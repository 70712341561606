import { AxiosResponse } from 'axios';
import OrderService from 'services/OrderService';

import {
    call,
    put,
    takeLatest
} from 'redux-saga/effects';

import {
    CREATE_ORDER,
    CREATE_ORDER_ITEM,
    GET_SINGLE_ORDER_ITEM,
    GET_ORDERS_LIST,
    GET_AVAILABLE_ORDERS_SHIPMENT,
    UPDATE_SINGLE_ORDER,
    UPDATE_ORDER_ITEM,
    UPDATE_ORDER_SHIPMENT,
    DELETE_ORDER,
    DELETE_ORDER_ITEM,
    SET_MOVEMENT_STATUS,
    SET_ORDER_STATUS,
    GET_ORDER_STATUS_LIST,
    GET_MOVEMENT_STATUS_LIST,
    GET_SHIPMENT_STATUS_LIST,
    GET_ORDER_PRODUCTS,
    SPLIT_PRODUCT_ORDER_ITEM,
} from 'store/types/orderTypes';

import { IError } from 'types/ErrorInterface';
import {
    ICreateOrderItem,
    IOrder,
    ISplitOrderItemDTO,
    IUpdateOrderItem,
    IUpdateOrderShipmentDTO,
    OrderNumberType
} from "types/OrderInterface";

import {
    IWorkerCallback,
    IWorkerCreate,
    IWorkerDelete,
    IWorkerGetList,
    IWorkerId,
    IWorkerUpdate,
    IWorkerUpdateStatus
} from 'types/SagaInterface';

import {
    setOrderStatusList,
    setMovementStatusList,
    setShipmentStatusList,
    setOrdersList,
    setAvailableOrdersShipment,
    setOrderProducts,
    setSingleOrderItem,
} from 'store/actions/orderAction';

import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';


function* createOrderWorker({payload}: IWorkerCreate<IOrder>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.create, data)

        if (response?.status === 201) {
            if (callback instanceof Function) callback({status: 'success', success: true, data: response?.data})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Створити замовлення не вдалося"], error as IError ))
    }
}

function* createOrderItemWorker({payload}: IWorkerUpdate<ICreateOrderItem>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.createOrderItem, id, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Створити замовлення не вдалося"], error as IError ))
    }
}

function* getSingleOrderItemWorker({payload}: IWorkerId) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getSingleOrderItem, id)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setSingleOrderItem(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати дані замовлення не вдалося"], error as IError ))
    }
}

function* getOrdersListWorker({payload}: IWorkerGetList) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setOrdersList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список замовлень не вдалося"], error as IError ))
    }
}

function* getAvailableOrdersShipmentWorker({payload}: IWorkerGetList) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getAvailableOrdersShipment)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setAvailableOrdersShipment(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список замовлень не вдалося"], error as IError ))
    }
}

function* updateOrderWorker({payload}: IWorkerUpdate<IOrder>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.update, id, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Оновити замовлення не вдалося"], error as IError ))
    }
}

function* updateOrderItemWorker({payload}: IWorkerUpdate<IUpdateOrderItem>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.updateOrderItem, id, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Оновити замовлення не вдалося"], error as IError ))
    }
}

function* updateOrderShipmentWorker({payload}: IWorkerUpdate<IUpdateOrderShipmentDTO>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.updateOrderShipment, id, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення віправлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Відправити замовлення не вдалося"], error as IError ))
    }
}

function* setMovementStatusWorker({payload}: IWorkerUpdateStatus<OrderNumberType>) {
    const { id, status, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.updateMovementStatus, id, status)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Статус оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Змінити статус не вдалося"], error as IError ))
    }
}

function* setOrderStatusWorker({payload}: IWorkerUpdateStatus<OrderNumberType>) {
    const { id, status, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.updateOrderStatus, id, status)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Статус замовлення оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Змінити статус замовлення не вдалося"], error as IError ))
    }
}

function* getOrderStatusListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getOrderStatusList)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setOrderStatusList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список статусів замовлень не вдалося"], error as IError ))
    }
}

function* getMovementStatusListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getMovementStatusList)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setMovementStatusList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список статусів замовлень не вдалося"], error as IError ))
    }
}

function* getShipmentStatusListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getShipmentStatusList)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setShipmentStatusList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список статусів замовлень не вдалося"], error as IError ))
    }
}

function* deleteOrderWorker({payload}: IWorkerDelete) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.delete, id)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Видалити замовлення не вдалося"], error as IError ))
    }
}

function* deleteOrderItemWorker({payload}: IWorkerDelete) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.deleteOrderItem, id)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Видалити замовлення не вдалося"], error as IError ))
    }
}

function* getOrderProductsWorker({payload}: IWorkerId) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.getOrderProducts, id)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setOrderProducts(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список продуктів замовлення не вдалося"], error as IError ))
    }
}

function* splitOrderItemByProduct({payload}: IWorkerUpdate<ISplitOrderItemDTO>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(OrderService.splitOrderItemByProduct, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Операцію виконано успішно"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Не вдалося виконати операцію"], error as IError ))
    }
}

export function* orderWatcher() {
    yield takeLatest(CREATE_ORDER, createOrderWorker);
    yield takeLatest(CREATE_ORDER_ITEM, createOrderItemWorker);
    yield takeLatest(GET_SINGLE_ORDER_ITEM, getSingleOrderItemWorker);
    yield takeLatest(GET_ORDERS_LIST, getOrdersListWorker);
    yield takeLatest(GET_AVAILABLE_ORDERS_SHIPMENT, getAvailableOrdersShipmentWorker);
    yield takeLatest(UPDATE_SINGLE_ORDER, updateOrderWorker);
    yield takeLatest(UPDATE_ORDER_ITEM, updateOrderItemWorker);
    yield takeLatest(UPDATE_ORDER_SHIPMENT, updateOrderShipmentWorker);
    yield takeLatest(SET_MOVEMENT_STATUS, setMovementStatusWorker);
    yield takeLatest(SET_ORDER_STATUS, setOrderStatusWorker);
    yield takeLatest(GET_ORDER_STATUS_LIST, getOrderStatusListWorker);
    yield takeLatest(GET_MOVEMENT_STATUS_LIST, getMovementStatusListWorker);
    yield takeLatest(GET_SHIPMENT_STATUS_LIST, getShipmentStatusListWorker);
    yield takeLatest(DELETE_ORDER, deleteOrderWorker);
    yield takeLatest(DELETE_ORDER_ITEM, deleteOrderItemWorker);
    yield takeLatest(GET_ORDER_PRODUCTS, getOrderProductsWorker);
    yield takeLatest(SPLIT_PRODUCT_ORDER_ITEM, splitOrderItemByProduct);
}
