import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, Tab, Tabs } from '@mui/material';

import './basic-tabs.scss'

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface ITab {
    key: string;
    baseKey?: string;
    label: string;
    children?: Array<ITab>;
    uploadModalTitle?: string;
}

interface Props {
    tabs: Array<ITab>;
    basePath: string
}

export const PageTabs: FC<Props> = ({ tabs, basePath }) => {
    
    const location = useLocation()

    const [ activeTab, setActiveTab ] = useState<number>(getActiveTab());

    const childTabs = useMemo(() => {
        const output = tabs[activeTab]?.children
        if (output) return output
        return []
    }, [activeTab])

    const [ childActiveTab, setChildActiveTab ] = useState<number>(getChildActiveTab());

    function getActiveTab() {
        const tabKey = location.pathname.split('/')[3]
        if (tabKey) {
            const index = tabs.findIndex(el => el.key === tabKey)
            if (index > 0) return index
            else return 0
        }
        else return 0
    }

    function getChildActiveTab() {
        const tabKey = location.pathname.split('/')[4]
        if (tabKey) {
            const index = childTabs.findIndex(el => el.key === tabKey)
            if (index > 0) return index
            else return 0
        }
        else return 0
    }

    useEffect(() => {
        setActiveTab(getActiveTab())
    }, [location.pathname, tabs])

    useEffect(() => {
        setChildActiveTab(getChildActiveTab())
    }, [location.pathname, childTabs])

    const navigate = useNavigate()

    function navigateTab(event: SyntheticEvent, key: number) {
        setActiveTab(key)
        navigate(`${basePath}/${tabs[key].key}`)
    }

    function navigateChildTab(event: SyntheticEvent, key: number) {
        setChildActiveTab(key)
        navigate(`${basePath}/${tabs[activeTab].baseKey}/${childTabs[key].key}`)
    }

    return (
        <>
            <Box
                className="basic-tabs"
                sx={{
                    width: '100%',
                    marginBottom: '25px',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={activeTab}
                        onChange={navigateTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs"
                        sx={{
                            '& .MuiTabs-indicator': {
                                transition: 'none',
                            },
                        }}
                    >
                        {tabs.map((tab, index) => (
                            <Tab key={index} label={tab.label} {...a11yProps(0)} />
                        ))}
                    </Tabs>
                </Box>

                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    visibility: childTabs?.length ? 'visible' : 'hidden'
                }}>
                    <Tabs
                        value={childActiveTab}
                        onChange={navigateChildTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs"
                        sx={{
                            '& .MuiTabs-indicator': {
                                transition: 'none',
                            },
                        }}
                    >
                        {childTabs?.map((tab, index) => (
                            <Tab key={index} label={tab.label} {...a11yProps(0)} />
                        ))}
                    </Tabs>
                </Box>
            </Box>

            <Outlet context={{
                uploadModalTitle: tabs[activeTab].uploadModalTitle,
                entity: tabs[activeTab].key,
            }}/>
        </>
    );
}
