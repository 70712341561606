import { AxiosResponse } from 'axios';
import ProductTypeService from 'services/ProductTypeService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    CREATE_PRODUCT_TYPE,
    // GET_SINGLE_PRODUCT_TYPE,
    GET_PRODUCT_TYPE_LIST,
    UPDATE_PRODUCT_TYPE,
    DELETE_PRODUCT_TYPE,
} from "store/types/productTypeTypes";

import { IError } from 'types/ErrorInterface';
import { IWorkerCallback, IWorkerCreate, IWorkerDelete, IWorkerUpdate } from 'types/SagaInterface';
import { IProductTypeCreate, IProductTypeUpdate } from 'types/ProductTypeInterface';

import {
    setProductTypeList,
    // setSingleProductType
} from 'store/actions/productTypeAction';

import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';

function* createProductTypeWorker({payload}: IWorkerCreate<IProductTypeCreate>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductTypeService.create, data)
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback({status: 'success', success: true, data: response?.data})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Тип продукту створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Cтворити тип продукту не вдалося"], error as IError ))
    }
}

// function* getSingleProductTypeWorker({payload: id}: {type: string, payload: number}) {
//     try {
//         const response: AxiosResponse = yield call(ProductTypeService.getSingle, id)
//         if (response?.status === 200) {
//             yield put(setSingleProductType(response.data))
//         }
//     } catch(error) {
//         yield put(setErrorNotifications( ["Отримати тип продукту не вдалося"], error as IError ))
//     }
// }

function* getProductTypeListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductTypeService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setProductTypeList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список компонентів не вдалося"], error as IError ))
    }
}

function* updateProductTypeWorker({payload}: IWorkerUpdate<IProductTypeUpdate>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductTypeService.update, id, data)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Тип продукту оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Оновити тип продукту не вдалося"], error as IError ))
    }
}

function* deleteProductTypeWorker({payload}: IWorkerDelete) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductTypeService.delete, id)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Тип продукту видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Видалити тип продукту не вдалося"], error as IError ))
    }
}

export function* productTypeWatcher() {
    yield takeLatest(CREATE_PRODUCT_TYPE, createProductTypeWorker);
    // yield takeLatest(GET_SINGLE_PRODUCT_TYPE, getSingleProductTypeWorker);
    yield takeLatest(GET_PRODUCT_TYPE_LIST, getProductTypeListWorker);
    yield takeLatest(UPDATE_PRODUCT_TYPE, updateProductTypeWorker);
    yield takeLatest(DELETE_PRODUCT_TYPE, deleteProductTypeWorker);
}
