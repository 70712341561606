import { FC, Fragment, ReactNode, useMemo } from "react";
import { NavLink } from "react-router-dom";

import { ResponseStatusType } from "types/SagaInterface";

import { Box, CircularProgress, Typography } from "@mui/material";

interface Props {
    responseStatus: ResponseStatusType
    entity: string;
    isDataExists: boolean;
    to: string;
    gender?: string
    children: ReactNode;
}

export const ResponseStatusLoader: FC<Props> = ({
    responseStatus = 'loading',
    entity,
    isDataExists,
    to,
    gender = 'm',
    children
}) => {

    const available = useMemo(() => {
        let output = ''
        switch (gender) {
            case 'm': output = 'доступного'
                break
            case 'f': output = 'доступної'
                break
        }
        return output
    }, [gender])

    return (
        <Fragment>
            {responseStatus === 'loading'
                ?
                <Box sx={{width: "100%", height: '100%', display: 'flex', alignItems: 'center'}}>
                    <CircularProgress sx={{display: 'block', margin: '0 auto'}}/>
                </Box>
                :
                isDataExists
                    ?
                    children
                    :
                    <Typography variant="subtitle1" color="error" gutterBottom>
                        {responseStatus === 'error'
                            ?
                            <>Виникла помилка при завантаженні даних</>
                            :
                            <>
                                Не знайдено {available} {entity}. <br/>
                                Для створення перейдіть, будь ласка, за цим посиланням -&nbsp;
                                <NavLink to={to}>
                                    створення {entity}
                                </NavLink>
                            </>
                        }
                    </Typography>
            }
        </Fragment>
    )
}