import { FC } from "react";
import { Navigate } from "react-router-dom";

import { PageTabs } from "components/Tabs/PageTabs";

import { useTypedSelector } from "hooks/useTypedSelector";
import { IAuthState } from "types/AuthInterface";

const tabs = [
    {key: 'operator', label: 'Оператор', uploadModalTitle: ''},
    {key: 'application', label: 'Додаток', uploadModalTitle: 'Завантажити додаток на сервер'},
    {key: 'firmware', label: 'Прошивка', uploadModalTitle: 'Завантажити прошивку на сервер'},
]

interface Props {}

export const RSFS: FC<Props> = () => {
    const { isAuth } = useTypedSelector<IAuthState>(state => state.auth)

    if (isAuth) {
        return (
            <div className="rsfs">
                <PageTabs tabs={tabs} basePath="/dashboard/rsfs"/>
            </div>
        )
    }

    return <Navigate to="/permission-denied"/>
}