import {
    CREATE_PRODUCT,
    // GET_SINGLE_PRODUCT,
    SET_SINGLE_PRODUCT,
    GET_PRODUCTS_LIST,
    SET_PRODUCTS_LIST,
    GET_AVAILABLE_PRODUCTS,
    SET_AVAILABLE_PRODUCTS,
    GET_PRODUCTS_BY_STATUS_IDS,
    UPDATE_PRODUCT,
    SET_PRODUCT_STATUS,
    SET_PRODUCT_STATUS_LIST,
    GET_PRODUCT_STATUS_LIST,
    DELETE_PRODUCT,
    ASSIGN_PRODUCT,
    UN_ASSIGN_PRODUCT,
} from "store/types/productTypes";

import {
    IAssignProduct,
    IProductCreate,
    IProductDTO,
    IProductUpdate,
    ProductStatusType
} from "types/ProductInterface";

import { ICallback } from "types/SagaInterface";

export const createProduct = (data: IProductCreate, callback: (success: boolean) => void) => ({
    type: CREATE_PRODUCT,
    payload: {
        data,
        callback,
    }
})

// export const getSingleProduct = (productId: number) => ({
//     type: GET_SINGLE_PRODUCT,
//     payload: productId
// })

export const setSingleProduct = (productData: IProductDTO) => ({
    type: SET_SINGLE_PRODUCT,
    payload: productData
})

export const getProductsList = (callback: (success: boolean) => void) => ({
    type: GET_PRODUCTS_LIST,
    payload: {
        callback,
    },
});

export const setProductsList = (productsList: IProductDTO[]) => ({
    type: SET_PRODUCTS_LIST,
    payload: productsList,
});

export const getAvailableProducts = (id: number, callback: (success: boolean) => void) => ({
    type: GET_AVAILABLE_PRODUCTS,
    payload: {
        id,
        callback,
    },
});

export const getProductsByStatusIds = (ids: Array<number>, callback: (success: boolean) => void) => ({
    type: GET_PRODUCTS_BY_STATUS_IDS,
    payload: {
        data: ids,
        callback,
    },
});

export const setAvailableProducts = (list: IProductDTO[]) => ({
    type: SET_AVAILABLE_PRODUCTS,
    payload: list,
});

export const updateProduct = (
    id: number,
    data: IProductUpdate,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_PRODUCT,
    payload: {
        id,
        data,
        callback,
    }
})

export const setProductStatus = (
    id: number,
    status: ProductStatusType,
    callback: (res: ICallback) => void
) => ({
    type: SET_PRODUCT_STATUS,
    payload: {
        id,
        status,
        callback,
    },
});

export const setProductStatusList = (productsList: IProductDTO[]) => ({
    type: SET_PRODUCT_STATUS_LIST,
    payload: productsList,
});

export const getProductStatusList = (callback: (success: boolean) => void) => ({
    type: GET_PRODUCT_STATUS_LIST,
    payload: {
        callback,
    },
});

export const deleteProduct = (id: number, callback: (success: boolean) => void) => ({
    type: DELETE_PRODUCT,
    payload: {
        id,
        callback
    }
})

export const assignProduct = (data: IAssignProduct, callback: (success: boolean) => void) => ({
    type: ASSIGN_PRODUCT,
    payload: {
        data,
        callback,
    }
})

export const unAssignProduct = (data: IAssignProduct, callback: (success: boolean) => void) => ({
    type: UN_ASSIGN_PRODUCT,
    payload: {
        data,
        callback,
    }
})