import { ChangeEvent, FC, useEffect, useState } from 'react';

import { styled, Button, FormHelperText } from '@mui/material';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

interface Props {
    sx?: {[key: string]: string};
    label: string;
    helperText?: string;
    error?: boolean;
    required?: boolean;
    onChange: (selectedFile: File) => void;
}

export const FileUploadInput: FC<Props> = ({
    sx = {},
    label = '',
    helperText = "Файл обов'язковий",
    error = false,
    required = false,
    onChange,
}) => {

    const [ fileState, setFileState ] = useState<File | null>(null);

    function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        const selectedFile = event.target.files && event.target.files[0];

        if (selectedFile) {
            onChange(selectedFile);
            setFileState(selectedFile);
        }
    }

    useEffect(() => {
        return () => {
            setFileState(null)
        }
    }, [])

    return (
        <div>
            <Button style={sx} component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                {label}
                <VisuallyHiddenInput
                    type="file"
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleFileChange(event)}
                />
            </Button>

            {(required && error) &&
                <FormHelperText sx={{margin: '3px 14px 0 14px'}} error>
                    {helperText}
                </FormHelperText>
            }
        </div>
    );
}