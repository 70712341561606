import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import {
    IAssignProduct,
    IProductCreate,
    IProductDTO,
    IProductStatus,
    IProductUpdate
} from "types/ProductInterface";

import { IResponseMessage } from "types/ResponseInterface";

const path_name = '/product'

export default class ProductService {

    static async create(data: IProductCreate): Promise<AxiosResponse<number>> {
        return $api.post<number>(`${path_name}`, data);
    }

    // static async getSingle(productId: number): Promise<AxiosResponse<IProductDTO>> {
    //     return $api.get<IProductDTO>(`${path_name}/${productId}`);
    // }

    static async getAll(): Promise<AxiosResponse<IProductDTO[]>> {
        return $api.get<IProductDTO[]>(`${path_name}`);
    }

    static async getAvailableProducts(id: number): Promise<AxiosResponse<IProductDTO[]>> {
        return $api.get<IProductDTO[]>(`${path_name}/available/${id}`);
    }

    static async getProductsByStatus(productStatus: number): Promise<AxiosResponse<IProductDTO[]>> {
        return $api.get<IProductDTO[]>(`/product-status/${productStatus}`);
    }

    static async getProductStatusList(): Promise<AxiosResponse<IProductStatus[]>> {
        return $api.get<IProductStatus[]>(`product-status`);
    }

    static async update(id: number, data: IProductUpdate): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`${path_name}/${id}`, data);
    }

    static async updateProductStatus(id: number, status: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`/product-status/${id}`, {status});
    }

    static async delete(id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${path_name}/${id}`);
    }

    static async assignProduct(data: IAssignProduct): Promise<AxiosResponse<number>> {
        return $api.post<number>(`/product-assign`, data);
    }

    static async unAssignProduct(data: IAssignProduct): Promise<AxiosResponse<number>> {
        return $api.post<number>(`/delete-product-assign`, data);
    }
}