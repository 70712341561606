const initTokenStore = () => {
    let accessToken: string

    const set = (token: string) => accessToken = token

    const get = () => accessToken

    return { set, get }
}

export const tokenStore = initTokenStore()