import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { ICallback } from "types/SagaInterface";
import { IAuthState } from "types/AuthInterface";
import { ITableHeader, IActionItem } from "types/TableInterface";
import { ISerialNumberDTO, ISeriesDTO, ISeriesState } from "types/SeriesInterface";

import { READ_WAREHOUSE, WRITE_WAREHOUSE } from "store/types/policiesTypes";

import { IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

interface Props {}

export const Series: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { seriesList } = useTypedSelector<ISeriesState>(state => state.series)
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);

    const { getSeriesList, deleteSeries, deleteSerialNumber } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    const [ searchParams ] = useSearchParams()
    const [ searchSeriesId, setSearchSeriesId ] = useState<number | null>(null);

    useEffect(() => {
        const idParam = Number(searchParams.get("seriesId")) || null;
        setSearchSeriesId(idParam);
    }, [searchParams]);

    useEffect(() => {
        getSeriesList(() => setIsDataLoading(false))
    }, [])

    function deleteSeriesHandler(id: number) {
        setStatusLoading(true)
        deleteSeries(id, (res: ICallback) => {
            if (res.success) getSeriesList(() => setStatusLoading(false))
            else setStatusLoading(false)
        })
    }

    function deleteSerialNumberHandler(id: number) {
        setStatusLoading(true)
        deleteSerialNumber(id, (res: ICallback) => {
            if (res.success) getSeriesList(() => setStatusLoading(false))
            else setStatusLoading(false)
        })
    }

    const computedSerialNumberTableHeaders = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "serial_number_id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "code", label: "Код", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "product_id", label: "ID продукту", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "product_type", label: "Тип продукту", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
             
        ]
        if ([READ_WAREHOUSE, WRITE_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({
                key: "actions",
                type: 'actions',    
                headerActions: authData?.policies.includes(WRITE_WAREHOUSE) ? [{
                    iconName: 'AddCircle',
                    action: (seriesId) => {
                        if (seriesId) navigate(`/dashboard/warehouse/series/create/serial-number/${seriesId}`)
                    }
                }] as IActionItem[] : [],
                styles: {textAlign: 'right', cellWidth: '10%', cellMinWidth: '100px'}
            },)
        }
        return output
    }, [authData?.policies])

    const tableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "series_id", label: "ID серії", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "series_code", label: "Код серії", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "serial_numbers", label: "Кількість", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
        ]
        if ([WRITE_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [authData?.policies])

    const buildTableData = useMemo(() => {
        let data = seriesList

        if (searchSeriesId) {
            data = seriesList?.filter(el => searchSeriesId === el?.series_id)
        }

        return data?.map((item: ISeriesDTO, index: number) => {
            return {
                tableId: item?.series_id,
                table_row_key: `series_id_${item?.series_id}_${index}`,
                series_id: item?.series_id,
                series_code: item?.series_code,
                serial_numbers: item?.serial_numbers?.length,
                rowSettings: {
                    loading: statusLoading,
                },
                actions: authData?.policies.includes(WRITE_WAREHOUSE) ? [
                    {
                        iconName: "Delete",
                        confirmAction: () => deleteSeriesHandler(item?.series_id),
                        disabled: item?.isBusy, 
                        iconColor: item?.isBusy && 'disabled',
                        tooltip: item?.isBusy ? 'Серія використовується' : 'Видалити'
                    },
                ] : [],
                accordionData: {
                    headers: computedSerialNumberTableHeaders,
                    tableData: item?.serial_numbers?.map((el: ISerialNumberDTO, idx: number) => ({
                        tableId: el?.serial_number_id,
                        table_row_key: `serial_number_id_${el?.serial_number_id}_${idx}`,
                        serial_number_id: el?.serial_number_id,
                        code: el?.code,
                        order_id: el?.order_id,
                        product_id: el?.product_id,
                        product_type: el?.product_type,
                        rowSettings: {
                            loading: statusLoading,
                            // action: () => {
                            //     if (el?.product_id) {
                            //         navigate(`/dashboard/warehouse/products?productId=${el.product_id}`)
                            //     }
                            // },
                        },
                        actions: [
                            ...authData?.policies.includes(READ_WAREHOUSE) ? [{
                                iconName: "Launch",
                                action: () => {
                                    if (el?.product_id) navigate(`/dashboard/warehouse/products?productId=${el.product_id}`)
                                },
                                tooltip: 'Перейти до продукту',
                            }] : [],
                            ...authData?.policies.includes(WRITE_WAREHOUSE) ? [{
                                iconName: "Delete", 
                                confirmAction: () => deleteSerialNumberHandler(el?.serial_number_id), 
                                disabled: el?.isBusy, 
                                iconColor: el?.isBusy && 'disabled',
                                tooltip: el?.isBusy ? 'Серійний номер використовується' : 'Видалити'
                            }] : []
                        ],
                    }))
                }
            }
        })
    }, [seriesList, statusLoading, searchSeriesId, computedSerialNumberTableHeaders, authData?.policies])

    function toCreateSeries() {
        navigate(`/dashboard/warehouse/series/create/series${location.search}`)
    }

    return (
        <div className="series">
            {authData?.policies.includes(WRITE_WAREHOUSE) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateSeries}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId="series-table"
                        searchEntityId={searchSeriesId}
                    />
                }
            </DataLoading>

            <Outlet/>
        </div>
    )
}