import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { IProductState } from "types/ProductInterface";
import { ITableHeader } from "types/TableInterface";
import {
    IOrder,
    IOrderItem,
    IOrderState,
    IOrderStatus,
} from "types/OrderInterface";

import { WRITE_PRODUCTS } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";
import { getOrderStatusColor } from "utils/helpers/getStatusColor";

import { Typography } from "@mui/material";

interface Props {}

export const DeliveryToClient: FC<Props> = () => {
    const [ statusLoading ] = useState<boolean>(false)
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const [ orderItem, setOrderItem ] = useState<Partial<IOrderItem>>({})
    const { availableOrdersShipment } = useTypedSelector<IOrderState>(state => state.order)
    const { orderStatusList } = useTypedSelector<IOrderState>(state => state.order)
    const { shipmentStatusList } = useTypedSelector<IOrderState>(state => state.order)
    const { productStatusList } = useTypedSelector<IProductState>(state => state.product)

    const {
        getAvailableOrdersShipment,
        getOrderStatusList,
        getProductStatusList,
        getShipmentStatusList,
    } = useActions()

    const navigate = useNavigate()

    useEffect(() => {
        getOrderStatusList()
        getShipmentStatusList()
        getProductStatusList()
        getAvailableOrdersShipment(() => setIsDataLoading(false))
    }, [])

    const params = useParams();

    function getProductTypeLabels(item: any) {
        const output: string[] = []
        item.forEach((el: any) => {
            if (el?.productType?.product_type) output.push(el.productType.product_type)
        })
        return output.join(', ')
    }

    const orderItemTableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "order_item_id", label: "ID", type: 'text' },
            { key: "product_type", label: "Назва", type: 'text' },
            { key: "quantity", label: "Кількість", type: 'text' },
            { 
                key: "shipment_status_id", label: "Статус", type: 'chip',
                styles: {buttonWidth: '100%', cellWidth: '10%', cellMinWidth: '180px', textAlign: 'center'}
            },
        ]
        if ([WRITE_PRODUCTS].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'}, })
        }
        return output
    }, [authData?.policies])

    const computedTableHeaders: ITableHeader[] = useMemo(() => {
        return [
            { key: "order_id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "orderItem", label: "Тип зброї", type: 'tooltip', action: (text: string) => getShortText(text, 8), settings: { filter: ['search'], sort: ['ASC', 'DESC'] }},
            { key: "last_name", label: "Прізвище", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "first_name", label: "Ім'я", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "brigade_name", label: "Назва бригади", type: 'tooltip', action: getShortText, styles: {textAlign: 'center'}, settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "work_region", label: "Район роботи", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "phone", label: "Номер телефону", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            {
                key: "status_id",
                label: "Статус",
                type: 'chip',
                styles: { textAlign: 'center' },
                settings: {
                    filter: [{
                        key: 'button-list',
                        options: orderStatusList?.map((item: IOrderStatus) => ({
                                    label: item?.status_name,
                                    key: item?.status_id,
                                    btnColor: getOrderStatusColor(item?.status_id)
                                }))
                    }],
                    sort: ['ASC', 'DESC']
                }
            },
            { key: "order_date", label: "Дата замовлення", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "order_process", label: "Дата процесу", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
        ]
    }, [orderStatusList])

    const getOrderStatus = useCallback((status: number) => {
        return {
            label: orderStatusList.find(el => el.status_id === status)?.status_name || '',
            value: Number(status),
            color: getOrderStatusColor(Number(status))
        }
    }, [orderStatusList])

    const getShipmentStatus = useCallback((status: number) => {
        return {
            label: shipmentStatusList.find(el => el.shipment_status_id === status)?.shipment_status_name || '',
            value: Number(status),
            color: getOrderStatusColor(Number(status))
        }
    }, [shipmentStatusList])

    const buildTableData = useMemo(() => {
        return availableOrdersShipment?.map((item: IOrder, index: number) => {
            return {
                tableId: item?.order_id,
                table_row_key: `order_id_${item?.order_id}_${index}`,
                order_id: item?.order_id,
                orderItem: getProductTypeLabels(item?.orderItem),
                last_name: item?.last_name,
                first_name: item?.first_name,
                brigade_name: item?.brigade_name,
                work_region: item?.work_region,
                phone: item?.phone,
                order_date: item?.order_date,
                order_process: item?.order_process,
                rowSettings: {
                    loading: statusLoading,
                },
                status_id: getOrderStatus(item?.status_id),
                accordionData: {
                    headers: orderItemTableHeaders,
                    tableData: item?.orderItem?.map((el: IOrderItem, idx: number) => {
                        if (Number(params.orderItemId) === el?.order_item_id) setOrderItem(el)
                        return {
                            tableId: el?.order_item_id,
                            table_row_key: `product_type_id_${el?.order_item_id}_${idx}`,
                            quantity: el?.quantity,
                            order_item_id: el?.order_item_id,
                            product_type: el?.productType?.product_type,
                            shipment_status_id: getShipmentStatus(el?.shipment?.shipmentStatus?.shipment_status_id),
                            actions: authData?.policies.includes(WRITE_PRODUCTS) ? [
                                {
                                    iconName: "LocalShipping",
                                    action: () => {
                                        if (el?.order_item_id) {
                                            setOrderItem(el)
                                            navigate(`/dashboard/movements/delivery-to-client/delivery-form/${el.order_item_id}`)
                                        }
                                    },
                                    disabled: el?.shipment?.shipmentStatus?.shipment_status_id > 1,
                                    iconColor: el?.shipment?.shipmentStatus?.shipment_status_id > 1 && 'disabled',
                                    tooltip: el?.shipment?.shipmentStatus?.shipment_status_id > 1 ? 'Відправлено' :'Відправити',
                                },
                            ] : [],
                        }
                    })
                }
            }
        })
    }, [ availableOrdersShipment, statusLoading, productStatusList, authData?.policies ])

    return (
        <div className="delivery-to-client">

            <DataLoading isDataLoading={isDataLoading}>

                {buildTableData.length > 0
                    ?
                    <TableComponent
                        headers={computedTableHeaders}
                        tableData={buildTableData}
                        tableId="delivery-to-client"
                    />
                    :
                    <Typography variant="h4">
                        Замовлення для відправки відсутні
                    </Typography>
                }

            </DataLoading>

            <Outlet context={orderItem}/>
        </div>
    )
}