import { FC, useMemo, useState} from 'react';
import { useLocation } from 'react-router-dom';

import { IBasicSelectOption } from 'components/Select/BasicSelect';
import { SubmitEntityModal, INumberRange } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IComponent, IComponentState } from 'types/ComponentInterface';
import { ICreateComponentOrder } from 'types/ComponentOrderInterface';
import { ICallback } from 'types/SagaInterface';

interface ICreateComponentOrderData extends ICreateComponentOrder {
    in_all: number,
}

const labels: {[key: string]: string} = {
    product_type_id: 'Компонент',
    price: 'Ціна',
    quantity: 'Кількість',
    in_all: 'Всього',
    address: 'Адреса',
    notes: 'Примітка',
}

const initialOrderData: ICreateComponentOrderData = {
    product_type_id: 0,
    quantity: 1,
    price: 0,
    in_all: 0,
    address: '',
    notes: '',
}

const inputRows: {[key: string]: number} = {
    notes: 4
}

const disabledFields: {[key: string]: boolean} = {
    in_all: true,
}

const dataTypes: {[key: string]: string} = {
    price: 'number',
    quantity: 'number',
}

const fieldsTypes: {[key: string]: string} = {
    price: 'number',
    quantity: 'number',
    in_all: 'number',
    address: 'text',
    notes: 'text',
    product_type_id: 'select'
}

const grid: {[key: string]: number} = {
    product_type_id: 4,
    price: 4,
    quantity: 4,
    in_all: 4,
    address: 8,
    notes: 12,
}

const order: {[key: string]: number} = {
    product_type_id: 1,
    address: 2,
    price: 3,
    quantity: 4,
    in_all: 5,
    notes: 6,
}

const numberRange: INumberRange = {
    quantity: {min: 1},
}

const requiredFields: Array<string> = ['product_type_id', 'address', 'price', 'quantity']
const submitProps: Array<string> = ['product_type_id', 'address', 'price', 'quantity', 'notes']

const emptyDataInfo = {
    entity: "компонента",
    to: "/dashboard/warehouse/components/create"
}

export const CreateComponentOrder: FC = () => {
    const { componentsList } = useTypedSelector<IComponentState>(state => state.component)
    const [ orderData ] = useState<ICreateComponentOrderData>(initialOrderData)

    const { createComponentOrder, getComponentsOrdersList, getComponentsList } = useActions()

    const location = useLocation();

    function backRoute(res: ICallback) {
        let query = ''
        if (res?.data?.id && typeof res.data.id === 'number') query = `?movementId=${res.data.id}`
        else if (location.search) query = location.search
        return `/dashboard/order/components-orders${query}`
    }

    const basicSelectOptions: IBasicSelectOption[] = useMemo(() => {
        return componentsList.map((el: IComponent) => ({
            key: el.id,
            label: el.name,
        }))
    }, [componentsList])

    function onChange(key: string, value: number, data: ICreateComponentOrderData) {
        const updateData: Partial<ICreateComponentOrderData> = {
            [key]: value
        }
        if (['price', 'quantity'].includes(key)) {
            if (key === 'price' && Number.isFinite(data.quantity)) {
                updateData.in_all = value * data.quantity
            } else if (key === 'quantity' && Number.isFinite(data.price)) {
                updateData.in_all = value * data.price
            }
        }
        return {...data, ...updateData}
    }

    return (
        <SubmitEntityModal
            title="Cтворити замовлення"
            order={order}
            grid={grid}
            dataTypes={dataTypes}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            numberRange={numberRange}
            initialData={orderData}
            requiredFields={requiredFields}
            disabledFields={disabledFields}
            submitProps={submitProps}
            labels={labels}
            backRoute={backRoute}
            emptyDataInfo={emptyDataInfo}
            basicSelectOptions={basicSelectOptions}
            isDataExists={!!(basicSelectOptions.length)}
            getEntityActionOnMount={getComponentsList}
            submitEnityAction={createComponentOrder}
            getEntityActionOnSubmit={getComponentsOrdersList}
            onChange={onChange}
        />
    )
}