import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { ITableHeader } from "types/TableInterface";
import { ICallback } from "types/SagaInterface";
import {
    IProductDTO,
    IProductState,
    IProductStatus,
    ProductStatusType
} from "types/ProductInterface";

import { READ_ORDER, WRITE_WAREHOUSE } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";
import { getProductStatusColor } from "utils/helpers/getStatusColor";

import { IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

interface Props {}

export const Products: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { productsList } = useTypedSelector<IProductState>(state => state.product)
    const { productStatusList } = useTypedSelector<IProductState>(state => state.product)
    const [ statusLoading, setStatusLoading ] = useState<boolean>(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)

    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ searchProductId, setSearchProductId ] = useState<number | null>(null);
    
    useEffect(() => {
      const newProductId = Number(searchParams.get("productId")) || null;
      setSearchProductId(newProductId);
    }, [searchParams]);

    // function deleteProduct(id: number) {
    //     setProductsList(productsList.filter(el => el.id !== id))
    // }

    const {
        setProductsList,
        getProductsList,
        setProductStatus,
        getProductStatusList,
        deleteProduct,
        setWarningNotifications,
    } = useActions()

    useEffect(() => {
        getProductStatusList()
        setProductsList([])
        getProductsList(() => {
            setIsDataLoading(false)
        })
    }, [])

    const navigate = useNavigate()
    const location = useLocation()

    const handleDeleteProduct = (id: number) => {
        setStatusLoading(true)
        deleteProduct(
            id,
            (success: boolean) => {
                if (success) getProductsList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        );
    }

    const handleSetProductStatus = (id: number, value: (number | string)) => {
        setStatusLoading(true)
        setProductStatus(
            id,
            Number(value) as ProductStatusType,
            (res: ICallback) => {
                if (res.success) getProductsList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        );
    }

    const tableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "product_id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "product_type", label: "Тип продукту", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "series_code", label: "Серія", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "serial_number", label: "Серійний номер", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "notes", label: "Примітка", type: 'tooltip', action: getShortText, settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            {
                key: "product_status_id",
                label: "Статус",
                type: authData?.policies.includes(WRITE_WAREHOUSE) ? 'select' : 'chip',
                styles: {buttonWidth: '100%', textAlign: 'center'},
                settings: {
                    filter: [{
                        key: 'button-list',
                        options: productStatusList?.map((item: IProductStatus) => ({
                                    label: item?.status_title,
                                    key: item?.status_id,
                                    btnColor: getProductStatusColor(item?.status_id)
                                }))
                    }],
                    sort: ['ASC', 'DESC'],
                },
            }
        ]
        if ([READ_ORDER, WRITE_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [productStatusList, authData?.policies])

    const getProductStatus = useCallback((status: number) => {
        return {
            label: productStatusList.find(el => el.status_id === status)?.status_title || '',
            value: Number(status),
            color: getProductStatusColor(Number(status))
        }
    }, [productStatusList])

    const productStatusOptions = useMemo(() => {
        return productStatusList.map(el => ({
            key: el?.status_id,
            label: el?.status_title,
            color: getProductStatusColor(el?.status_id),
            exclude: el?.only_view !== false,
            selfExclude: true,
        }))
    }, [productStatusList])

    const excludedProductStatus: Array<number> = useMemo(() => {
        return productStatusList.filter(el => el.only_view !== false).map(el => el.status_id)
    }, [productStatusList])

    const buildTableData = useMemo(() => {
        let data = productsList

        if (searchProductId) {
            data = productsList?.filter(el => searchProductId === el?.product_id)
        }

        return data?.map((item: IProductDTO, index: number) => {
            return {
                tableId: item?.product_id,
                table_row_key: `product_id_${item?.product_id}_${index}`,
                product_id: item?.product_id,
                product_type: item?.product_type.product_type,
                series_code: item?.series.series_code,
                serial_number: item?.serial_number.code,
                notes: item?.notes,
                rowSettings: {
                    loading: statusLoading,
                    // focused: {
                    //     status: item?.product_id === searchProductId ? true : false,
                    //     onFinish: () => setSearchParams('')
                    //     // duration: 1500,
                    // },
                },
                product_status_id: authData?.policies.includes(WRITE_WAREHOUSE) ? {
                    options: productStatusOptions,
                    preventDefault: {
                        status: excludedProductStatus.includes(item?.product_status_id),
                        onClick: () => setWarningNotifications('Цей статус змінити не можна')
                    },
                    color: getProductStatusColor(item?.product_status_id),
                    value: item?.product_status_id,
                    action: (value: (number | string)) => handleSetProductStatus(item?.product_id, value)
                } : getProductStatus(item?.product_status_id),
                actions: [
                    ...authData?.policies.includes(READ_ORDER) ? [{
                        iconName: "Launch",
                        action: () => {
                            if (item?.order_id) navigate(`/dashboard/order/customer/confirmed?orderId=${item.order_id}`)
                        },
                        disabled: !item.order_id,
                        iconColor: !item.order_id && 'disabled',
                        tooltip: item.order_id ? 'Перейти до замовлення' : "Продукт не прив'язаний до замовлення",
                    }] : [],
                    ...authData?.policies.includes(WRITE_WAREHOUSE) ? [
                        {
                            iconName: "Edit",
                            action: () => {
                                if (item?.product_id) {
                                    navigate(`/dashboard/warehouse/products/edit/${item.product_id}${location.search}`)
                                }
                            },
                            tooltip: 'Редагувати',
                        },
                        {
                            iconName: "Delete",
                            confirmAction: () => handleDeleteProduct(item?.product_id),
                            disabled: item?.isBusy,
                            iconColor: item?.isBusy && 'disabled',
                            tooltip: item?.isBusy ? 'Продукт у використанні' : 'Видалити'
                        },
                    ] : []
                ]
            }
        })
    }, [productsList, statusLoading, searchProductId, productStatusOptions, excludedProductStatus, authData?.policies])

    function toCreateProduct() {
        navigate(`/dashboard/warehouse/products/create${location.search}`)
    }

    return (
        <div className="products">
            {authData?.policies.includes(WRITE_WAREHOUSE) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateProduct}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }


            <DataLoading isDataLoading={isDataLoading}>

                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId="products-table"
                        searchEntityId={searchProductId}
                    />
                }

            </DataLoading>

            <Outlet/>
        </div>
    )
}