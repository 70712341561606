import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import { ICreateComponentOrder } from "types/ComponentOrderInterface";
import { IResponseMessage } from "types/ResponseInterface";

const path_name = '/component-order'

export default class ComponentOrderService {

    static async create(data: ICreateComponentOrder): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`${path_name}`, data);
    }

    // static async getSingle(id: number): Promise<AxiosResponse<ICreateComponentOrder>> {
    //     return $api.get<ICreateComponentOrder>(`${path_name}/${id}`);
    // }

    static async getAll(): Promise<AxiosResponse<ICreateComponentOrder[]>> {
        return $api.get<ICreateComponentOrder[]>(`${path_name}s`);
    }

    static async update(id: number, data: ICreateComponentOrder): Promise<AxiosResponse<IResponseMessage>> {
        return $api.put<IResponseMessage>(`${path_name}/${id}`, data);
    }

    static async delete(id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${path_name}/${id}`);
    }

    static async updateMovementStatus(movement_id: number, movement_status_id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`/component-order-status/${movement_id}/${movement_status_id}`);
    }
}