import { FC, useEffect, useMemo, useState } from "react";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { IAuthState } from "types/AuthInterface";
import { ITableHeader } from "types/TableInterface";
import { AppDTO, EActivationStatus, IOperatorState, OperatorDTO } from "types/OperatorInterface";

const tableHeaders: ITableHeader[]  = [
    { key: "id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
    { key: "phoneNumber", label: "Номер телефону", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "firstName", label: "Ім'я", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "lastName", label: "Прізвище", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "nickName", label: "Позивний", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "unit", label: "Підрозділ", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "milpost", label: "Посада", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
]

const activationStatusOptions: { [key: number]: string } = {
    [EActivationStatus.WAIT]: 'Очікується',
    [EActivationStatus.ACTIVATED]: 'Активовано',
    [EActivationStatus.REJECTED]: 'Відхилено',
    [EActivationStatus.DEACTIVATED]: 'Деактивовано'
}
function getActivationStatusColor(status: number) {
    switch (status) {
        case EActivationStatus.WAIT: return'primary'
        case EActivationStatus.ACTIVATED: return 'success'
        case EActivationStatus.REJECTED: return 'warning'
        case EActivationStatus.DEACTIVATED: return 'error'
        default: return 'primary'
    }
}
const activationStatusSettings = {
    filter: [{
        key: 'button-list',
        options: Object.entries(activationStatusOptions)
            ?.map(([key, value]) => ({
                label: value,
                key: Number(key),
                btnColor: getActivationStatusColor(Number(key))
            }) )
    }],
    sort: ['ASC', 'DESC']
}
const appTableHeaders: ITableHeader[]  = [
    { key: "id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
    { key: "version", label: "Версія", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "activationStatusId", label: "Статус", type: 'chip', styles: {textAlign: 'center'}, settings: activationStatusSettings },
    { key: "actions", type: 'actions', styles: {textAlign: 'right'} },
]

interface Props {}

export const Operator: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { operatorsList } = useTypedSelector<IOperatorState>(state => state.operator)
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);

    const { getOperatorsList, changeAppStatus } = useActions()

    function changeStatus(app_id: number, activationStatus: number) {
        setStatusLoading(true)
        changeAppStatus(
            {app_id, activationStatus},
            (success: boolean) => {
                if (success) getOperatorsList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        )
    }

    function getStatusLabel(status: number) {
        return {
            label: activationStatusOptions[status],
            value: Number(status),
            color: getActivationStatusColor(Number(status))
        }
    }

    function getActionsList(app_id: number, status: number) {
        let output = []
        switch (status) {
            case 1:
                output.push(
                    {
                        iconName: "VerifiedUser",
                        tooltip: 'Активувати',
                        confirmAction: () => changeStatus(app_id, EActivationStatus.ACTIVATED),
                        confirmationMsg: `Ви впевнені що хочете змінити статус?`
                    },
                    {
                        iconName: "PhonelinkErase",
                        tooltip: 'Відхилити',
                        iconColor: 'warning',
                        confirmAction: () => changeStatus(app_id, EActivationStatus.REJECTED),
                        confirmationMsg: `Ви впевнені що хочете змінити статус?`
                    },
                )
                break;
            case 2:
                output.push(
                    {
                        iconName: "RemoveModerator",
                        tooltip: 'Деактивувати',
                        confirmAction: () => changeStatus(app_id, EActivationStatus.DEACTIVATED),
                        confirmationMsg: `Ви впевнені що хочете змінити статус?`
                    },
                )
        }
        // {iconName: "HourglassTop", confirmAction: (status: number) => changeStatus(status), confirmationMsg: `Ви впевнені що хочете змінити статус?`},
        return output
    }
    
    const buildTableData = useMemo(() => {
        const data = operatorsList?.map((item: OperatorDTO, idx: number) => {
            return {
                tableId: item.id,
                table_row_key: `order_id_${item?.id}_${idx}`,
                id: item?.id,
                phoneNumber: item?.phoneNumber,
                firstName: item?.firstName,
                lastName: item?.lastName,
                nickName: item?.nickName,
                unit: item?.unit,
                milpost: item?.milpost,
                rowSettings: {
                    loading: statusLoading,
                },
                accordionData: {
                    headers: appTableHeaders,
                    tableData: item?.apps?.map((el: AppDTO, index: number) => ({
                        tableId: el.id,
                        table_row_key: `app_id_${el?.id}_${index}`,
                        id: el?.id,
                        version: el?.version,
                        activationStatusId: getStatusLabel(el?.activationStatusId),
                        rowSettings: {
                            loading: statusLoading,
                        },
                        actions: [
                        //     // {iconName: "Edit", action: () => {el.activationStatusId}},
                            ...getActionsList(el?.id, el?.activationStatusId),
                        ]
                    }))
                }
            }
        })
        return data
    }, [operatorsList, statusLoading])

    useEffect(() => {
        getOperatorsList(() => setIsDataLoading(false))
    }, [])

    return (
        <div className="activation">

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId="activation-table"
                    />
                }
            </DataLoading>
        </div>
    )
}