import { FC, useEffect, useMemo, useState } from "react";

import { IFilterElement } from "components/FilterSort/Filter";
import { FilterSortBar } from "components/FilterSort/FilterSortBar";

import { IFilterData, ITableDataItem, ITableHeader } from "types/TableInterface";

import { TableCell, TableRow } from "@mui/material";

const filterTypes: {[key: string]: {[key: string]: string}} = {
    'range-slider': {label: "Діапазон"},
    from: {label: "Від", type: "number"},
    to: {label: "До", type: "number"},
    search: {label: "Пошук", type: "text"},
}

export interface ISliderRange {
    [key: string]: number[]
}

interface Props<T> {
    tableData: ITableDataItem<T>[];
    headers: ITableHeader[];
    filterData: IFilterData;
    sortData: string[];
    setSortData: Function;
    setFilterData: Function;
}

export const TableSortingFilter: FC<Props<any>> = ({
    tableData,
    headers,
    filterData,
    sortData,
    setSortData,
    setFilterData,
}) => {

    const [ slidersRange, setSlidersRange ] = useState<ISliderRange>({})

    useEffect(() => {
        const keys = headers?.filter(el => el?.settings?.filter?.includes('range-slider')).map(el => el?.key)

        const range: ISliderRange = {}
        keys?.forEach(key => {
            const numbersArr = tableData?.map(el => {
                if (typeof el[key] === 'number') return el[key]
                if (typeof el[key] === 'string') return Number(el[key])
                if (el[key]?.value !== undefined) return Number(el[key]?.value)
                return el
            })
            if (Array.isArray(numbersArr) && numbersArr.every(item => typeof item === 'number')) {
                range[key] = [Math.min(...numbersArr), Math.max(...numbersArr)]
            }
        })
        setSlidersRange({...range})
    }, [tableData, headers])

    function getFilterData(data: string[] | undefined, key: string) {
        if (data) {
            return data?.reduce((acc: IFilterElement[], el: string) => {
                let element = ''
                let filter_spread = {}
                if (typeof el === 'string')  element = el
                else if (typeof el === 'object' && (el as { key?: string })?.key) {
                    element = (el as { key: string })?.key
                    if ((el as { options?: {} })?.options) {
                        filter_spread = {...filter_spread, options: (el as { options?: {} }).options}
                    }
                }
                if (filterTypes[element]) filter_spread = {...filter_spread, ...filterTypes[element]}
                
                acc?.push({
                    ...filter_spread,
                    key: element,
                    action: (value: string | number | number[] | undefined) => setFilterData((prevValue: IFilterData) => {
                        let typeKey = element
                        if (['range-slider', 'limit'].includes(element)) typeKey = 'range-slider'
                        
                        for (const property in prevValue) {
                            if (Object.hasOwnProperty.call(prevValue, property) && property?.includes(key)) {
                                delete prevValue[property]
                            }
                        }
                        
                        if (!value) {
                            delete prevValue[`${key}|${typeKey}`]
                            return {...prevValue}
                        } else if (['string', 'number'].includes(typeof value)  || Array.isArray(value)) {
                            return {...prevValue, [`${key}|${typeKey}`]: value as string | number | number[]}
                        } else {
                            return {...prevValue}
                        }
                    })
                })
                return acc
            }, [])
        }
        return null
    }

    function getSortData(data: string[] | undefined, key: string) {
        if (data) {
            return data?.reduce((acc: {[key: string]: () => void}, el: string) => {
                if (el === 'ASC') acc.sortASC = () => setSortData([key, 'ASC'])
                else if (el === 'DESC') acc.sortDESC = () => setSortData([key, 'DESC'])
                return acc
            }, {})
        }
        return null
    }

    const computedHeaders = useMemo(() => {
        return headers?.map((item: ITableHeader) => {
            return {
                ...item,
                settings: {
                    filter: getFilterData(item?.settings?.filter, item?.key),
                    sort: getSortData(item?.settings?.sort, item?.key)
                }
            }
        })
    }, [headers])

    return (
        <TableRow sx={{backgroundColor: '#fff'}}>
            {computedHeaders.map((header: ITableHeader) => (
                <TableCell
                    key={header.key + 'table-sorting-filter'}
                    component="th"
                    scope="row"
                    sx={{padding: '8px'}}
                    align={header?.styles?.textAlign ?? 'inherit'}
                >
                    {(header?.settings?.filter || header?.settings?.sort) &&

                        <FilterSortBar
                            slidersRange={slidersRange[header.key]}
                            keyName={header.key}
                            data={header.settings}
                            filterData={filterData}
                            sortData={sortData}
                        />

                    }
                </TableCell>
            ))}
        </TableRow>
    )
}