import * as Auth from 'store/actions/authAction'
import * as Order from 'store/actions/orderAction'
import * as UnconfirmedOrder from 'store/actions/unconfirmedOrderAction'
import * as ComponentOrder from 'store/actions/componentOrderAction'
import * as Notification from 'store/actions/notificationAction'
import * as Component from 'store/actions/componentAction'
import * as Product from 'store/actions/productAction'
import * as ProductType from 'store/actions/productTypeAction'
import * as Recipe from 'store/actions/recipeAction'
import * as Series from 'store/actions/seriesAction'
import * as Operator from 'store/actions/operatorAction'
import * as Software from 'store/actions/softwareAction'

const ActionCreators = {
    ...Auth,
    ...Order,
    ...UnconfirmedOrder,
    ...ComponentOrder,
    ...Notification,
    ...Component,
    ...Product,
    ...ProductType,
    ...Recipe,
    ...Series,
    ...Operator,
    ...Software,
}

export default ActionCreators