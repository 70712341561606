import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IProductTypeState, IProductTypeUpdate } from 'types/ProductTypeInterface';

const labels: {[key: string]: string} = {
    product_type: 'Назва',
    note: 'Примітка',
    submit: 'Зберегти',
}

const initialData = {
    product_type: '',
    note: '',
}

const fieldsTypes: {[key: string]: string} = {
    product_type: 'text',
    note: 'text',
}

const inputRows: {[key: string]: number} = {
    note: 6
}

const grid: {[key: string]: number} = {
    product_type: 12,
    note: 12,
}

const order: {[key: string]: number} = {
    product_type: 1,
    note: 2,
}

const requiredFields: Array<string> = ['product_type']

export const EditProductType: FC = () => {
    const { productTypeList } = useTypedSelector<IProductTypeState>(state => state.product_type)
    const [ productTypeData, setProductTypeData ] = useState<IProductTypeUpdate>(initialData)

    const { updateProductType, getProductTypeList } = useActions()

    const params = useParams()
    const location = useLocation()

    useEffect(() => {
        const productTypeInstance = productTypeList.find(el => (
            el.product_type_id === Number(params.productTypeId)
        )) || null
        if (productTypeInstance) {
            setProductTypeData({
                product_type: productTypeInstance?.product_type || '',
                note: productTypeInstance?.note || '',
            })
        }
    }, [productTypeList, params])

    return (
        <SubmitEntityModal
            title={`Редагувати тип продукту ${params.productTypeId}`}
            order={order}
            grid={grid}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            initialData={productTypeData}
            requiredFields={requiredFields}
            labels={labels}
            backRoute={`/dashboard/warehouse/product-types${location.search}`}
            isDataExists={true}
            submitEnityAction={updateProductType.bind(null, Number(params.productTypeId))}
            getEntityActionOnSubmit={getProductTypeList}
        />
    )
}