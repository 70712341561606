import {
    FC,
    useEffect,
    useMemo,
    useState,
    forwardRef,
    useImperativeHandle,
    ForwardedRef,
    MutableRefObject,
    ElementType,
} from "react";

import { TableRowComponent } from "components/Table/TableBody/TableRowComponent";

import { IFilterData, ITableDataItem, ITableHeader, ITableItemStyles } from "types/TableInterface";

import { TableBody } from '@mui/material';

interface Props<T> {
    ref?: ForwardedRef<{ fetchData: () => void }>;
    zIndex: number;
    tableComponentRef: MutableRefObject<HTMLDivElement | null>;
    tableData: ITableDataItem<T>[];
    headers: ITableHeader[];
    filterData: IFilterData;
    sortData: string[];
    has_pagination: boolean;
    per_page: number;
    has_more: boolean;
    setHasMore: Function;
    onChangeRowInput?: (value: string | number, index: number) => void;
    getHeaderStyles: (styles: ITableItemStyles | undefined) => void;
    StyledTableRow: ElementType;
    StyledTableCell: ElementType;
}

export const TableBodyComponent: FC<Props<any>> = forwardRef(({
    zIndex,
    tableComponentRef,
    tableData,
    headers,
    filterData,
    sortData,
    has_pagination,
    per_page,
    has_more,
    setHasMore,
    onChangeRowInput,
    getHeaderStyles,
    StyledTableRow,
    StyledTableCell,
}, ref) => {
    const [ table_rows, setTableRows ] = useState<Array<ITableDataItem<any>>>([]);
    const [ page, setPage ] = useState<number>(1);
    const [ timeoutId, setTimeoutId ] = useState<NodeJS.Timeout | null>(null);
    const [ sortFilterTimeoutId, setSortFilterTimeoutId ] = useState<NodeJS.Timeout | null>(null);

    useImperativeHandle(ref, () => ({
        fetchData() {
            fetchData()
        },
    }))

    const computedTableData: ITableDataItem<any>[] = useMemo(() => {
        let output: ITableDataItem<any>[] = [...tableData]
        for (const property in filterData) {
            if (Object.hasOwnProperty.call(filterData, property)) {
                const [key, type] = property?.split('|')

                output = output?.filter(el => {
                    const value = el[key]?.value !== undefined ? el[key]?.value : el[key]
                    
                    if (type === 'search' && typeof filterData[property] === 'string') {
                        if (typeof value === 'string') {
                            return value?.toLowerCase()?.includes((filterData[property] as string)?.toLowerCase())
                        } else if (typeof value === 'number') {
                            return value === Number(filterData[property])
                        }
                    } else if (['limit', 'range-slider'].includes(type) ) {
                        const sliderValues = filterData[property] as number[];
                        if (['number', 'string'].includes(typeof value)) {
                            return value >= sliderValues[0] && value <= sliderValues[1]
                        } else if (['number', 'string'].includes(typeof value)) {
                            return value >= sliderValues[0] && value <= sliderValues[1]
                        }
                        return false
                    }
                    else if (type === 'button-list') return value === filterData[property]
                    else if (type === 'from') return value >= filterData[property]
                    else if (type === 'to') return value <= filterData[property]

                    return false
                })
            }
        }
        if (sortData?.length === 2) {
            const sortOrder = sortData[1] === 'ASC' ? 1 : -1;
          
            output = output?.sort((a, b) => {
                const aValue = a[sortData[0]]?.value !== undefined ? a[sortData[0]]?.value : a[sortData[0]];
                const bValue = b[sortData[0]]?.value !== undefined ? b[sortData[0]]?.value : b[sortData[0]];
            
                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return sortOrder * aValue?.localeCompare(bValue);
                } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return sortOrder * (aValue - bValue);
                }
            
                return 0;
            });
        }

        return output
    }, [sortData, filterData, tableData])

    useEffect(() => {
        // Solved issue:
        // 1. Set table_rows data when changed sortData, filterData, per_page
        if (table_rows.length) {
            if (sortFilterTimeoutId) {
                clearTimeout(sortFilterTimeoutId);
                setSortFilterTimeoutId(null)
            }

            setSortFilterTimeoutId(setTimeout(() => {
                setPage(1);
                setHasMore(true);
                setTableRows([]);
            }, 100))
        }
    }, [sortData, filterData, per_page])

    useEffect(() => {
        // Solved issue:
        // 1. When tableData is changed and tableData.length > 0 && table_rows.length > 0
        if (tableData.length && table_rows.length) {
            fetchData(1);
        }
        // Solved issue:
        // 1. When deleting an element from tableData and tableData is an empty array, table_rows was not updated
        else if (tableData.length === 0 && table_rows.length > 0) {
            fetchData(1);
        }
    }, [tableData])

    useEffect(() => {
        if (computedTableData.length && !table_rows.length) {
            fetchData(1);
        }
    }, [computedTableData])

    useEffect(() => {
        if (tableData.length && !table_rows.length && computedTableData.length) {
            if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null)
            }

            setTimeoutId(setTimeout(() => {
                    fetchData(1);
            }, 100))
        }
    }, [table_rows])

    function fetchData(p?: number) {
        if (!has_pagination) setTableRows([...computedTableData])
        else if (tableData.length && has_more) {
            // console.log('1. tableData.length && has_more')

            // Solved issue:
            // When not last page
            if (tableData.length > table_rows.length) {
                // console.log('1.1. tableData.length > table_rows.length')

                const newData = computedTableData.slice(0, (p ? p : page) * per_page)
                setTableRows([...newData]);

                // console.log('page', page)
                // console.log('newData', newData)
                setPage(p ? p + 1 : Math.floor(newData.length / per_page) + 2);

                setHasMore(tableData.length > newData.length);
            }
            // Solved issue:
            // When create new entity(row) and has_more is true, needs to be set new table_rows data
            else if (
                (computedTableData.length !== table_rows.length) ||
                (JSON.stringify(computedTableData) !== JSON.stringify(table_rows))
            ) {
                // console.log('1.2. computedTableData.length !== table_rows.length', 'has_more is TRUE', has_more)

                const newData = computedTableData.slice(0, (p ? p : page) * per_page)
                setTableRows([...newData]);
            }
        }
        // Solved issue:
        // When create new entity(row) and has_more is false, needs to be set new table_rows data
        else if (
            (computedTableData.length !== table_rows.length) ||
            (JSON.stringify(computedTableData) !== JSON.stringify(table_rows))
        ) {
            // console.log('2. computedTableData.length !== table_rows.length', 'has_more is FALSE', has_more)

            const newData = computedTableData.slice(0, (p ? p : page) * per_page)
            setTableRows([...newData]);

            // Solved issue:
            // When scrolled to last page and after this created new entity(row), needs to be set has_more to true
            if (computedTableData.length > newData.length) setHasMore(true)
        }

        // Solved issue:
        // When created new entity(row) and has_more is false, needs to be set has_more true
        if (tableData.length < table_rows.length && !has_more) {
            // console.log('3. tableData.length < table_rows.length && !has_more', 'has_more FALSE', has_more)

            setHasMore(true);
        }
    }
    
    return (
        <TableBody>
            {table_rows?.map((row, index) => (
                <TableRowComponent
                    key={row?.table_row_key || index}
                    index={index}
                    zIndex={zIndex}
                    tableComponentRef={tableComponentRef}
                    data={row}
                    headers={headers}
                    getHeaderStyles={getHeaderStyles}
                    StyledTableRow={StyledTableRow}
                    StyledTableCell={StyledTableCell}
                    onChangeInput={onChangeRowInput}
                />
            ))}
        </TableBody>
    )
})