import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { WatchLogs } from 'pages/Dashboard/WatchLogs/WatchLogs';

interface Props {}

export const WatchLogsRouter: FC<Props> = () => {
    return (
        <Routes>
            <Route path="/" element={<WatchLogs/>}/>
        </Routes>
    )
}