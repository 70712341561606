import { AxiosResponse } from 'axios';
import SoftwareService from 'services/SoftwareService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    GET_SOFTWARE_LIST,
    UPLOAD_SOFTWARE,
    DOWNLOAD_SOFTWARE,
} from "store/types/softwareTypes";

import { IError } from 'types/ErrorInterface';

import {
    setErrorNotifications,
    setSuccessNotifications,
    setWarningNotifications
} from 'store/actions/notificationAction';

import { setSoftwareList } from 'store/actions/softwareAction';
import { IFileUpload, SoftwareEntityType } from 'types/SoftwareInterface';
import { ICallback } from 'types/SagaInterface';

interface IGetSoftwares {
    type: string;
    payload: {
        entity: SoftwareEntityType;
        callback: (res: ICallback) => void;
    }
}

function* getSoftwaresWorker({payload}: IGetSoftwares) {
    const { entity, callback } = payload
    try {
        let response: AxiosResponse | null = null
        if (entity === 'application') response = yield call(SoftwareService.getApplications)
        else if (entity === 'firmware') response = yield call(SoftwareService.getFirmwares)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setSoftwareList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})
        
        yield put(setErrorNotifications( ["Отримати список файлів не вдалося"], error as IError ))
    }
}

interface IUploadSoftware {
    type: string;
    payload: {
        data: IFileUpload;
        entity: SoftwareEntityType;
        callback: (success: boolean) => void;
    }
}

function* uploadSoftwareWorker({payload}: IUploadSoftware) {
    const { data, entity, callback } = payload
    try {
        let response: AxiosResponse | null = null
        if (entity === 'application') response = yield call(SoftwareService.uploadApplication, data)
        else if (entity === 'firmware') response = yield call(SoftwareService.uploadFirmware, data)
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Файл завантажено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Завантажити файл не вдалося"], error as IError ))
    }
}

interface IDownloadSoftware {
    type: string;
    payload: {
        id: number;
        entity: SoftwareEntityType;
        title: string
    }
}

function* downloadSoftwareWorker({payload}: IDownloadSoftware) {
    const { id, entity, title } = payload
    try {
        let response: AxiosResponse | null = null
        if (entity === 'application') response = yield call(SoftwareService.downloadApplication, id)
        else if (entity === 'firmware') response = yield call(SoftwareService.downloadFirmware, id)
        
        if (response?.status === 200) {
            if (response?.data) {
                const url = response.data
                const link = document.createElement('a');
                link.href = url;
                link.download = title;
                window.open(url, '_blank');
            } else {
                yield put(setWarningNotifications('Файл відсутній у контенті відповіді сервера'))
            }
        
            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Файл завантажено"))
            }
        }
    } catch(error) {
        yield put(setErrorNotifications( ["Завантажити файл не вдалося"], error as IError ))
    }
}

export function* softwareWatcher() {
    yield takeLatest(GET_SOFTWARE_LIST, getSoftwaresWorker);
    yield takeLatest(UPLOAD_SOFTWARE, uploadSoftwareWorker);
    yield takeLatest(DOWNLOAD_SOFTWARE, downloadSoftwareWorker);
}
