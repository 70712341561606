import { FC, useMemo } from "react";
import { useActions } from "hooks/useActions";

import {
    styled,
    Box,
    Button,
    IconButton,
    Toolbar,
    Typography,
    AppBar as MuiAppBar,
    AppBarProps as MuiAppBarProps,
    Tooltip,
    MenuList,
    MenuItem,
    Divider,
} from "@mui/material";

import {
    Menu as MenuIcon,
    Logout as LogoutIcon
} from '@mui/icons-material';

import './header.scss'
import { useTypedSelector } from "hooks/useTypedSelector";
import { IAuthState } from "types/AuthInterface";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    width: number
}

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open',})<AppBarProps>(({ theme, open, width }) => {

    return {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${width}px)`,
            marginLeft: `${width}px`,
            transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }}
);

const labels: {[key: string]: string} = {
    order: 'Замовлення',
    warehouse: 'Складування',
    products: 'Переміщення',
    read: 'Перегляд',
    write: 'Запис',
}

interface Props {
    drawerWidth: number,
    drawerIsOpened: boolean
    openDrawer: () => void
}

export const Header: FC<Props> = ({ drawerWidth, drawerIsOpened, openDrawer }) => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)

    const { logoutAction } = useActions()

    const policies = useMemo(() => {
        let policies = authData?.policies.reduce((acc: {[key: string]: string[]}, el: string) => {
            const keys = el.split(':')
            if (!acc[keys[1]]) acc[keys[1]] = [keys[0]]
            else acc[keys[1]].push(keys[0])
            return acc
        }, {}) || {}

        return (
            <>
                {Object.entries(policies).map(([key, value], index) => (
                    <MenuList
                        key={key}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                    >
                        <MenuItem>{labels[key]}</MenuItem>
                        <MenuList
                            id="composition-menu"
                            aria-labelledby="composition-button"
                        >
                            {value.map((item: string, idx: number) => (
                                <MenuItem key={idx}>
                                    {labels[item]}
                                </MenuItem>
                            ))}
                        </MenuList>
                        {Object.entries(policies).length !== index+1 && (
                            <Divider style={{backgroundColor: '#ffffff'}}/>
                        )}
                    </MenuList>
                ))}
            </>
        )
    }, [authData])

    return (
        <AppBar width={drawerWidth} position="fixed" open={drawerIsOpened}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={openDrawer}
                    edge="start"
                    sx={{ mr: 2, ...(drawerIsOpened && { display: 'none' }) }}
                >
                    <MenuIcon />
                </IconButton>

                <Tooltip title={policies}>
                    <Typography variant="h6" noWrap component="div">
                        {authData?.name || ''}
                    </Typography>
                </Tooltip>

                <Box sx={{ margin:"0 0 0 auto"}}>
                    <Button
                        variant="outlined"
                        color="inherit"
                        endIcon={<LogoutIcon />}
                        onClick={logoutAction}
                    >
                        Вийти
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    )
}