import { FC, useEffect, useMemo, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal, INumberRange } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IComponentOrder, IComponentOrderState, IUpdateComponentOrder } from 'types/ComponentOrderInterface';

interface IUpdateComponentOrderData extends IUpdateComponentOrder {
    in_all: number,
}

const labels: {[key: string]: string} = {
    price: 'Ціна',
    quantity: 'Кількість',
    in_all: 'Всього',
    shipping_address: 'Адреса',
    notes: 'Примітка',
    submit: 'Зберегти',
}

const inputRows: {[key: string]: number} = {
    notes: 4
}

const disabledFields: {[key: string]: boolean} = {
    in_all: true,
}

const dataTypes: {[key: string]: string} = {
    price: 'number',
    quantity: 'number',
}

const grid: {[key: string]: number} = {
    price: 4,
    quantity: 4,
    in_all: 4,
    shipping_address: 12,
    notes: 12,
}

const order: {[key: string]: number} = {
    price: 1,
    quantity: 2,
    in_all: 3,
    shipping_address: 4,
    notes: 5,
}

const numberRange: INumberRange = {
    price: {min: -5},
    quantity: {min: 1},
}

const requiredFields: Array<string> = ['shipping_address', 'price', 'quantity']
const submitProps: Array<string> = ['shipping_address', 'price', 'quantity', 'notes']

export const EditComponentOrder: FC = () => {
    const [ orderData, setOrderData ] = useState<Partial<IComponentOrder>>({})
    const { componentsOrdersList } = useTypedSelector<IComponentOrderState>(state => state.component_order)

    const { updateComponentOrder, getComponentsOrdersList } = useActions()

    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        const orderInstance = componentsOrdersList.find(el => el.movement_id === Number(params.orderId)) || {}
        setOrderData({...orderInstance})
    }, [componentsOrdersList, params])

    const initialOrderData: any = useMemo(() => {
        return {
            shipping_address: orderData?.cMovements?.shipment?.shipping_address || '',
            price: orderData?.price !== undefined ? orderData.price : 0,
            quantity: orderData?.quantity !== undefined ? orderData.quantity : 0,
            in_all: orderData?.price && orderData?.quantity
                ? Number(orderData?.price) * Number(orderData?.quantity)
                : 0,
            notes: orderData?.cMovements?.shipment?.notes || '',
        }
    }, [orderData])

    function onChange(key: string, value: number, data: IUpdateComponentOrderData) {
        const updateData: Partial<IUpdateComponentOrderData> = {
            [key]: value
        }
        if (['price', 'quantity'].includes(key)) {
            if (key === 'price' && Number.isFinite(data.quantity)) {
                updateData.in_all = value * data.quantity
            } else if (key === 'quantity' && Number.isFinite(data.price)) {
                updateData.in_all = value * data.price
            }
        }
        return {...data, ...updateData}
    }

    const fieldsTypes = useMemo(() => {
        let types: {[key: string]: string} = {
            notes: 'text',
            shipping_address: 'text',
        }
        const status = orderData?.cMovements?.shipment?.shipStatus?.shipment_status_id
        if (status !== undefined && status === 1) {
            types = {
                price: 'number',
                quantity: 'number',
                in_all: 'number',
                notes: 'text',
                shipping_address: 'text',
            }
        }
        return types
    }, [orderData])

    return (
        <SubmitEntityModal
            title="Редагувати замовлення"
            order={order}
            grid={grid}
            dataTypes={dataTypes}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            numberRange={numberRange}
            initialData={initialOrderData}
            requiredFields={requiredFields}
            disabledFields={disabledFields}
            submitProps={submitProps}
            labels={labels}
            backRoute={`/dashboard/order/components-orders${location.search}`}
            isDataExists={true}
            submitEnityAction={updateComponentOrder.bind(null, Number(params?.orderId))}
            getEntityActionOnSubmit={getComponentsOrdersList}
            onChange={onChange}
        />
    )
}