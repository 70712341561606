import { AxiosResponse } from 'axios';
import RecipeService from 'services/RecipeService';

import {
    put,
    call,
    takeLatest,
    all,
    Effect,
} from 'redux-saga/effects';

import {
    // GET_SINGLE_RECIPE,
    GET_RECIPES_LIST,
    UPDATE_SINGLE_RECIPE,
    CREATE_RECIPE,
    DELETE_RECIPE,
    DELETE_RECIPE_COMPONENT,
} from "store/types/recipeTypes";

import { IError } from 'types/ErrorInterface';
import { IPartDTO, IRecipeDTO } from "types/RecipeInterface"
import {
    IWorkerCallback,
    IWorkerCreate,
    IWorkerUnknown,
    IWorkerUpdate
} from 'types/SagaInterface';

import {
    setRecipesList,
    // setSingleRecipe,
} from 'store/actions/recipeAction';
import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';


function* createRecipeWorker({payload}: IWorkerCreate<IRecipeDTO>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(RecipeService.create, data)
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Рецепт створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Cтворити рецепт не вдалося"], error as IError ))
    }
}

// function* getSingleRecipeWorker({payload: recipeId}: {type: string, payload: number}) {
//     try {
//         // const response: AxiosResponse = yield call(RecipeService.getSingle, recipeId)
//         // if (response?.status === 200) {
//         //     yield put(setSingleRecipe(response.data))
//         // }
//     } catch(error) {
//         yield put(setErrorNotifications(
//             ["Помилка з'єднання. Отримати дані рецепта не вдалося"],
//             error as IError
//         ))
//     }
// }

function* getRecipesListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(RecipeService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setRecipesList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список рецептів не вдалося"], error as IError ))
    }
}

function* updateRecipeWorker({payload}: IWorkerUpdate<IRecipeDTO>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(RecipeService.update, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Рецепт оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Оновити рецепт не вдалося"], error as IError ))
    }
}

function* deleteRecipeWorker({payload}: IWorkerUnknown) {
    const { product_type_id, parts, callback } = payload
    try {
        const promises = parts.map(function* (part: IPartDTO): Generator<Effect, any, any> {
            return yield call(RecipeService.delete, product_type_id, part.component_type_id);
        });
  
        const response: AxiosResponse[] = yield all(promises);
        
        if (response.every((res: AxiosResponse) => res.status === 200)) {
            if (callback instanceof Function) callback(true)

            yield put(setSuccessNotifications('Рецепт видалено'));
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Видалити рецепт не вдалося"], error as IError ))
    }
}

function* deleteRecipeComponentWorker({payload}: IWorkerUnknown) {
    const { product_type_id, component_type_id, last_component, callback } = payload
    try {
        const response: AxiosResponse = yield call(RecipeService.delete, product_type_id, component_type_id);
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setSuccessNotifications(last_component ? 'Рецепт видалено' : 'Компонент видалено'));
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Видалити компонент не вдалося"], error as IError ))
    }
}

export function* recipeWatcher() {
    yield takeLatest(UPDATE_SINGLE_RECIPE, updateRecipeWorker);
    yield takeLatest(CREATE_RECIPE, createRecipeWorker);
    // yield takeLatest(GET_SINGLE_RECIPE, getSingleRecipeWorker);
    yield takeLatest(GET_RECIPES_LIST, getRecipesListWorker);
    yield takeLatest(DELETE_RECIPE, deleteRecipeWorker);
    yield takeLatest(DELETE_RECIPE_COMPONENT, deleteRecipeComponentWorker);
}
