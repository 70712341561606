import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { menuReducer } from "./menuReducer";
import { userReducer } from "./userReducer";
import { orderReducer } from "./orderReducer";
import { unconfirmedOrderReducer } from "./unconfirmedOrderReducer";
import { componentOrderReducer } from "./componentOrderReducer";
import { notificationReducer } from "./notificationReducer";
import { componentReducer } from "./componentReducer";
import { productReducer } from "./productReducer";
import { productTypeReducer } from "./productTypeReducer";
import { recipeReducer } from "./recipeReducer";
import { seriesReducer } from "./seriesReducer";
import { operatorReducer } from "./operatorReducer";
import { softwareReducer } from "./softwareReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    menu: menuReducer,
    user: userReducer,
    order: orderReducer,
    unconfirmed_order: unconfirmedOrderReducer,
    component_order: componentOrderReducer,
    notification: notificationReducer,
    component: componentReducer,
    product: productReducer,
    product_type: productTypeReducer,
    recipe: recipeReducer,
    series: seriesReducer,
    operator: operatorReducer,
    software: softwareReducer,
})

export type RootState = ReturnType<typeof rootReducer>