import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BasicSelect } from 'components/Select/BasicSelect';
import { TableComponent } from 'components/Table/Table';
import { EmptyDataInfo } from 'components/Common/EmptyDataInfo';
import { DataLoading } from 'components/Loader/DataLoading';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IProductTypeState } from 'types/ProductTypeInterface';
import { ITableHeader, IActionItem } from 'types/TableInterface';
import { IComponentState } from 'types/ComponentInterface';
import { IPartDTO, IRecipeDTO, IRecipeState } from 'types/RecipeInterface';
import { ICallback } from 'types/SagaInterface';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    Grid,
    TextField,
    FormHelperText,
} from '@mui/material';

export enum EComponentQuantity {
    DEFAULT = 1,
    MIN_QUANTITY = 1,
}

const tableHeaders: ITableHeader[] = [
    { key: "id", label: "ID", type: 'text', styles: {cellWidth: '10%', cellMinWidth: '100px'} },
    { key: "name", label: "Назва", type: 'text', styles: {cellWidth: '50%', cellMinWidth: '100px'} },
    { key: "quantity", label: "Кількість", type: 'input', styles: {cellWidth: '10%', cellMinWidth: '150px'} },
    {
        key: "actions",
        type: 'actions',
        headerActions: [] as IActionItem[],
        styles: {textAlign: 'right', cellWidth: '10%', cellMinWidth: '100px'}
    },  
]

const initialRecipeData = {
    product_type_id: 0,
    name: '',
    parts: [] as IPartDTO[]
}

const inputFields: string[] = []
const requiredFields: string[] = ['product_type_id', 'parts']

const recipeDataLabels: {[key: string]: string}  = {
    name: 'Назва',
    remark: 'Примітка',
    product_type_id: 'Тип продукту',
    parts: 'Компоненти'
}

const dataTypes: {[key: string]: string} = {
    name: 'string',
    remark: 'string',
}

interface Props {}

export const CreateRecipe: FC<Props> = () => {
    const [ isProductsLoading, setIsProductsLoading ] = useState<boolean>(true)
    const [ isComponentsLoading, setIsComponentsLoading ] = useState<boolean>(true)
    const [ loadingFailed, setLoadingFailed ] = useState<boolean>(false);
    const [ createBtnDisabled, setCreateBtnDisabled ] = useState<boolean>(true)
    const [ recipeData, setRecipeData ] = useState<IRecipeDTO>(initialRecipeData)
    const [ componentsQuantities, setComponentsQuantities ] = useState<{[key: string]: number}[]>([])
    const { componentsList } = useTypedSelector<IComponentState>(state => state.component)
    const { productTypeList } = useTypedSelector<IProductTypeState>(state => state.product_type)
    const { recipesList } = useTypedSelector<IRecipeState>(state => state.recipe)

    const { getProductTypeList, getComponentsList, createRecipe, getRecipesList } = useActions()

    useEffect(() => {
        getProductTypeList((res: ICallback) => {
            if (!res.success) setLoadingFailed(true)
            setIsProductsLoading(false)
        })
        getComponentsList((res: ICallback) => {
            if (!res.success) setLoadingFailed(true)
            setIsComponentsLoading(false)
        })
    }, [])

    const navigate = useNavigate()
    const location = useLocation()

    const closeModal = () => {
        let query = ''
        if (recipeData?.product_type_id && typeof recipeData.product_type_id === 'number') {
            query = `?productTypeId=${recipeData.product_type_id}`
        } else if (location.search) query = location.search
        navigate(`/dashboard/warehouse/recipes${query}`)
    };

    const basicSelectOptions = useMemo(() => {
        const exclusion = recipesList.map(el => el.product_type_id)
        
        return productTypeList
            .filter(el => !exclusion.includes(el.product_type_id) )
            .map(el => ({
                key: el.product_type_id,
                label: el.product_type,
            }))
    }, [productTypeList, recipesList])

    function selectProductTypeHandler(value: string) {
        setRecipeData({ ...recipeData, product_type_id: Number(value) })
    }

    const selectOptions = useMemo(() => {
        const selectedComponents = componentsQuantities.map(el => el.component_type_id)

        return componentsList
            .filter(el => !selectedComponents.includes(el.id))
            .map(el => ({
                key: el.id,
                label: el.name,
                input: {
                    label: 'Кількість',
                    type: 'number',
                    min: EComponentQuantity.MIN_QUANTITY,
                    defaultValue: EComponentQuantity.DEFAULT
                }
            }))
    }, [componentsList, componentsQuantities])

    function deleteComponentHandler(component_type_id: number) {
        setComponentsQuantities([...componentsQuantities.filter(el => el.component_type_id !== component_type_id)])
    }

    function addComponent(component_type_id?: number | string, quantity?: number | string): void {
        if (component_type_id !== undefined && quantity !== undefined) {
            const requestData = {
                component_type_id: Number(component_type_id),
                quantity: Number(quantity)
            }
            setComponentsQuantities([...componentsQuantities, requestData])
        }
    }

    const computedTableHeaders = tableHeaders.map(el => {
        if (el.key === 'actions') {
            el.headerActions = [{
                iconName: 'AddCircleDropdown',
                options: selectOptions,
                action: addComponent
            }]
        }
        return el
    })

    function getComponentName(id: number): string | null {
        return componentsList.find(el => el.id === id)?.name || null
    }

    const buildTableData = useMemo(() => {
        return componentsQuantities.map((el, index: number) => ({
                    tableId: el.component_type_id,
                    table_row_key: `component_type_id_${el.component_type_id}_${index}`,
                    id: el.component_type_id,
                    name: getComponentName(el.component_type_id),
                    quantity: {
                        value: el.quantity ?? EComponentQuantity.DEFAULT,
                        type: 'number',
                        min: EComponentQuantity.MIN_QUANTITY,
                        onChange: (quantity: (number | string), idx: number) => onChangeComponentQuantity(quantity, el.component_type_id, idx)
                    },
                    actions: [
                        {iconName: "Delete", confirmAction: () => deleteComponentHandler(el.component_type_id)},
                    ]
                }))
    }, [componentsQuantities])

    function onChangeComponentQuantity(quantity: (number | string), id: number, index: number) {
        setComponentsQuantities(prevValue => {
            prevValue[index].quantity = Number(quantity)
            return [...prevValue]
        })
    }
    
    function setFieldValue(event: ChangeEvent<HTMLInputElement>, key: string) {
        switch (dataTypes[key]) {
            case 'number': setRecipeData({...recipeData, [key]: Number(event.target.value)});
                break;
            default: setRecipeData({...recipeData, [key]: event.target.value});
        }
    }

    const checkRecipeDataValidation: boolean = useMemo(() => {
        return requiredFields.some(key => {
            if (key === 'parts' && componentsQuantities.length === 0) return true
            else if (!recipeData[key as keyof IRecipeDTO]) return true
        })
    }, [recipeData, componentsQuantities])

    useEffect(() => {
        const disabled: boolean = !checkRecipeDataValidation

        setCreateBtnDisabled(!disabled)
    }, [checkRecipeDataValidation]);

    function getValue(key: string) {
        let value = recipeData[key as keyof IRecipeDTO] || ''
        return value
    }

    function createRecipeHandler() {

        setCreateBtnDisabled(true)

        let requestData = {
            product_type_id: recipeData.product_type_id,
            parts: componentsQuantities.map(el => ({
                component_type_id: el.component_type_id,
                quantity: el.quantity,
            })),
        }

        createRecipe(
            requestData,
            (res: ICallback) => {
                if(res.success) {
                    getRecipesList()
                    closeModal()
                }
                setCreateBtnDisabled(false)
        })
    }

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Створити новий рецепт</DialogTitle>

            <DialogContent dividers>

                <DataLoading isDataLoading={isProductsLoading || isComponentsLoading}>
                    
                    <EmptyDataInfo
                        entity="типу продукту"
                        dataLength={basicSelectOptions.length}
                        loadingFailed={loadingFailed}
                        to="/dashboard/warehouse/product-types/create"
                    >
                        <Grid container spacing={2}>

                            {inputFields.map((key) => (
                                <Grid key={key} item xs={12} sm={6}>
                                    <TextField
                                        label={recipeDataLabels[key]}
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        value={getValue(key)}
                                        required={requiredFields.includes(key)}
                                        error={!getValue(key)}
                                        helperText={!getValue(key) && "Обов'язкове поле"}
                                        onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue(event, key)}
                                    />
                                </Grid>
                            ))}

                            <Grid item xs={12}>
                                <BasicSelect
                                    label={recipeDataLabels['product_type_id']}
                                    value={recipeData.product_type_id || ''}
                                    options={basicSelectOptions}
                                    required={requiredFields.includes('product_type_id')}
                                    error={!recipeData.product_type_id}
                                    onChange={selectProductTypeHandler}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <EmptyDataInfo
                                    entity="компонента"
                                    dataLength={componentsList.length}
                                    loadingFailed={loadingFailed}
                                    to="/dashboard/warehouse/components/create"
                                >
                                    <TableComponent
                                        headers={computedTableHeaders}
                                        tableData={buildTableData}
                                        tableId="create-recipe-table"
                                    />
                                    {requiredFields.includes('parts') && componentsQuantities.length === 0 &&
                                        <FormHelperText error>
                                            Виберіть компонент
                                        </FormHelperText>
                                    }
                                </EmptyDataInfo>
                            </Grid>

                        </Grid>
                    </EmptyDataInfo>

                </DataLoading>

            </DialogContent>

            <DialogActions>
                <Button onClick={closeModal}>Закрити</Button>
                <Button variant="contained" onClick={createRecipeHandler} disabled={createBtnDisabled}>
                    Створити
                </Button>
            </DialogActions>
        </Dialog>
    )
}