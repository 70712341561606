import {
    SET_UNCONFIRMED_ORDERS_LIST,
} from "store/types/unconfirmedOrderTypes"

import { IUnconfirmedOrderState, UnconfirmedOrderAction } from "types/UnconfirmedOrderInterface"

const initialState: IUnconfirmedOrderState = {
    unconfirmedOrdersList: [],
    unconfirmedOrderStatusList: [
        { unconfirmed_status_id: 1, unconfirmed_status_code: 'UNCONFIRMED', unconfirmed_status_name: 'Не підтвердженний' },
        { unconfirmed_status_id: 2, unconfirmed_status_code: 'CONFIRMED', unconfirmed_status_name: 'Підтвердженний' },
        { unconfirmed_status_id: 3, unconfirmed_status_code: 'DECLINE', unconfirmed_status_name: 'Відхилено' },
        { unconfirmed_status_id: 4, unconfirmed_status_code: 'DELETE', unconfirmed_status_name: 'Видалено' },
    ]
};

export const unconfirmedOrderReducer = (
    state = initialState,
    action: UnconfirmedOrderAction
): IUnconfirmedOrderState => {
    switch (action.type) {
        case SET_UNCONFIRMED_ORDERS_LIST:
            return { ...state, unconfirmedOrdersList: action.payload }
        default:
            return state
    }
}