import { FC } from "react";
import { Navigate } from "react-router-dom";

import { PageTabs } from "components/Tabs/PageTabs";

import { useTypedSelector } from "hooks/useTypedSelector";
import { IAuthState } from "types/AuthInterface";

import { READ_PRODUCTS } from "store/types/policiesTypes";

const tabs = [
    {key: 'construction', label: 'Збірка продукту'},
    {key: 'delivery-to-client', label: 'Доставка до клієнта'},
    {key: 'returning-from-client', label: 'Повернення від клієнта'},
    {key: 'delivery-status', label: 'Стан доставки'},
]

interface Props {}

export const Movements: FC<Props> = () => {
    const { authData, isAuth } = useTypedSelector<IAuthState>(state => state.auth)

    if (authData?.policies.includes(READ_PRODUCTS) && isAuth) {
        return (
            <div className="movements">
                <PageTabs tabs={tabs} basePath="/dashboard/movements"/>
            </div>
        )
    }

    return <Navigate to="/permission-denied"/>
}