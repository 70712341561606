import { ButtonSelect } from "components/Button/ButtonSelect";
import { FC } from "react";

import { ITableDataItem, ITableHeader } from "types/TableInterface";

interface Props<T> {
    zIndex: number;
    data: ITableDataItem<T>;
    header: ITableHeader;
}

export const TableSelectType: FC<Props<any>> = ({ zIndex, data, header }) => {
    return (
        <div className="">
            <ButtonSelect
                zIndex={zIndex}
                width={header?.styles?.buttonWidth || 'initial'}
                options={data[header?.key]?.options}
                value={data[header?.key]?.value}
                color={data[header?.key]?.color}
                preventDefault={data[header?.key]?.preventDefault}
                disabled={data[header?.key]?.disabled}
                loading={data?.rowSettings?.loading}
                handleSelect={
                    value => data[header?.key]?.action(value, data)
                }
            />
        </div>
    )
}