import { AxiosResponse } from 'axios';
import AuthService from "services/AuthService"

import { tokenStore } from 'store/tokenStore';

import { call, put, takeLatest } from 'redux-saga/effects';

import {
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    CHECK_AUTH_REQUEST,
} from 'store/types/authTypes';

import { setAuthData, setAuthIsLoading, setIsAuth } from 'store/actions/authAction';
import { setNotifications } from 'store/actions/notificationAction';


function* loginWorker({payload}: {type: string, payload: {login: string, password: string}}) {
    const { login, password } = payload;
    try {
        yield put(setAuthIsLoading(true));

        const response: AxiosResponse = yield call(AuthService.login, login, password);

        if (response?.status === 200) {
            yield put(setIsAuth(true))
            yield put(setAuthData({
                name: response?.data?.name,
                policies: response?.data?.policies,
            }))
            yield tokenStore.set(response?.data?.jwtToken)
        }
        yield put(setAuthIsLoading(false));
    } catch {
        yield put(setIsAuth(false));
        yield put(setAuthIsLoading(false));

        yield put(setNotifications([{id: Date.now(), bgColor: 'error', msg: 'Помилка авторизації'}]))
    }
}

function* logoutWorker() {
    try {
        // yield call(AuthService.logout);

        yield tokenStore.set('')
        yield put(setIsAuth(false))
    } catch {
        yield put(setNotifications([{id: Date.now(), bgColor: 'error', msg: 'Помилка виходу'}]))
    }
}

function* checkAuthWorker() {
    try {
        yield put(setAuthIsLoading(true));

        const response: AxiosResponse = yield call(AuthService.refresh);

        if (response?.status === 200) {
            yield put(setIsAuth(true))
            yield put(setAuthData({
                name: response?.data?.name,
                policies: response?.data?.policies,
            }))
            yield tokenStore.set(response?.data?.jwtToken)
        }
        yield put(setAuthIsLoading(false));
    } catch {
        yield put(setIsAuth(false));
        yield put(setAuthIsLoading(false));
        
        yield put(setNotifications([{id: Date.now(), bgColor: 'error', msg: 'Помилка перевірки авторизації'}]))
    }
}

export function* authWatcher() {
    yield takeLatest(LOGIN_REQUEST, loginWorker);
    yield takeLatest(LOGOUT_REQUEST, logoutWorker);
    yield takeLatest(CHECK_AUTH_REQUEST, checkAuthWorker);
}
