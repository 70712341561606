import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { ICallback } from "types/SagaInterface";
import { IAuthState } from "types/AuthInterface";
import { ITableHeader } from "types/TableInterface";
import { IOrderState, IShipmentStatusDTO, OrderNumberType } from "types/OrderInterface";
import {
    IComponentOrder,
    IComponentOrderState,
} from "types/ComponentOrderInterface";

import { WRITE_ORDER } from "store/types/policiesTypes";

import { getMovementStatusColor } from "utils/helpers/getStatusColor";
import { getShortText } from "utils/helpers/gerShortText";

import { IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

const rangeSliderSettings = { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] }
const searchSettings = { filter: ['search'], sort: ['ASC', 'DESC'] }

interface Props {}

export const ComponentsOrders: FC<Props> = () => {
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { componentsOrdersList } = useTypedSelector<IComponentOrderState>(state => state.component_order)
    const { shipmentStatusList } = useTypedSelector<IOrderState>(state => state.order)

    const {
        getShipmentStatusList,
        getComponentsOrdersList,
        deleteComponentOrder,
        setComponentOrderMovementStatus,
    } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    const [ searchParams ] = useSearchParams()
    const [ searchMovementId, setSearchMovementId ] = useState<number | null>(null);

    useEffect(() => {
        const idParam = Number(searchParams.get("movementId")) || null;
        setSearchMovementId(idParam);
    }, [searchParams]);

    useEffect(() => {
        getShipmentStatusList()
        getComponentsOrdersList(() => setIsDataLoading(false))
    }, [])

    function deleteOrderHandler(id: number) {
        setStatusLoading(true)
        deleteComponentOrder(id, (res: ICallback) => {
            if (res.success) getComponentsOrdersList(() => setStatusLoading(false))
            setStatusLoading(false)
        })
    }

    function toCreateComponentOrder() {
        navigate(`/dashboard/order/components-orders/create${location.search}`)
    }

    const handleMovementStatus = (movement_id: number, movement_status_id: (number | string)) => {
        setStatusLoading(true)
        setComponentOrderMovementStatus(
            movement_id,
            Number(movement_status_id) as OrderNumberType,
            (res: ICallback) => {
                if (res?.success) getComponentsOrdersList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        );
    }

    function canDelete(shipment_status_id: number) {
        if (shipment_status_id > 1) return true
        return false
    }

    const shipmentStatusOptions = useMemo(() => {
        return shipmentStatusList.reduce((acc: {[key: number]: string}, item: IShipmentStatusDTO) => {
            acc[item?.shipment_status_id] = item?.shipment_status_name;
            return acc
        }, {})
    }, [shipmentStatusList])

    const computedTableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "movement_id", label: "ID", type: 'text', settings: rangeSliderSettings },
            { key: "product_type_id", label: "Назва", type: 'text', settings: searchSettings },
            { key: "quantity", label: "Кількість", type: 'text', settings: searchSettings },
            { key: "price", label: "Ціна", type: 'text', settings: rangeSliderSettings },
            { key: "address", label: "Адреса", type: 'text', settings: searchSettings },
            { key: "notes", label: "Примітка", type: 'tooltip', action: getShortText, styles: {textAlign: 'center'}, settings: searchSettings },
            {
                key: "shipment_status",
                label: "Статус",
                type: authData?.policies.includes(WRITE_ORDER) ? 'select' : 'chip',
                styles: { buttonWidth: '100%', textAlign: 'center' },
                settings: {
                    filter: [{
                        key: 'button-list',
                        options: shipmentStatusList?.map((item: IShipmentStatusDTO) => ({
                                    label: item?.shipment_status_name,
                                    key: item?.shipment_status_id,
                                    btnColor: getMovementStatusColor(item?.shipment_status_id)
                                }))
                    }],
                    sort: ['ASC', 'DESC']
                }
            },
        ]
        if ([WRITE_ORDER].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [shipmentStatusList, authData?.policies])

    const getOrderStatus = useCallback((status: number) => {
        return {
            label: shipmentStatusList.find(el => el.shipment_status_id === status)?.shipment_status_name || '',
            value: Number(status),
            color: getMovementStatusColor(Number(status))
        }
    }, [shipmentStatusList, authData?.policies])

    const buildTableData = useMemo(() => {
        let data = componentsOrdersList

        if (searchMovementId) {
            data = componentsOrdersList?.filter(el => searchMovementId === el?.movement_id)
        }
        return data?.map((item: IComponentOrder, index: number) => {
            return {
                tableId: item?.movement_id,
                table_row_key: `movement_id_${item?.movement_id}_${index}`,
                movement_id: item.movement_id,
                product_type_id: item.product_type_id,
                quantity: item.quantity,
                price: item.price,
                address: item?.cMovements?.shipment?.shipping_address,
                notes: item?.cMovements?.shipment?.notes,
                shipment_status: authData?.policies.includes(WRITE_ORDER) ? {
                    options: shipmentStatusOptions,
                    color: getMovementStatusColor(item?.cMovements?.movement_status_id),
                    value: item?.cMovements?.movement_status_id,
                    action: (shipment_status_id: (number | string)) => {
                        handleMovementStatus( item.movement_id, shipment_status_id )
                    }
                } : getOrderStatus(item?.cMovements?.movement_status_id),
                rowSettings: {
                    loading: statusLoading,
                },
                actions: authData?.policies.includes(WRITE_ORDER) ? [
                    {
                        iconName: "Edit",
                        tooltip: 'Редагувати',
                        action: () => {
                            if (item?.movement_id) {
                                navigate(`/dashboard/order/components-orders/edit/${item.movement_id}${location.search}`)
                            }
                        },
                    },
                    {
                        iconName: "Delete",
                        confirmAction: () => deleteOrderHandler(item?.movement_id),
                        confirmationMsg: `Ви впевнені що хочете видалити ордер ID ${item?.movement_id}?`,
                        disabled: canDelete(item?.cMovements?.shipment?.shipStatus?.shipment_status_id),
                        iconColor: canDelete(item?.cMovements?.shipment?.shipStatus?.shipment_status_id) && 'disabled',
                        tooltip: canDelete(item?.cMovements?.shipment?.shipStatus?.shipment_status_id) ? 'Лише нові ордери можна видаляти' : 'Видалити',
                    },
                ] : [],
            }
        })
    }, [componentsOrdersList, statusLoading, shipmentStatusOptions, searchMovementId, authData?.policies])

    return (
        <div className="components-orders">
            {authData?.policies.includes(WRITE_ORDER) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateComponentOrder}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={computedTableHeaders}
                        tableData={buildTableData}
                        tableId="components-orders-table"
                        searchEntityId={searchMovementId}
                    />
                }
            </DataLoading>

            <Outlet/>
        </div>
    )
}