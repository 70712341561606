import $api from "services/http/AUTH_API";
import { AxiosResponse } from "axios";

import { IAuthData } from "types/AuthInterface";

export default class AuthService {

    static async login(login: string, password: string): Promise<AxiosResponse<IAuthData>> {
        return $api.post<IAuthData>('/auth-request', { login, password })
    }

    static async logout(): Promise<void> {
        return $api.post('/logout')
    }

    static async refresh(): Promise<AxiosResponse<IAuthData>> {
        return $api.get<IAuthData>('/refresh-token')
    }
}

// export default function authHeader() {
//     const user: IUser = JSON.parse(localStorage.getItem('user') || '')

//     if (user && user.accessToken) {
//         return {'x-access-token': user.accessToken}
//     } else {
//         return {}
//     }
// }