import { SET_OPERATORS_LIST } from "store/types/operatorTypes";

export interface OperatorDTO {
    id: number;
    phoneNumber: string;
    firstName?: string;
    lastName?: string;
    nickName?: string;
    unit?: string;
    milpost?: string;
    apps: AppDTO[];
}

export interface AppDTO {
    id: number;
    hashKey: string;
    version: string;
    activationStatusId: number;
    products: ProductDTO[];
}

export interface ProductDTO {}

export interface IOperatorState {
    operatorsList: OperatorDTO[];
}

interface ISetOperatorListAction {
    type: typeof SET_OPERATORS_LIST;
    payload: OperatorDTO[];
}

export type OperatorAction = ISetOperatorListAction

export enum EActivationStatus {
    WAIT = 1,
    ACTIVATED = 2,
    REJECTED = 3,
    DEACTIVATED = 4,
}
