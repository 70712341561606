import {
    SET_SOFTWARE_LIST,
    GET_SOFTWARE_LIST,
    UPLOAD_SOFTWARE,
    DOWNLOAD_SOFTWARE,
} from "store/types/softwareTypes";
import { ICallback } from "types/SagaInterface";

import { IFileUpload, SoftwareEntityType, SoftwareType } from "types/SoftwareInterface";

export const setSoftwareList = (softwareList: SoftwareType[]) => ({
    type: SET_SOFTWARE_LIST,
    payload: softwareList,
});

export const getSoftwareList = (entity: SoftwareEntityType, callback: (res: ICallback) => void) => ({
    type: GET_SOFTWARE_LIST,
    payload: {
        entity,
        callback,
    }
});

export const uploadSoftware = (
    data: IFileUpload,
    entity: SoftwareEntityType,
    callback: (success: boolean) => void
) => ({
    type: UPLOAD_SOFTWARE,
    payload: {
        data,
        entity,
        callback,
    }
})

export const downloadSoftware = (id: number, entity: SoftwareEntityType, title: string) => ({
    type: DOWNLOAD_SOFTWARE,
    payload: {
        id,
        entity,
        title
    }
});