import { FC } from "react";

import { DraggableCardList, IDraggableCardListData, IListItem } from "./DraggableCardList";

import { Card, CardContent } from "@mui/material";

interface Props {
    lists: {
        [key: string]: IListItem[]
    };
    rightListKey: string;
    leftListKey: string;
    leftData: IDraggableCardListData;
    rightData: IDraggableCardListData;
    additionalListData: IDraggableCardListData;
    isDisabled?: boolean;
}

export const DragAndDropCard: FC<Props> = ({
    lists,
    rightListKey,
    leftListKey,
    leftData,
    rightData,
    additionalListData,
}) => {

    let leftSide = [
        DraggableCardList({
            list: lists[leftListKey],
            listId: leftListKey,
            data: leftData,
        }),
    ];

    leftSide = [
        ...leftSide,
        ...Object.keys(lists).reduce<any[]>((acc, listId) => {
            if (listId === leftListKey) return acc;
            if (listId === rightListKey) return acc;
            if (lists[listId].length > 0) {
                return [
                    ...acc,
                    DraggableCardList({
                        list: lists[listId],
                        listId,
                        data: additionalListData
                    })
                ];
            }
            return acc;
        }, []),
    ];

    const rightSide = DraggableCardList({
        list: lists[rightListKey],
        listId: rightListKey,
        data: rightData,
    });

    return (
        <Card className="scroll-bar-primary-y" style={{overflowY: "auto"}}>
            <CardContent style={{display: "flex", flexDirection: "row"}}>

                <CardContent style={{display: "flex", flexDirection: "column"}}>
                    {leftSide}
                </CardContent>
                
                <CardContent>
                    {rightSide}
                </CardContent>

            </CardContent>
        </Card>
    );
}