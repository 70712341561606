import {
    SET_ORDER_PRODUCTS,
    SET_ORDER_STATUS_LIST,
    SET_MOVEMENT_STATUS_LIST,
    SET_SHIPMENT_STATUS_LIST,
    SET_ORDERS_LIST,
    SET_AVAILABLE_ORDERS_SHIPMENT,
    SET_SINGLE_ORDER,
    SET_SINGLE_ORDER_ITEM,
} from "store/types/orderTypes"

import {
    IMovementStatusDTO,
    IOrderStatus,
    IOrder,
    ISingleOrderItem,
    IOrderState,
    OrderAction,
    IShipmentStatusDTO
} from "types/OrderInterface"

import { IProductDTO } from "types/ProductInterface";

const initialState: IOrderState = {
    orderProducts: {
        availableProducts: [] as IProductDTO[],
        currentProducts: [] as IProductDTO[],
    },
    orderStatusList: [] as IOrderStatus[],
    movementStatusList: [] as IMovementStatusDTO[],
    shipmentStatusList: [] as IShipmentStatusDTO[],
    stateOrderData: {} as IOrder,
    stateOrderItem: {} as ISingleOrderItem,
    orderList: [],
    availableOrdersShipment: [],
};

export const orderReducer = (state = initialState, action: OrderAction): IOrderState => {
    switch (action.type) {
        case SET_ORDER_PRODUCTS:
            return { ...state, orderProducts: action.payload }
        case SET_ORDER_STATUS_LIST:
            return { ...state, orderStatusList: action.payload }
        case SET_MOVEMENT_STATUS_LIST:
            return { ...state, movementStatusList: action.payload }
        case SET_SHIPMENT_STATUS_LIST:
            return { ...state, shipmentStatusList: action.payload }
        case SET_SINGLE_ORDER:
            return { ...state, stateOrderData: action.payload }
        case SET_SINGLE_ORDER_ITEM:
            return { ...state, stateOrderItem: action.payload }
        case SET_ORDERS_LIST:
            return { ...state, orderList: action.payload }
        case SET_AVAILABLE_ORDERS_SHIPMENT:
            return { ...state, availableOrdersShipment: action.payload }
        default:
            return state
    }
}