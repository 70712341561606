import { SET_COMPONENTS_LIST, SET_SINGLE_COMPONENT } from "store/types/componentTypes"
import { IComponentState, ComponentAction, IComponent } from "types/ComponentInterface"

const initialState: IComponentState = {
    stateComponentData: {} as IComponent,
    componentsList: []
}

export const componentReducer = (state = initialState, {type, payload}: ComponentAction): IComponentState => {
    switch (type) {
        case SET_SINGLE_COMPONENT:
            return { ...state, stateComponentData: payload }
        case SET_COMPONENTS_LIST:
            return { ...state, componentsList: payload }
        default:
            return state
    }
}