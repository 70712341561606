import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { FC } from "react";

interface Props {
    keyName: string;
    data: string[];
    sortASC: () => void;
    sortDESC: () => void;
}

export const SortingBar: FC<Props> = ({keyName, data, sortASC, sortDESC}) => {

    function getIconColor(key: string, direction: string) {
        if (key === data[0] && direction === data[1]) return 'primary'
        return 'inherit'
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <ArrowDropUp
                color={getIconColor(keyName, 'DESC')}
                className="filter-icon"
                sx={{marginTop: '-6px'}}
                onClick={sortDESC}
            />
            <ArrowDropDown
                color={getIconColor(keyName, 'ASC')}
                className="filter-icon"
                sx={{marginTop: '-11px', marginBottom: '-10px'}}
                onClick={sortASC}
            />
        </div>
    )
}