export const CREATE_ORDER = "CREATE_ORDER"
export const CREATE_ORDER_ITEM = "CREATE_ORDER_ITEM"
export const SET_SINGLE_ORDER = "SET_SINGLE_ORDER"
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER"
export const SET_SINGLE_ORDER_ITEM = "SET_SINGLE_ORDER_ITEM"
export const GET_SINGLE_ORDER_ITEM = "GET_SINGLE_ORDER_ITEM"
export const SET_ORDERS_LIST = 'SET_ORDERS_LIST'
export const GET_ORDERS_LIST = "GET_ORDERS_LIST"
export const SET_AVAILABLE_ORDERS_SHIPMENT = "SET_AVAILABLE_ORDERS_SHIPMENT"
export const GET_AVAILABLE_ORDERS_SHIPMENT = "GET_AVAILABLE_ORDERS_SHIPMENT"
export const UPDATE_SINGLE_ORDER = "UPDATE_SINGLE_ORDER"
export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM"
export const UPDATE_ORDER_SHIPMENT = "UPDATE_ORDER_SHIPMENT"
export const SPLIT_PRODUCT_ORDER_ITEM = "SPLIT_PRODUCT_ORDER_ITEM"
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS'
export const SET_MOVEMENT_STATUS= "SET_MOVEMENT_STATUS"
export const SET_ORDER_STATUS_LIST = "SET_ORDER_STATUS_LIST"
export const GET_ORDER_STATUS_LIST = "GET_ORDER_STATUS_LIST"
export const SET_MOVEMENT_STATUS_LIST = "SET_MOVEMENT_STATUS_LIST"
export const GET_MOVEMENT_STATUS_LIST = "GET_MOVEMENT_STATUS_LIST"
export const SET_SHIPMENT_STATUS_LIST = "SET_SHIPMENT_STATUS_LIST"
export const GET_SHIPMENT_STATUS_LIST = "GET_SHIPMENT_STATUS_LIST"
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_ITEM = "DELETE_ORDER_ITEM"
export const SET_ORDER_PRODUCTS = "SET_ORDER_PRODUCTS"
export const GET_ORDER_PRODUCTS = "GET_ORDER_PRODUCTS"