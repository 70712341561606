import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DragAndDropCard } from "components/DragAndDrop/DragAndDropCard";
import { DroppableShift } from "components/DragAndDrop/DroppableShift";
import { DataLoading } from "components/Loader/DataLoading";
import { EmptyDataInfo } from "components/Common/EmptyDataInfo";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { IProductDTO, IProductState } from "types/ProductInterface";
import { IOrderState, ISplitOrderItemDTO } from "types/OrderInterface";
import { ChipColorType, IChip } from "types/MaterialInterface";

import { getProductStatusColor } from "utils/helpers/getStatusColor";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Card,
    CardContent,
    Button,
    DialogActions,
} from "@mui/material";

import { DragDropContext, DropResult } from "react-beautiful-dnd";

interface Item {
    id: number;
    content: string;
    chip: IChip;
    isDisabled?: boolean;
}

type ListState<T> = {
    [id: string]: T[];
};

const currentOrderKey = "list-1";
const availableProductsKey = "list-2";

const initialState = {
    "list-1": [],
    "list-2": [],
};

export const AssignProductDnD: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false);

    const [nextListId, setNextListId] = useState<number>(3);
    const [dataLists, setDataLists] = useState<ListState<Item>>(initialState);
    const [instanceDataLists, setInstanceDataLists] =
        useState<ListState<Item>>(initialState);

    const { productStatusList } = useTypedSelector<IProductState>(
        (state) => state.product
    );
    const { orderProducts } = useTypedSelector<IOrderState>(
        (state) => state.order
    );

    const { setOrderProducts, getOrderProducts, getProductStatusList, splitOrderItemByProduct, getOrdersList } =
        useActions();

    const params = useParams();
    const location = useLocation();

    const quantity = Number(params.quantity);

    const navigate = useNavigate();
    const closeModal = () => {
        navigate(`/dashboard/order/customer/confirmed${location.search}`);
    };

    useEffect(() => {
        getProductStatusList();
    }, []);

    useEffect(() => {
        setOrderProducts({
            availableProducts: [] as IProductDTO[],
            currentProducts: [] as IProductDTO[],
        });
        getOrderProducts(Number(params.orderItemId), (success: boolean) => {
            if (!success) setLoadingFailed(true);
            setIsDataLoading(false);
        });
    }, [params.orderItemId]);

    const statuses = useMemo(() => {
        return productStatusList.reduce<Map<number, string>>((acc, item) => {
            acc.set(item.status_id, item.status_title);
            return acc;
        }, new Map<number, string>());
    }, [productStatusList]);

    const getListData = useCallback(
        (list: IProductDTO[]) => {
            return list.map((product) => ({
                id: product.product_id,
                content: `${product.series.series_code}-${product.serial_number.code}`,
                chip: {
                    label:
                        statuses.get(product.product_status_id) ||
                        ("" as string),
                    color: getProductStatusColor(
                        product.product_status_id
                    ) as ChipColorType,
                },
                isDisabled: product.product_status_id > 3,
            }));
        },
        [statuses]
    );

    useEffect(() => {
        const data = {
            "list-1": getListData(orderProducts.currentProducts),
            "list-2": getListData(orderProducts.availableProducts),
        };
        setDataLists({ ...data });
        setInstanceDataLists(JSON.parse(JSON.stringify(data)));
    }, [
        statuses,
        orderProducts.currentProducts,
        orderProducts.availableProducts,
    ]);

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (destination?.droppableId === currentOrderKey) {
            if (dataLists[currentOrderKey]?.length >= quantity) {
                return;
            }
        }

        if (!destination) return;

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                dataLists[source.droppableId],
                source.index,
                destination.index
            );

            setDataLists({ ...dataLists, [source.droppableId]: items });
        } else {
            const sourceList = dataLists[source.droppableId];
            const destList =
                destination.droppableId === "new-list"
                    ? []
                    : dataLists[destination.droppableId] ?? [];
            const [removed] = sourceList.splice(source.index, 1);

            if (destination.droppableId === "new-list") {
                const newListId = `list-${nextListId}`;
                setDataLists({ ...dataLists, [newListId]: [removed] });
                setNextListId((prevValue) => prevValue + 1);
            } else {
                destList.splice(destination.index, 0, removed);

                const data = {
                    ...dataLists,
                    [destination.droppableId]: destList,
                };
                if (sourceList.length > 0)
                    data[source.droppableId] = sourceList;
                else delete data[source.droppableId];

                setDataLists({ ...data });
            }
        }
    };

    useEffect(() => {
        const disabled: boolean =
            JSON.stringify(dataLists) === JSON.stringify(instanceDataLists);

        setIsDisabled(disabled);
    }, [instanceDataLists, dataLists]);

    const updateHandler = () => {
        const request: ISplitOrderItemDTO = {
            order_item_id: Number(params.orderItemId),
            movement_id: Number(params.movementId),
            orderProductIds: (dataLists[currentOrderKey] ?? []).map((item) => item.id),
            newOrderProducts: Object.keys(dataLists).reduce(
                (acc: number[][], listId) => {
                    if (listId === currentOrderKey) return acc;
                    if (listId === availableProductsKey) return acc;
                    return [...acc, dataLists[listId].map((item) => item.id)];
                },
                []
            ),
        };

        splitOrderItemByProduct(request, (success: boolean) => {
            if (success) {
                getOrdersList()
                closeModal()
            }
        });
    };

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            aria-labelledby="dialog-title"
            fullWidth
            maxWidth="md"
        >
            <DialogTitle id="dialog-title">
                Прив'язати продукт - {params.productType}
            </DialogTitle>

            <DragDropContext onDragEnd={onDragEnd}>
                <DialogContent
                    style={{
                        display: "flex",
                        justifyContent: "space-around",
                        padding: "8px",
                    }}
                >
                    <DataLoading isDataLoading={isDataLoading}>
                        <EmptyDataInfo
                            entity="продукту"
                            dataLength={
                                orderProducts.availableProducts.length ||
                                orderProducts.currentProducts.length
                            }
                            loadingFailed={loadingFailed}
                            to="/dashboard/warehouse/products/create"
                        >
                            <Card
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <DragAndDropCard
                                    lists={dataLists}
                                    leftListKey={currentOrderKey}
                                    rightListKey={availableProductsKey}
                                    leftData={{
                                        title: "Поточне замовлення",
                                        subtitle: `${
                                            dataLists[currentOrderKey]
                                                ?.length ?? 0
                                        }/${quantity}`,
                                        quantity,
                                    }}
                                    rightData={{ title: "Доступні продукти" }}
                                    additionalListData={{
                                        title: "Нове замовлення",
                                    }}
                                />

                                <CardContent
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <DroppableShift />
                                </CardContent>
                            </Card>
                        </EmptyDataInfo>
                    </DataLoading>
                </DialogContent>
            </DragDropContext>

            <DialogActions>
                <Button variant="outlined" onClick={closeModal}>
                    Закрити
                </Button>
                <Button
                    variant="contained"
                    disabled={isDisabled}
                    onClick={updateHandler}
                >
                    Зберегти
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const reorder = (
    list: Item[],
    startIndex: number,
    endIndex: number
): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
