import { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Typography, Button, Alert } from '@mui/material';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';
import { INotification, INotificationBtn, INotificationState } from 'types/NotificationInterface';

export const NotificationItem: FC<INotification> = ({id, severity, bgColor = 'success', msg, duration = 6000, btn}) => {
    const { notifications } = useTypedSelector<INotificationState>(state => state.notification)

    const location = useLocation();

    const { deleteNotification } = useActions()

    const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
        
        if (reason === 'clickaway') {
            return;
        }
        // Find index of notification in the store, if exists - delete notification
        const ntfIndex = notifications.findIndex(el => el.id === id)
        if (ntfIndex >= 0) deleteNotification(id)
    };

    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        // Set the setTimeout timer and store its ID in the state
        const timerId = setTimeout(() => {
            handleClose();
        }, duration);

        setTimeoutId(timerId);

        return () => {
            // Clear the setTimeout timer when the component unmounts
            if (timeoutId) clearTimeout(timeoutId);

            // Delete notification with id 'confirm-action' when component unmounts or url is changed
            if (id === 'confirm-action') deleteNotification(id);
        };
    }, [location]);

    return (
        <Alert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={severity || bgColor}
            color={bgColor}
            sx={{ width: '100%' }}
        >
            {typeof msg === 'string' &&
                <Typography className='notification-message'>{msg}</Typography>
            }
            {(Array.isArray(msg) && msg instanceof Array) && msg.map((text, idx) => (
                    <Typography key={idx} className='notification-message'>
                        {text}
                    </Typography>
                ))
            }
            <Box className="notification-actions">
                {btn &&
                    btn.map((item: INotificationBtn, index: number) =>
                        <Button
                            key={index}
                            size="small"
                            variant={item.variant}
                            color={item.color}
                            onClick={() => {
                                item.action && item.action()
                                handleClose()
                            }}
                        >
                            {item.label}
                        </Button>
                    )
                }
            </Box>
        </Alert>
    );
}