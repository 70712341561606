import { DELETE_NOTIFICATION, SET_NOTIFICATIONS } from "store/types/notificationTypes";
import { INotification } from "types/NotificationInterface";
import { IError } from "types/ErrorInterface";

function computedNotifications(msgs: string[] | string, bgColor: string, duration: number, error?: IError) {
    let notifications: {
        id: number;
        bgColor: string;
        msg: string;
        duration: number
    }[] = []

    let msgsArray: string[] = []

    if (typeof msgs === 'string' && msgs) msgsArray.push(msgs)
    else if (Array.isArray(msgs) && msgs instanceof Array) msgsArray.push(...msgs)

    if (error instanceof Error && error.message) msgsArray.push(error.message)
    if (error instanceof Error && (error as IError)?.response?.data?.message) {
        if (error.response.data.message) msgsArray.push(error.response.data.message);
      }

    notifications = msgsArray.map((msg, idx) => ({ id: Date.now() + idx, bgColor, msg, duration }))

    return notifications
}

export const setErrorNotifications = (msgs: string[] | string, error?: IError) => {
    const notifications = computedNotifications(msgs, 'error', 10000, error)
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications,
    }
};

export const setWarningNotifications = (msgs: string[] | string) => {
    const notifications = computedNotifications(msgs, 'warning', 10000)
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications,
    }
};

export const setInfoNotifications = (msgs: string[] | string) => {
    const notifications = computedNotifications(msgs, 'info', 6000)
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications,
    }
};

export const setSuccessNotifications = (msgs: string[] | string) => {
    const notifications = computedNotifications(msgs, 'success', 6000)
    return {
        type: SET_NOTIFICATIONS,
        payload: notifications,
    }
};

export const setNotifications = (notifications: INotification[]) => ({
    type: SET_NOTIFICATIONS,
    payload: notifications,
});

export const deleteNotification = (id: (number | string)) => ({
    type: DELETE_NOTIFICATION,
    payload: id,
});