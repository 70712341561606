import { FC } from "react";
import { Navigate } from "react-router-dom";

import { PageTabs } from "components/Tabs/PageTabs";

import { useTypedSelector } from "hooks/useTypedSelector";
import { IAuthState } from "types/AuthInterface";

import { READ_WAREHOUSE } from "store/types/policiesTypes";

const tabs = [
    {key: 'components', label: 'Компоненти'},
    {key: 'product-types', label: 'Типи продуктів'},
    {key: 'recipes', label: 'Рецепти'},
    {key: 'products', label: 'Продукти'},
    {key: 'series', label: 'Серії'},
]

interface Props {}

export const Warehouse: FC<Props> = () => {
    const { authData, isAuth } = useTypedSelector<IAuthState>(state => state.auth)

    if (authData?.policies.includes(READ_WAREHOUSE) && isAuth) {
        return (
            <div className="warehouse">
                <PageTabs tabs={tabs} basePath="/dashboard/warehouse"/>
            </div>
        )
    }

    return <Navigate to="/permission-denied"/>
}