import {
    SET_AVAILABLE_PRODUCTS,
    SET_PRODUCTS_LIST,
    SET_SINGLE_PRODUCT,
    SET_PRODUCT_STATUS_LIST,
} from "store/types/productTypes";

import { IProductType } from "./ProductTypeInterface";
import { ISerialNumberDTO, ISeriesDTO } from "./SeriesInterface";

export interface IAssignProduct {
  order_item_id: number;
  product_id: number;
  movement_id: number;
}

export enum EProductQuantity {
    DEFAULT = 1,
    MIN_QUANTITY = 1,
}

export interface IProductStatus {
    status_id: number;
    status_code: string;
    status_title: string;
    only_view: boolean
}

export interface IProductCreate {
    product_type_id: number;
    serial_number_id: number;
    notes: string;
}

export interface IProductUpdate {
    notes: string;
}

export interface IProductDTO {
    product_status_id: number;
    product_id: number;
    serial_number: Pick<ISerialNumberDTO, 'serial_number_id'| 'code'>;
    series: Pick<ISeriesDTO, 'series_id' | 'series_code'>;
    product_type: IProductType;
    notes: string;
    isBusy: boolean;
    order_id: number;
}

export interface IProductState {
    productStatusList: IProductStatus[];
    stateProductData: IProductDTO;
    productsList: IProductDTO[];
    availableProducts: IProductDTO[];
}

interface ISetProductStatusListAction {
    type: typeof SET_PRODUCT_STATUS_LIST;
    payload: IProductStatus[];
}

interface ISetAvailableProductsAction {
    type: typeof SET_AVAILABLE_PRODUCTS;
    payload: IProductDTO[];
}

interface ISetProductListAction {
    type: typeof SET_PRODUCTS_LIST;
    payload: IProductDTO[];
}

interface ISetSingleProductAction {
    type: typeof SET_SINGLE_PRODUCT;
    payload: IProductDTO;
}

export type ProductAction = 
    ISetAvailableProductsAction |
    ISetProductListAction |
    ISetSingleProductAction |
    ISetProductStatusListAction;

export enum EProductStatus {
    NEW = 1,
    IN_PROGRESS = 2,
    DONE = 3,
    DELIVERED = 4,
    ACTIVE = 5,
    BROKEN = 6,
    ON_REPAIR = 7
}

export type ProductStatusType = typeof EProductStatus[keyof typeof EProductStatus];
