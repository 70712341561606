import {
    SET_SINGLE_PRODUCT,
    SET_PRODUCTS_LIST,
    SET_AVAILABLE_PRODUCTS,
    SET_PRODUCT_STATUS_LIST,
} from "store/types/productTypes"

import { IProductDTO, IProductState, IProductStatus, ProductAction } from "types/ProductInterface"

const initialState: IProductState = {
    productStatusList: [] as IProductStatus[],
    stateProductData: {} as IProductDTO,
    productsList: [] as IProductDTO[],
    availableProducts: [] as IProductDTO[],
}

export const productReducer = (state = initialState, {type, payload}: ProductAction): IProductState => {
    switch (type) {
        case SET_PRODUCT_STATUS_LIST:
            return { ...state, productStatusList: payload }
        case SET_SINGLE_PRODUCT:
            return { ...state, stateProductData: payload }
        case SET_PRODUCTS_LIST:
            return { ...state, productsList: payload }
        case SET_AVAILABLE_PRODUCTS:
            return { ...state, availableProducts: payload }
        default:
            return state
    }
}