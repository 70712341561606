import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useOutletContext } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { ISoftwareState, SoftwareType } from "types/SoftwareInterface";
import { ITableHeader } from "types/TableInterface";

import { IconButton } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

const tableHeaders: ITableHeader[]  = [
    { key: "id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
    { key: "version", label: "Версія", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "title", label: "Назва", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
    { key: "actions", type: 'actions', styles: {textAlign: 'right'} },
]

interface Props {}

export const Software: FC<Props> = () => {
    const { softwareList } = useTypedSelector<ISoftwareState>(state => state.software)
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);

    const { uploadModalTitle, entity }: {[key: string]: string} = useOutletContext();

    const { getSoftwareList, setSoftwareList, downloadSoftware } = useActions()

    const navigate = useNavigate()

    function toUploadSortware() {
        navigate(`/dashboard/rsfs/${entity}/upload`)
    }

    // function deleteSoftwareHandler(id: number) {
    //     setStatusLoading(true)
    //     deleteSoftware(
    //         id,
    //         (success: boolean) => {
    //             if (success) getSoftwareList(() => setStatusLoading(false))
    //             else setStatusLoading(false)
    //         }
    //     );
    // }
    
    const buildTableData = useMemo(() => {
        const data = softwareList.map((item: SoftwareType, idx: number) => {
            return {
                tableId: item.id,
                table_row_key: `${entity}_id_${item?.id}_${idx}`,
                id: item?.id,
                version: item.version,
                title: item.title,
                rowSettings: {
                    loading: statusLoading,
                },
                actions: [
                    {
                        iconName: "Download",
                        action: () => downloadSoftware(item.id, entity, item.title),
                        tooltip: 'Завантажити',
                    },
                    // {
                    //     iconName: "Delete",
                    //     confirmAction: () => deleteSoftwareHandler(item.id),
                    //     confirmationMsg: `Ви впевнені що хочете видалити файл?`,
                    //     disabled: item?.isBusy,
                    //     iconColor: item?.isBusy && 'disabled',
                    //     tooltip: item?.isBusy ? 'Файл не можна видалити' : 'Видалити',
                    // },
                ]
            }
        })
        return data
    }, [softwareList, statusLoading, entity])

    useEffect(() => {
        getSoftwareList(entity, () => setIsDataLoading(false))
        return () => setSoftwareList([])
    }, [entity])

    return (
        <div className="software">
            <IconButton
                color="primary"
                edge="start"
                size="large"
                sx={{m: 0}}
                onClick={toUploadSortware}
            >
                <CloudUpload fontSize="large"/>
            </IconButton>

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId={`${entity}-table`}
                    />
                }
            </DataLoading>

            <Outlet context={{
                title: uploadModalTitle,
                entity,
            }}/>
        </div>
    )
}