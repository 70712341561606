// import { ButtonPropsColorOverrides } from '@mui/material/Button';
import {
    createTheme,
    PaletteColorOptions,
} from '@mui/material/styles';
  
declare module '@mui/material/styles' {
    interface CustomPalette {
        anger: PaletteColorOptions;
        apple: PaletteColorOptions;
        steelBlue: PaletteColorOptions;
        violet: PaletteColorOptions;
        purple: PaletteColorOptions;
        teal: PaletteColorOptions;
        plum: PaletteColorOptions;
        snow: PaletteColorOptions;
    }
    interface Palette extends CustomPalette {}
    interface PaletteOptions extends CustomPalette {}
}
  
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        anger: true;
        apple: true;
        steelBlue: true;
        violet: true;
        purple: true;
        teal: true;
        plum: true;
        snow: true;
    }
}
  
const { palette } = createTheme();
const { augmentColor } = palette;
  
const createColor = (mainColor: any) => augmentColor({ color: { main: mainColor, contrastText: '#ffffff' } });
  
export const buttonColorTheme = createTheme({
    palette: {
        anger: createColor('#F40B27'),
        apple: createColor('#5DBA40'),
        steelBlue: createColor('#5C76B7'),
        violet: createColor('#BC00A3'),
        purple: createColor('#800080'),
        teal: createColor('#008080'),
        plum: createColor('#6542b7'),
        snow: createColor('#fffafa'),
    },
});

export type MaterialColorType =
    'secondary' | 'success' | 'primary' | 'warning' | 'error' | 'info' |
    'anger' | 'apple' | 'steelBlue' | 'violet' | 'purple' | 'teal' | 'plum' | 'snow';