import { FC } from "react";

import { Filter } from "./Filter";
import { SortingBar } from "./Sorting";

import { IFilterData, ITableItemStyles } from "types/TableInterface";

import './filter-sort-bar.scss'

interface Props {
    keyName: string;
    data: any;
    slidersRange: number[];
    filterData: IFilterData;
    sortData: string[];
    styles?: {[key: string]: string | number},
}

export const FilterSortBar: FC<Props> = ({
    styles,
    keyName,
    data,
    slidersRange,
    filterData,
    sortData,
}) => {

    function getFilterIconsStyles(styles: ITableItemStyles | undefined) {
        let output = {
            justifyContent: 'inherit'
        }
        if (styles?.textAlign === 'right') output.justifyContent = 'flex-end'
        if (styles?.textAlign === 'center') output.justifyContent = 'center'
        if (styles?.textAlign === 'left') output.justifyContent = 'flex-start'
        if (styles?.textAlign === 'inherit') output.justifyContent = 'inherit'
        if (styles?.textAlign === 'justify') output.justifyContent = 'space-between'
        return output
    }

    return (
        <div
            className='filter-icons'
            style={getFilterIconsStyles(styles)}
        >

            {data?.filter &&
                <Filter
                    range={slidersRange}
                    keyName={keyName}
                    filterData={filterData}
                    data={data.filter}
                />
            }

            {data?.sort &&
                <SortingBar
                    keyName={keyName}
                    data={sortData}
                    sortASC={data.sort?.sortASC}
                    sortDESC={data.sort?.sortDESC}
                />
            }
        </div>
    )
}