import { EMovementStatus, EOrderStatus } from "types/OrderInterface";
import { EProductStatus } from "types/ProductInterface";
import { EUnconfirmedOrderStatus } from "types/UnconfirmedOrderInterface";

export type ProductColorType = "primary" | "success" | "secondary" | "teal" | "plum" | "error" | "warning"

export function getProductStatusColor(status: number): ProductColorType {
    switch (status) {
        case EProductStatus.NEW: return "primary";
        case EProductStatus.IN_PROGRESS: return "success";
        case EProductStatus.DONE: return "secondary";
        case EProductStatus.DELIVERED: return "teal";
        case EProductStatus.ACTIVE: return "plum";
        case EProductStatus.BROKEN: return "error";
        case EProductStatus.ON_REPAIR: return "warning";
        default: return "primary";
    }
}

export type OrderColorType = "primary" | "success" | "secondary" | "warning"

export function getOrderStatusColor(status: number): OrderColorType {
    switch (status) {
        case EOrderStatus.NEW: return'primary'
        case EOrderStatus.IN_PROGRESS: return 'success'
        case EOrderStatus.COMPLETED: return 'secondary'
        case EOrderStatus.DECLINED: return 'warning'
        default: return 'primary'
    }
}

export type MovementColorType = "primary" | "success" | "teal" | "error"

export function getMovementStatusColor(status: number): MovementColorType {
    switch (status) {
        case EMovementStatus.NEW: return "primary";
        case EMovementStatus.IN_PROGRESS: return "success";
        case EMovementStatus.DONE: return "teal";
        case EMovementStatus.DELETE: return "error";
        default: return "primary";
    }
}

export type UnconfirmedOrderColorType = "primary" | "success" | "teal" | "error"

export function getUnconfirmedOrderStatusColor(status: number): UnconfirmedOrderColorType {
    switch (status) {
        case EUnconfirmedOrderStatus.UNCONFIRMED: return "primary";
        case EUnconfirmedOrderStatus.CONFIRMED: return "success";
        case EUnconfirmedOrderStatus.DECLINE: return "teal";
        case EUnconfirmedOrderStatus.DELETE: return "error";
        default: return "primary";
    }
}