import { FC } from "react";
import { useTypedSelector } from "hooks/useTypedSelector";
import { IAuthState } from "types/AuthInterface";

interface Props {}

export const DeliveryStatus: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)

    return (
        <div className="delivery-status">
            DeliveryStatus tab
        </div>
    )
}