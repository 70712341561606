import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import {
    ICreateOrderItem,
    IOrder,
    IOrderProducts,
    IOrderStatus,
    IMovementStatusDTO,
    IShipmentStatusDTO,
    ISplitOrderItemDTO,
    ISingleOrderItem,
    SingleOrderDataType,
    IUpdateOrderItem,
    IUpdateOrderShipmentDTO,
} from "types/OrderInterface";

import { IResponseMessage } from "types/ResponseInterface";

const path_name = '/order'
const order_item_path = '/order-item'

export default class OrderService {

    static async create(data: SingleOrderDataType): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`${path_name}`, data);
    }

    static async createOrderItem(id: number, data: ICreateOrderItem): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`${order_item_path}/${id}`, data);
    }

    static async getSingle(order_id: number): Promise<AxiosResponse<IOrder>> {
        return $api.get<IOrder>(`${path_name}/${order_id}`);
    }

    static async getSingleOrderItem(order_item_id: number): Promise<AxiosResponse<ISingleOrderItem>> {
        return $api.get<ISingleOrderItem>(`${order_item_path}/${order_item_id}`);
    }

    static async getAll(): Promise<AxiosResponse<IOrder[]>> {
        return $api.get<IOrder[]>(`${path_name}`);
    }

    static async getAvailableOrdersShipment(): Promise<AxiosResponse<IOrder[]>> {
        return $api.get<IOrder[]>(`/order/available-to-shipment`);
    }

    static async update(order_id: number, data: SingleOrderDataType): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`${path_name}/${order_id}`, data);
    }

    static async updateOrderItem(order_item_id: number, data: IUpdateOrderItem): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`${order_item_path}/${order_item_id}`, data);
    }

    static async updateOrderShipment(order_item_id: number, data: IUpdateOrderShipmentDTO): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`${order_item_path}/ship/${order_item_id}`, data);
    }

    static async delete(order_id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${path_name}/${order_id}`);
    }

    static async deleteOrderItem(order_item_id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${order_item_path}/${order_item_id}`);
    }

    static async updateOrderStatus(order_id: number, order_status: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`${path_name}/update-order-status/${order_id}/${order_status}`);
    }

    static async updateMovementStatus(movement_id: number, movement_status_id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`${path_name}/update-movement-status/${movement_id}/${movement_status_id}`);
    }

    static async getOrderStatusList(): Promise<AxiosResponse<IOrderStatus[]>> {
        return $api.get<IOrderStatus[]>(`/order-status`);
    }

    static async getMovementStatusList(): Promise<AxiosResponse<IMovementStatusDTO[]>> {
        return $api.get<IMovementStatusDTO[]>(`/movement-status`);
    }

    static async getShipmentStatusList(): Promise<AxiosResponse<IShipmentStatusDTO[]>> {
        return $api.get<IShipmentStatusDTO[]>(`/shipment-status`);
    }

    static async getOrderProducts(id: number): Promise<AxiosResponse<IOrderProducts>> {
        return $api.get<IOrderProducts>(`/product-for-order/${id}`);
    }
    
    static async splitOrderItemByProduct(data: ISplitOrderItemDTO): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`/split-order-item`, data);
    }
}