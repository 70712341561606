import { FC, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { DashboardContent } from "components/Layout/DashboardContent";
import { styled, Box, CssBaseline } from "@mui/material";

import { Sidebar } from "components/Sidebar/Sidebar";
import { Header } from "components/Header/Header";

import { useTypedSelector } from "hooks/useTypedSelector";

import { IAuthState } from "types/AuthInterface";

import './dashboard-layout.scss'

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

interface Props {}

export const DashboardLayout: FC<Props> = () => {
    const [isOpened, setIsOpened] = useState(Boolean(localStorage.getItem('menu-open-status')));

    const { isAuth } = useTypedSelector<IAuthState>(state => state.auth)

    const location = useLocation()

    const redirect = (): boolean => {
        const pathname: string = location.pathname.replace(/\/+$/, '');
        if ( pathname === '/dashboard' || pathname === '/dashboard/warehouse') return true
        return false
    }

    const drawerWidth = 240;

    const handleDrawerOpen = () => {
        setIsOpened(true);
        localStorage.setItem('menu-open-status', 'opened')
    };

    const handleDrawerClose = () => {
        setIsOpened(false);
        localStorage.removeItem('menu-open-status')
    };

    useEffect(() => {
        const menuStatus = localStorage.getItem('menu-open-status')
        if (menuStatus) setIsOpened(true);
        else setIsOpened(false);
    }, [])

    if (isAuth) {
        return (
            <div className="dashboard-layout text-center">
                <Box sx={{ display: 'flex' }}>
                    <CssBaseline />
                    <Header
                        drawerWidth={drawerWidth}
                        drawerIsOpened={isOpened}
                        openDrawer={handleDrawerOpen}
                    />
                    <Sidebar
                        drawerWidth={drawerWidth}
                        isOpened={isOpened}
                        closeDrawer={handleDrawerClose}
                        DrawerHeader={DrawerHeader}
                    />
                    <DashboardContent isOpened={isOpened} drawerWidth={drawerWidth}>
                        {/* <DrawerHeader/> */}
                        <Outlet/>
                    </DashboardContent>
                </Box>
                
                {redirect() && <Navigate to="/dashboard/warehouse/components"/>}
            </div>
        )
    }
    
    return <Navigate to="/permission-denied"/>
}