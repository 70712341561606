import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from 'router/AppRouter';

import { ThemeProvider } from '@emotion/react';

import { buttonColorTheme } from 'utils/material/buttonColorTheme';

import './App.css';

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={buttonColorTheme}>
                <BrowserRouter>
                    <AppRouter/>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
