import {
    SET_AUTH_IS_LOADING,
    SET_IS_AUTH,
    SET_AUTH_DATA,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    CHECK_AUTH_REQUEST,
} from 'store/types/authTypes';
import { IAuthData } from 'types/AuthInterface';

export const setAuthIsLoading = (isLoading: boolean) => ({
    type: SET_AUTH_IS_LOADING,
    payload: isLoading,
});

export const setIsAuth = (isAuth: boolean) => ({
    type: SET_IS_AUTH,
    payload: isAuth,
});

export const setAuthData = (data: Pick<IAuthData, 'name' | 'policies'>) => ({
    type: SET_AUTH_DATA,
    payload: data,
});


export const loginAction = (login: string, password: string) => ({
    type: LOGIN_REQUEST,
    payload: {
        login,
        password,
    },
});

export const logoutAction = () => ({
    type: LOGOUT_REQUEST,
});

export const checkAuthAction = () => ({
    type: CHECK_AUTH_REQUEST,
});
  
  