import {
    SET_COMPONENTS_ORDERS_LIST,
} from "store/types/componentOrderTypes"

import { IComponentOrderState, ComponentOrderAction } from "types/ComponentOrderInterface"

const initialState: IComponentOrderState = {
    componentsOrdersList: [],
};

export const componentOrderReducer = (state = initialState, action: ComponentOrderAction): IComponentOrderState => {
    switch (action.type) {
        case SET_COMPONENTS_ORDERS_LIST:
            return { ...state, componentsOrdersList: action.payload }
        default:
            return state
    }
}