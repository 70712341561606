import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import { ICreateSerialNumberDTO, ISeriesDTO } from "types/SeriesInterface";
import { IResponseMessage } from "types/ResponseInterface";

const path_name = '/series'
const serial_number_path = '/serial-number'

export default class SeriesService {

    static async create(data: ISeriesDTO): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`${path_name}`, data);
    }

    static async getAll(): Promise<AxiosResponse<ISeriesDTO[]>> {
        return $api.get<ISeriesDTO[]>(`${path_name}`);
    }

    static async getAvailableSeries(): Promise<AxiosResponse<ISeriesDTO[]>> {
        return $api.get<ISeriesDTO[]>(`${path_name}/available`);
    }

    static async delete(id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${path_name}/${id}`);
    }

    static async createSerialNumber(data: ICreateSerialNumberDTO): Promise<AxiosResponse<IResponseMessage>> {
        return $api.post<IResponseMessage>(`${serial_number_path}`, data);
    }

    static async deleteSerialNumber(id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${serial_number_path}/${id}`);
    }
}