import {
    SET_ORDERS_LIST,
    SET_AVAILABLE_ORDERS_SHIPMENT,
    SET_ORDER_STATUS,
    SET_SINGLE_ORDER,
    SET_SINGLE_ORDER_ITEM,
    SET_ORDER_STATUS_LIST,
    SET_MOVEMENT_STATUS_LIST,
    SET_SHIPMENT_STATUS_LIST,
    SET_ORDER_PRODUCTS,
    SPLIT_PRODUCT_ORDER_ITEM,
} from "store/types/orderTypes";

import { IProductDTO } from "./ProductInterface";

export interface IOrderStatus {
    status_id: number;
    status_code: string;
    status_name: string;
    only_view: boolean;
}

export interface IMovementStatusDTO {
    movement_status_id: number;
    movement_status_code: string;
    movement_status_name: string;
    only_view: boolean;
}

export interface IShipmentStatusDTO {
    shipment_status_id: number;
    shipment_status_code: string;
    shipment_status_name: string;
    // only_view: boolean;
}

interface IProductType {
    product_type_id: number;
    product_type: string;
}

interface IMovementStatus {
    movement_status_id: number;
    movement_status_name: string;
}

interface IMovementType {
    movement_type_id: number;
    movement_type_name: string;
}

interface IShipmentStatus {
    shipment_status_id: number;
    shipment_status_name: string;
}

export interface IShipments {
    shipment_id: number;
    shipping_address: string;
    shipmentStatus: IShipmentStatus;
}

export interface IUpdateOrderShipmentDTO {
    shipment_uid: string;
    external_link: string;
    notes: string;
}

export interface IStaffing {
    status: number;
    quantity: number;
}

export interface IUpdateOrderItem {
    shipping_address: string;
    quantity: number;
}

export interface ISingleOrderItem extends IUpdateOrderItem {
}

export interface ICreateOrderItem {
    quantity: number;
    shipping_address: string;
    product_type_id: number;
}

export interface IOrderItem {
    order_item_id: number;
    quantity: number;
    movement_id: number;
    productType: IProductType;
    movementStatus: IMovementStatus;
    movementType: IMovementType;
    staffing: IStaffing[];
    products: IProductDTO[];
    available: number;
    shipment: IShipments;
}

export interface IOrderUpdate {
    last_name: string;
    first_name: string;
    brigade_name: string;
    position: string;
    work_region: string;
    phone: string;
    notes: string;
}

export interface IOrder extends IOrderUpdate {
    order_id: number;
    status_id: OrderNumberType;
    order_date: string;
    order_process: string;
    person_id: number;
    // shipments: IShipments;
    orderItem: IOrderItem[];
}

export interface ISingleOrderData {
    last_name: string;
    first_name: string;
    brigade_name: string;
    work_region: string;
    phone: string;
    shipping_address: string;
    notes: string;
    orderItem: IProductType[];
}

export interface IOrderProducts {
    availableProducts: IProductDTO[];
    currentProducts: IProductDTO[];
}

export interface IDeniedOrderData {
    comment: string;
}

export interface ISplitOrderItemDTO {
    movement_id: number;
    newOrderProducts: number[][];
    orderProductIds: number[];
    order_item_id: number;
}

export type SingleOrderDataType = ISingleOrderData | IDeniedOrderData | {};

export interface IOrderState {
    orderProducts: IOrderProducts;
    movementStatusList: IMovementStatusDTO[];
    orderStatusList: IOrderStatus[];
    shipmentStatusList: IShipmentStatusDTO[];
    stateOrderData: IOrder;
    stateOrderItem: ISingleOrderItem;
    orderList: IOrder[];
    availableOrdersShipment: IOrder[];
}

interface ISetOrderProductsAction {
    type: typeof SET_ORDER_PRODUCTS;
    payload: IOrderProducts;
}
interface ISetOrderStatusListAction {
    type: typeof SET_ORDER_STATUS_LIST;
    payload: IOrderStatus[];
}
interface ISetMovementStatusListAction {
    type: typeof SET_MOVEMENT_STATUS_LIST;
    payload: IMovementStatusDTO[];
}
interface ISetShipmentStatusListAction {
    type: typeof SET_SHIPMENT_STATUS_LIST;
    payload: IShipmentStatusDTO[];
}
interface ISetOrderStatusAction {
    type: typeof SET_ORDER_STATUS;
    payload: number;
}
interface ISetSingleOrderAction {
    type: typeof SET_SINGLE_ORDER;
    payload: IOrder;
}
interface ISetSingleOrderItemAction {
    type: typeof SET_SINGLE_ORDER_ITEM;
    payload: ISingleOrderItem;
}
interface ISetOrderListAction {
    type: typeof SET_ORDERS_LIST;
    payload: IOrder[];
}
interface ISetAvailableOrdersShipmentAction {
    type: typeof SET_AVAILABLE_ORDERS_SHIPMENT;
    payload: IOrder[];
}

interface ISplitOrderItemAction {
    type: typeof SPLIT_PRODUCT_ORDER_ITEM;
    payload: ISplitOrderItemDTO;
}

export type OrderAction =
    | ISetOrderProductsAction
    | ISetOrderStatusAction
    | ISetOrderListAction
    | ISetAvailableOrdersShipmentAction
    | ISetSingleOrderAction
    | ISetSingleOrderItemAction
    | ISetOrderStatusListAction
    | ISetMovementStatusListAction
    | ISetShipmentStatusListAction
    | ISplitOrderItemAction;

export enum EMovementStatus {
    NEW = 1,
    IN_PROGRESS = 2,
    DONE = 3,
    DELETE = 4,
}

export enum EOrderStatus {
    NEW = 1,
    IN_PROGRESS = 2,
    COMPLETED = 3,
    DECLINED = 4,
}

export type OrderNumberType = (typeof EOrderStatus)[keyof typeof EOrderStatus];
