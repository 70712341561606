import { FC } from "react";

interface Props {}

export const NotFound: FC<Props> = () => {
    return (
        <div className="not-found">
            <h1>Not found 404</h1>
        </div>
    )
}