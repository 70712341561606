import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { ICallback } from "types/SagaInterface";
import { IAuthState } from "types/AuthInterface";
import { IActionItem, ITableHeader } from "types/TableInterface";
import { IProductState, IProductStatus } from "types/ProductInterface";
import {
    IStaffing,
    IOrder,
    IOrderItem,
    IOrderState,
    // IOrderStatus,
    OrderNumberType
} from "types/OrderInterface";
import { IPieChartItem } from "components/Table/TableBody/TableColumnType/TableChartPieType";

import { WRITE_ORDER } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";
import { components_colors } from "utils/helpers/systemColors";
import {
    getMovementStatusColor,
    getOrderStatusColor,
    getProductStatusColor
} from "utils/helpers/getStatusColor";

import { IconButton } from "@mui/material";
import { AddCircle } from "@mui/icons-material";

function getStaffing(orderItem: IOrderItem, productStatusList: IProductStatus[]) {
    let output: IPieChartItem[] = orderItem?.staffing
    ?.sort((a, b) => a.status - b.status)
    ?.map((item: IStaffing) => {
        return {
            label: productStatusList.find(el => el.status_id === item.status)?.status_title || '',
            value: item.quantity,
            color: components_colors[getProductStatusColor(item.status)],
        }
    }) || []

    if (orderItem?.products.length < orderItem?.quantity) {
        output.push({
            label: 'Недостатньо',
            value: orderItem?.quantity - orderItem?.products.length,
            color: components_colors.gray,
        })
    }
    return output
}

// interface DialogRef {
//     openModal: () => void;
// }

interface Props {}

export const CustomerOrder: FC<Props> = () => {
    const [ statusLoading, setStatusLoading ] = useState<boolean>(false)
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { orderList } = useTypedSelector<IOrderState>(state => state.order)
    const { orderStatusList } = useTypedSelector<IOrderState>(state => state.order)
    const { movementStatusList } = useTypedSelector<IOrderState>(state => state.order)
    const { productStatusList } = useTypedSelector<IProductState>(state => state.product)
    // const dialogRef = useRef<DialogRef | null>(null);

    const {
        getOrdersList,
        deleteOrder,
        deleteOrderItem,
        setMovementStatus,
        setOrderStatus,
        getOrderStatusList,
        getProductStatusList,
        getMovementStatusList,
        setWarningNotifications,
    } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        getOrderStatusList()
        getMovementStatusList()
        getProductStatusList()
        getOrdersList(() => setIsDataLoading(false))
    }, [])

    function toCreateOrder() {
        navigate(`/dashboard/order/customer/confirmed/create${location.search}`)
    }

    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ searchOrderId, setSearchOrderId ] = useState<number | null>(null);

    useEffect(() => {
        const orderIdParam = Number(searchParams.get("orderId")) || null;
        setSearchOrderId(orderIdParam);
    }, [searchParams]);

    function deleteOrderHandler(id: number) {
        deleteOrder(id, (success: boolean) => {
            if (success) getOrdersList()
        })
    }

    function deleteOrderItemHandler(id: number) {
        deleteOrderItem(id, (res: ICallback) => {
            if (res.success) getOrdersList()
        })
    }

    const handleOrderSelect = (order_id: number, value: (number | string)) => {
        setStatusLoading(true)
        setOrderStatus(
            order_id,
            Number(value) as OrderNumberType,
            (success: boolean) => {
                if (success) getOrdersList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        );
    }

    const handleMovementSelect = (movement_id: number, movement_status_id: (number | string)) => {
        setStatusLoading(true)
        setMovementStatus(
            movement_id,
            Number(movement_status_id) as OrderNumberType,
            (success: boolean) => {
                if (success) getOrdersList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        );
    }

    // const openDetailsModal = () => {
    //     if (dialogRef && dialogRef.current) dialogRef.current.openModal();
    // };

    function getProductTypeLabels(item: any) {
        const output: string[] = []
        item.forEach((el: any) => {
            if (el?.productType?.product_type) output.push(el.productType.product_type)
        })
        return output.join(', ')
    }

    function canDelete(status_id: number) {
        return status_id > 1 ? true : false
    }

    const getOrderItemTableHeaders = useCallback((orderItem: number) => {
        let output: ITableHeader[] = [
            { key: "order_item_id", label: "ID", type: 'text' },
            { key: "staffing", label: "Заповненість", type: 'chart-pie', styles: {paddingTop: '5px', paddingBottom: '5px'} },
            { key: "product_type", label: "Назва", type: 'text' },
            { key: "quantity", label: "Кількість", type: 'text' },
            { key: "available", label: "Доступно", type: 'text' },
            {
                key: "movement_status_id",
                label: "Статус",
                // type: 'select',
                type: 'chip',
                styles: {textAlign: 'center', buttonWidth: '100%', cellWidth: '10%', cellMinWidth: '180px', paddingTop: '5px', paddingBottom: '5px'}
            },
        ]
        if ([WRITE_ORDER].some(el => authData?.policies.includes(el))) {
            output.push({
                key: "actions",
                type: 'actions',
                styles: {textAlign: 'right'},
                headerActions: [{
                    iconName: 'AddCircle',
                    to: `/dashboard/order/customer/confirmed/create-order-item/${orderItem}`,
                }] as IActionItem[],
            })
        }
        return output;
    }, [authData?.policies])

    const computedTableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "order_id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "orderItem", label: "Тип зброї", type: 'tooltip', action: (text: string) => getShortText(text, 8), settings: { filter: ['search'], sort: ['ASC', 'DESC'] }},
            { key: "last_name", label: "Прізвище", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "first_name", label: "Ім'я", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "brigade_name", label: "Назва бригади", type: 'tooltip', action: getShortText, styles: {textAlign: 'center'}, settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "work_region", label: "Район роботи", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "phone", label: "Номер телефону", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            // {
            //     key: "status_id",
            //     label: "Статус",
            //     type: authData?.policies.includes(WRITE_ORDER) ? 'select' : 'chip',
            //     styles: { buttonWidth: '100%', textAlign: 'center' },
            //     settings: {
            //         filter: [{
            //             key: 'button-list',
            //             options: orderStatusList?.map((item: IOrderStatus) => ({
            //                         label: item?.status_name,
            //                         key: item?.status_id,
            //                         btnColor: getOrderStatusColor(item?.status_id)
            //                     }))
            //         }],
            //         sort: ['ASC', 'DESC']
            //     }
            // },
            { key: "order_date", label: "Дата замовлення", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "order_process", label: "Дата процесу", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
        ]
        if ([WRITE_ORDER].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [orderStatusList, authData?.policies])

    const orderStatusOptions = useMemo(() => {
        return orderStatusList.map(el => ({
            key: el?.status_id,
            label: el?.status_name,
            color: getOrderStatusColor(el?.status_id),
            // exclude: el?.only_view !== false,
            selfExclude: true,
        }))
    }, [orderStatusList])

    const excludedOrderStatus: Array<number> = useMemo(() => {
        // return orderStatusList.filter(el => el.only_view !== false).map(el => el.status_id)

        // temporary return
        return [];
    }, [orderStatusList])

    const movementStatusOptions = useMemo(() => {
        return movementStatusList.map(el => ({
            key: el?.movement_status_id,
            label: el?.movement_status_name,
            color: getOrderStatusColor(el?.movement_status_id),
            // exclude: el?.only_view !== false,
            selfExclude: true,
        }))
    }, [movementStatusList])

    const excludedMovementStatus: Array<number> = useMemo(() => {
        // return movementStatusList.filter(el => el.only_view !== false).map(el => el.status_id)

        // temporary return
        return [];
    }, [movementStatusList])

    const getMovementStatus = useCallback((status: number) => {
        return {
            label: movementStatusList.find(el => el.movement_status_id === status)?.movement_status_name || '',
            value: Number(status),
            color: getMovementStatusColor(Number(status))
        }
    }, [movementStatusList])

    const buildTableData = useMemo(() => {
        let data = orderList

        if (searchOrderId) {
            data = orderList?.filter(el => searchOrderId === el?.order_id)
        }

        return data?.map((item: IOrder, index: number) => {
            return {
                tableId: item?.order_id,
                table_row_key: `order_id_${item?.order_id}_${index}`,
                order_id: item?.order_id,
                orderItem: getProductTypeLabels(item?.orderItem),
                last_name: item?.last_name,
                first_name: item?.first_name,
                brigade_name: item?.brigade_name,
                work_region: item?.work_region,
                phone: item?.phone,
                order_date: item?.order_date,
                order_process: item?.order_process,
                rowSettings: {
                    loading: statusLoading,
                    toggleAccordion: searchOrderId === item?.order_id
                },
                // status_id: authData?.policies.includes(WRITE_ORDER) ? {
                //     options: orderStatusOptions,
                //     preventDefault: {
                //         status: excludedOrderStatus.includes(item?.status_id),
                //         onClick: () => setWarningNotifications('Цей статус змінити не можна')
                //     },
                //     color: getOrderStatusColor(item?.status_id),
                //     value: item?.status_id,
                //     action: (value: (number | string)) => handleOrderSelect(item?.order_id, value)
                // } : getOrderStatus(item?.status_id),
                actions: authData?.policies.includes(WRITE_ORDER) ? [
                    {
                        iconName: "Edit",
                        action: () => {
                            if (item?.order_id) {
                                navigate(`/dashboard/order/customer/confirmed/edit/${item.order_id}${location.search}`)
                            }
                        },
                        tooltip: 'Редагувати',
                    },
                    {
                        iconName: "Delete",
                        confirmAction: () => deleteOrderHandler(item?.order_id),
                        confirmationMsg: `Ви впевнені що хочете видалити ордер ID ${item?.order_id}?`,
                        disabled: canDelete(item?.status_id),
                        iconColor: canDelete(item?.status_id) && 'disabled',
                        tooltip: canDelete(item?.status_id) ? 'Лише нові ордери можна видаляти' : 'Видалити',
                    },
                ] : [],
                // *************************** ORDER ITEM TABLE DATA ***************************************
                // ********************************** start ************************************************
                accordionData: {
                    headers: getOrderItemTableHeaders(item?.order_id),
                    tableData: item?.orderItem?.map((el: IOrderItem, idx: number) => ({
                        tableId: el?.order_item_id,
                        table_row_key: `product_type_id_${el?.order_item_id}_${idx}`,
                        quantity: el?.quantity,
                        order_item_id: el?.order_item_id,
                        product_type: el?.productType?.product_type,
                        staffing: {
                            data: getStaffing(el, productStatusList),
                            slotProps: {legend: {hidden: true}},
                        },
                        available: el?.available,
                        rowSettings: {
                            loading: statusLoading,
                        },
                        movement_status_id: getMovementStatus(el?.movementStatus?.movement_status_id),
                        // movement_status_id: {
                        //     options: movementStatusOptions,
                        //     preventDefault: {
                        //         status: excludedMovementStatus.includes(el?.movementStatus?.movement_status_id),
                        //         onClick: () => setWarningNotifications('Цей статус змінити не можна')
                        //     },
                        //     color: getOrderStatusColor(el?.movementStatus?.movement_status_id),
                        //     value: el?.movementStatus?.movement_status_id,
                        //     action: (movement_status_id: (number | string)) => handleMovementSelect(
                        //         el?.movement_id,
                        //         movement_status_id
                        //     )
                        // },
                        actions: authData?.policies.includes(WRITE_ORDER) ? [
                            {
                                iconName: "LibraryAdd",
                                action: () => {
                                    if (
                                        el?.order_item_id &&
                                        el?.movement_id &&
                                        el?.productType?.product_type &&
                                        el?.productType?.product_type_id
                                    ) {
                                        const params = [
                                            el.order_item_id,
                                            el.movement_id,
                                            el.productType.product_type,
                                            el.productType.product_type_id,
                                            el.quantity
                                        ]
                                        navigate(`/dashboard/order/customer/confirmed/assign-product/${params.join('/')}${location.search}`)
                                    }
                                },
                                tooltip: "Прив'язати продукти",
                            },
                            {
                                iconName: "Edit",
                                action: () => {
                                    if (item?.order_id && el?.order_item_id) {
                                        navigate(`/dashboard/order/customer/confirmed/edit/${item.order_id}/${el.order_item_id}${location.search}`)
                                    }
                                },
                                tooltip: 'Редагувати',
                            },
                            {
                                iconName: "Delete",
                                confirmAction: () => deleteOrderItemHandler(el?.order_item_id),
                                confirmationMsg: `Ви впевнені що хочете видалити ордер ID ${el?.order_item_id}?`,
                                disabled: canDelete(el?.movementStatus?.movement_status_id),
                                iconColor: canDelete(el?.movementStatus?.movement_status_id) && 'disabled',
                                tooltip: canDelete(el?.movementStatus?.movement_status_id) ? 'Лише нові замовлення можна видаляти' : 'Видалити',
                            },
                        ] : [],
                    }))
                }
                // *********************************** end *************************************************
                // *************************** ORDER ITEM TABLE DATA ***************************************
            }
        })
    }, [
        // Table data dependencies
        orderList,
        searchOrderId,
        statusLoading,
        orderStatusOptions,
        movementStatusOptions,
        productStatusList,
        excludedOrderStatus,
        excludedMovementStatus,
        authData?.policies,
    ])

    return (
        <div className="customer">
            {authData?.policies.includes(WRITE_ORDER) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateOrder}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }

            <DataLoading isDataLoading={isDataLoading}>

                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={computedTableHeaders}
                        tableData={buildTableData}
                        tableId="orders-table"
                        amount_per_page={12}
                        searchEntityId={searchOrderId}
                    />
                }

            </DataLoading>

            <Outlet/>
            {/* <RefDialog ref={dialogRef}>
                <OrderDetails/>
            </RefDialog> */}
        </div>
    )
}