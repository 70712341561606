import {
    SET_UNCONFIRMED_ORDERS_LIST,
    GET_UNCONFIRMED_ORDERS_LIST,
    UPDATE_UNCONFIRMED_ORDER_STATUS,
} from "store/types/unconfirmedOrderTypes";

import { IUnconfirmedOrder } from "types/UnconfirmedOrderInterface";

import { ICallback } from "types/SagaInterface";


export const setUnconfirmedOrdersList = (list: IUnconfirmedOrder[]) => ({
    type: SET_UNCONFIRMED_ORDERS_LIST,
    payload: list,
});

export const getUnconfirmedOrdersList = (callback: (res: ICallback) => void) => ({
    type: GET_UNCONFIRMED_ORDERS_LIST,
    payload: {
        callback,
    }
});

export const updateUnconfirmedOrderStatus = (
    id: number,
    status: number,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_UNCONFIRMED_ORDER_STATUS,
    payload: {
        id,
        status,
        callback,
    }
})