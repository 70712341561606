import { FC } from "react";

import { Draggable } from "react-beautiful-dnd";

import { Card, Chip } from "@mui/material";
import { IChip } from "types/MaterialInterface";

interface Props {
    id: string | number;
    index: number;
    content: string;
    chip: IChip;
    isDisabled?: boolean;
}

export const DraggableCardChip: FC<Props> = ({
    id,
    index,
    content,
    chip,
    isDisabled,
}) => {
    return (
        <Draggable
            draggableId={`${id}`}
            index={index}
            isDragDisabled={isDisabled}
        >
            {(provided, snapshot) => (
                <Card
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                        userSelect: "none",
                        padding: 8,
                        margin: "0 0 8px 0",
                        minHeight: "30px",
                        backgroundColor: snapshot.isDragging
                            ? "lightgreen"
                            : isDisabled
                            ? "lightgrey"
                            : "white",
                        color: "black",
                        ...provided.draggableProps.style,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            justifyItems: "center",
                        }}
                    >
                        <div style={{ margin: "auto 0" }}>{id}</div>

                        <div style={{ margin: "auto 0" }}>{content}</div>

                        <Chip
                            label={chip.label}
                            color={chip.color || "default"}
                        />
                    </div>
                </Card>
            )}
        </Draggable>
    );
};
