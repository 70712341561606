import { FC, Fragment, MouseEvent, useMemo, useRef, useState } from 'react';

import {
    // ThemeProvider,
    MenuList,
    MenuItem,
    Popper,
    Paper,
    Grow,
    ClickAwayListener,
    Button,
    ButtonGroup,
    CircularProgress,
    Box,
} from '@mui/material';

import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';

import { MaterialColorType } from 'utils/material/buttonColorTheme';

// import { buttonColorTheme } from 'utils/material/buttonColorTheme';

export interface IButtonSelectOption {
    key: number | string;
    label: string;
    color?: MaterialColorType;
    exclude?: boolean;
    selfExclude?: boolean;
}

interface Props {
    zIndex: number;
    options: {[key: (number | string)]: string} | IButtonSelectOption[];
    value: number;
    width: string;
    color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    preventDefault?: {
        status: boolean;
        onClick: () => void;
    };
    disabled?: boolean;
    loading?: boolean;
    handleSelect: (index: (number | string)) => void
}

export const ButtonSelect: FC<Props> = ({
    zIndex = 1,
    options = {},
    value = 0,
    width,
    color = 'primary',
    preventDefault,
    disabled,
    loading,
    handleSelect,
}) => {
    
    const [ open, setOpen ] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const localOptions: IButtonSelectOption[] = useMemo(() => {
        let output: IButtonSelectOption[] = []

        if (Array.isArray(options)) {
            output = options;
        }
        else if (typeof options === 'object') {
            for (const key in options) {
                if (Object.hasOwnProperty.call(options, key)) {
                    let tempKey: string | number = key
                    if (typeof value === 'number') tempKey = Number(key)
                    output.push({
                        key: tempKey,
                        label: options[key]
                    })
                }
            }
        }
        return output;
    }, [options])

    const title = useMemo(() => {
        const output = localOptions?.find(el => el.key === value) || null
        if (output) return output.label
        return ''
    }, [localOptions, value])

    // const handleClick = () => {
    //     console.info(`You clicked ${options[selectedIndex]}`);
    // };

    const handleMenuItemClick = (event: MouseEvent<HTMLLIElement | HTMLButtonElement>, index: (number | string)) => {
        handleSelect(index);
        setOpen(false);
    };

    const handleToggle = () => {
        if (preventDefault?.status === true) {
            if (preventDefault?.onClick instanceof Function) preventDefault.onClick() 
        } else {
            setOpen((prevOpen) => !prevOpen);
        }
    };

    const handleClose = (event: Event) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
        return;
        }

        setOpen(false);
    };

    return (
        // <ThemeProvider theme={buttonColorTheme}>
        title ?
        <Fragment>
            <ButtonGroup sx={{width: width}} variant="contained" color={color} ref={anchorRef} aria-label="split button">
                {/* <Button onClick={handleClick}>{options[selectedIndex]}</Button> */}
                <Button
                    sx={{width: width, justifyContent: 'space-between', position: "relative", textWrap: 'nowrap'}}
                    size="small"
                    aria-controls={open ? 'split-button-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    disabled={disabled || loading}
                    onClick={handleToggle}
                >
                    {title}

                    <CircularProgress
                        size={20}
                        sx={{position: "absolute", right: 10, visibility: loading ? 'visible' : 'hidden'}}
                    />

                    <ArrowDropDownIcon sx={{visibility: loading ? 'hidden' : 'visible'}}/>
                </Button>
            </ButtonGroup>
            <Popper
                sx={{zIndex}}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                    transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                                {localOptions.map(item => (
                                    !(item?.exclude || (item?.selfExclude && value === item.key)) && (
                                        item?.color ?
                                            <Box
                                                key={item.key}
                                                sx={{px: 2, py: 1}}
                                            >
                                                <Button
                                                    size='small'
                                                    variant="contained"
                                                    sx={{width: '100%'}}
                                                    color={item.color}
                                                    onClick={(event) => handleMenuItemClick(event, item.key)}
                                                >
                                                    {item.label}
                                                </Button>
                                            </Box>
                                            :
                                            <MenuItem
                                                key={item.key}
                                                // disabled={option?.disabled}
                                                selected={item.key === value}
                                                onClick={(event) => handleMenuItemClick(event, item.key)}
                                            >
                                                    {item.label}
                                            </MenuItem>
                                    )
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </Fragment>
        :
        null
        // </ThemeProvider>
    );
}