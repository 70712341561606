import { FC, useMemo, useState } from 'react';
import {
    Box,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    SelectChangeEvent,
    FormHelperText
} from '@mui/material';

export interface IBasicSelectOption {
    label: string | number;
    key: string | number;
}

interface Props {
    sx?: {[key: string]: string | number}
    size?: "small" | "medium"
    label: string;
    value: string | number;
    options: string[] | IBasicSelectOption[];
    name?: string;
    error?: boolean;
    required?: boolean;
    helperText?: string;
    onChange: (selected: string) => void;
}

export const BasicSelect: FC<Props> = ({
    sx = {},
    size = "medium",
    label,
    value,
    options,
    name = 'basic-select',
    error = false,
    required = false,
    helperText = '',
    onChange
}) => {
    const [ localValue, setLocalValue ] = useState(value);

    const handleChange = (event: SelectChangeEvent) => {
        const selected = event.target.value as string
        setLocalValue(selected);
        onChange(selected)
    };

    function getElement(el: string | IBasicSelectOption, property: keyof IBasicSelectOption): string | number {
        if (typeof el === 'object' && el[property]) return el[property]
        else if (['string', 'number'].includes(typeof el)) return el as string | number
        return ''
    }

    const computedValue: string = useMemo(() => {
        let output = ''
        options.forEach((el: string | IBasicSelectOption) => {
            if (typeof el === 'object' && el.key === localValue) output = String(el.key)
            if (typeof el === 'number' && el === localValue) output = String(el)
            if (typeof el === 'string' && el === localValue) output = el
        })
        return output
    }, [localValue, options])

    return (
        <Box sx={{ minWidth: 120, ...sx }}>
            <FormControl fullWidth>
                <InputLabel id="basic-select-label" error={error}>
                    {label + (required ? ' *' : '')}
                </InputLabel>
                <Select
                    name={name}
                    size={size}
                    labelId="basic-select-label"
                    id="basic-select-label"
                    value={computedValue}
                    label={label + (required ? ' *' : '')}
                    error={error}
                    onChange={handleChange}
                >
                    {options.map((el: string | IBasicSelectOption, idx: number) => (
                        <MenuItem key={getElement(el, 'key')} value={getElement(el, 'key')}>
                            {getElement(el, 'label')}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText error={error}>
                    {error && helperText ? "Обов'язкове поле" : ''}
                </FormHelperText>
            </FormControl>
        </Box>
    );
}
