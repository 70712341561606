import { ChangeEvent, FC, useEffect, useState } from "react";

import { Grid, TextField } from "@mui/material";

interface Props {
    value: string;
    onChange: (value: string) => void;
}

export const Step4: FC<Props> = ({ value, onChange }) => {
    const [ note, setNote ] = useState<string>('')

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        if (event?.target?.value !== undefined) {
            setNote(event.target.value)
            onChange(event.target.value)
        }
    }

    useEffect(() => {
        setNote(value)
    }, [value])

    return (
        <Grid
            container
            spacing={2}
            pb={1} pr={1}
            sx={{ maxHeight: '100%', overflowY: 'auto' }}
            className='scroll-bar-primary-y'
        >
            <Grid item xs={12}>
                <TextField
                    id="outlined-basic"
                    label="Примітка"
                    variant="outlined"
                    sx={{width: '100%'}}
                    multiline
                    rows={7}
                    value={note}
                    onChange={handleInputChange}
                />
            </Grid>
        </Grid>
    )
}