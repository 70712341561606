import { SET_RECIPES_LIST, SET_SINGLE_RECIPE } from "store/types/recipeTypes"
import { IRecipeState, RecipeAction, IRecipeDTO } from "types/RecipeInterface"

const initialState: IRecipeState = {
    stateRecipeData: {} as IRecipeDTO,
    recipesList: []
}

export const recipeReducer = (state = initialState, {type, payload}: RecipeAction): IRecipeState => {
    switch (type) {
        case SET_SINGLE_RECIPE:
            return { ...state, stateRecipeData: payload }
        case SET_RECIPES_LIST:
            return { ...state, recipesList: payload }
        default:
            return state
    }
}