import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { BasicSelect, IBasicSelectOption } from 'components/Select/BasicSelect';
import { ResponseStatusLoader } from 'components/Loader/ResponseStatusLoader';

import { ICallback, ResponseStatusType } from 'types/SagaInterface';

import { getTypedValue } from 'utils/helpers/getTypedValue';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    TextField,
    Grid,
} from '@mui/material';

const phoneMask = /^\+[0-9]{12}$/;

const inputTypes = ['text', 'number', 'password', 'tel', 'email', 'search', 'url']

export interface INumberRange {
    [key: string]: {
        min?: number,
        max?: number
    }
}

interface Props<T> {
    title: string;
    dialogSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    dialogContentMinHeight?: string;
    order?: {[key: string]: number};
    grid?: {[key: string]: number};
    dataTypes?: {[key: string]: string};
    fieldsTypes?: {[key: string]: string};
    inputRows?: {[key: string]: number};
    numberRange?: INumberRange;
    initialData: T;
    requiredFields?: Array<string>;
    disabledFields?: {[key: string]: boolean};
    submitProps?: Array<string>;
    labels: {[key: string]: string};
    backRoute: string | Function;
    helpText?: {[key: string]: string};
    emptyDataInfo?: {entity: string, to: string},
    isDataExists?: boolean;
    basicSelectOptions?: IBasicSelectOption[];
    getEntityActionOnMount?: Function;
    submitEnityAction: Function;
    getEntityActionOnSubmit?: Function;
    onChange?: Function;
}

export const SubmitEntityModal: FC<Props<any>> = ({
    title = '',
    dialogSize = 'md',
    dialogContentMinHeight = '20vh',
    order = {},
    grid = {},
    dataTypes = {},
    fieldsTypes = {},
    inputRows = {},
    numberRange = {},
    initialData = {},
    requiredFields = [],
    disabledFields = {},
    submitProps = [],
    labels = {},
    backRoute = '',
    helpText = {},
    emptyDataInfo,
    isDataExists = false,
    basicSelectOptions,
    getEntityActionOnMount,
    submitEnityAction,
    getEntityActionOnSubmit,
    onChange,
}) => {

    const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState<boolean>(true)
    const [ responseStatus, setResponseStatus ] = useState<ResponseStatusType>('loading')

    const [ entityData, setEntityData] = useState<{[key: string]: number | string}>(initialData)
    const [ entityInstance, setEntityInstance] = useState<{[key: string]: number | string}>(
        JSON.parse(JSON.stringify(initialData))
    )

    const navigate = useNavigate()
    const closeModal = (res?: any) => {
        if (backRoute instanceof Function) navigate(backRoute(res))
        else if (backRoute) navigate(backRoute)
    };

    useEffect(() => {
        if (getEntityActionOnMount instanceof Function) {
            getEntityActionOnMount.call(null, (res: ICallback) => {
                setResponseStatus(res.status)
            })
        } else {
            setResponseStatus('success')
        }
    }, [])

    useEffect(() => {
        setEntityData(initialData)
        setEntityInstance(JSON.parse(JSON.stringify(initialData)))
    }, [initialData])

    function selectOptionHandler(value: string, key: string) {
        setEntityData({ ...entityData, [key]: Number(value) })
    }

    function getHelpText(key: string): string {
        if (requiredFields.includes(key) && checkField(key)) {
            if (helpText?.[key]) return helpText?.[key]
            if (fieldsTypes?.[key] === 'tel') return "Поле обов'язкове у форматі +380971122333"
            return "Поле обовязкове"
        }
        return ""
    }

    function setFieldValue(event: ChangeEvent<HTMLInputElement>, key: string) {
        try {
            if ( (fieldsTypes?.[key] === 'tel' && !/^\+\d*$/.test(event.target.value)) ) {
                return
            }
            const value = getTypedValue(
                event.target.value,
                dataTypes?.[key] || 'string',
                numberRange?.[key]?.min,
                numberRange?.[key]?.max
            )

            if (value !== undefined) {
                let data: {[key: string]: number | string} = {...entityData, [key]: value}

                if (onChange instanceof Function) data = onChange(key, value, data);
                setEntityData({...data});
            }
        } catch {}
    }

    function numberCheck(key: string): boolean {
        const min = numberRange?.[key]?.min
        const max = numberRange?.[key]?.max
        if (entityData?.[key] === '') return true
        else if (min !== undefined && Number(entityData?.[key]) < min) return true
        else if (max !== undefined && Number(entityData?.[key]) > max) return true
        else return false
    }

    function checkField(key: string): boolean {
        if (fieldsTypes?.[key] === 'tel') return !phoneMask.test(String(entityData?.[key]))
        else if (dataTypes?.[key] === 'number') {
            return numberCheck(key)
        }
        else return !entityData?.[key]
    }

    function isFieldsValidated(): boolean {
        return requiredFields.some( key => {
            if (fieldsTypes?.[key] === 'tel') return !phoneMask.test(String(entityData?.[key]))
            else if (dataTypes?.[key] === 'number') {
                return numberCheck(key)
            }
            else return !(entityData[key])
        })
    }

    function getChangedData() {
        let output: any = {}
        const keys = new Set([...Object.keys(entityInstance), ...Object.keys(entityData)])
        
        keys.forEach(key => {
            if (entityInstance[key] !== entityData[key]) output[key] = entityData[key]
        })
        return output
    }

    useEffect(() => {
        const changedData = getChangedData()
        const disabled: boolean = !!(Object.keys(changedData).length) && !isFieldsValidated()

        setSubmitBtnDisabled(!disabled)
    }, [entityData]);

    function createEntityHandler() {
        setSubmitBtnDisabled(true)

        let requestData: any = {}
        if (submitProps?.length) {
            submitProps.forEach(prop => {
                if (entityData[prop] !== undefined) requestData[prop] = entityData[prop]
            })
        } else requestData = entityData

        submitEnityAction.call(
            null,
            requestData,
            (res: ICallback) => {
                if(res.success) {
                    if (getEntityActionOnSubmit instanceof Function) getEntityActionOnSubmit()
                    closeModal(res)
                }
                setSubmitBtnDisabled(false)
        })
    }

    const getAttributes = useCallback((key: string) => {
        return {
            label: labels?.[key],
            value: entityData?.[key] === undefined ? '' : entityData?.[key],
            required: requiredFields.includes(key),
            error: requiredFields.includes(key) && checkField(key),
            helperText: getHelpText(key),
        }
    }, [entityData])

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            maxWidth={dialogSize}
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>

            <DialogContent dividers sx={{minHeight: dialogContentMinHeight}}>

                <ResponseStatusLoader
                    responseStatus={responseStatus}
                    entity={emptyDataInfo?.entity || ''}
                    isDataExists={isDataExists}
                    to={emptyDataInfo?.to || ''}
                >

                    <Grid container spacing={2}>

                        {Object.keys(entityData).map((key) => (
                            <Grid key={key} item xs={12} sm={grid?.[key]} order={order?.[key]}>

                                {fieldsTypes?.[key] === 'select' &&
                                    basicSelectOptions &&
                                        <BasicSelect
                                            options={basicSelectOptions}
                                            onChange={(value) => selectOptionHandler(value, key)}
                                            {...getAttributes(key)}
                                        />
                                }

                                {inputTypes.includes(fieldsTypes?.[key]) &&
                                    <TextField
                                        color="primary"
                                        variant="outlined"
                                        sx={{width: '100%'}}
                                        disabled={disabledFields?.[key]}
                                        type={fieldsTypes?.[key] || 'text'}
                                        multiline={Boolean(inputRows?.[key])}
                                        rows={inputRows?.[key] ? inputRows[key] : 1}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                            setFieldValue(e, key)
                                        }}
                                        InputProps={key === 'phone' ? {
                                            inputProps: { mask: phoneMask },
                                        } : {}}
                                        {...getAttributes(key)}
                                    />
                                }

                            </Grid>
                        ))}
                    </Grid>

                </ResponseStatusLoader>

            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={closeModal}>
                    {labels?.close || 'Закрити'}
                </Button>
                <Button variant="contained" disabled={submitBtnDisabled} onClick={createEntityHandler}>
                    {labels?.submit || 'Створити'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}