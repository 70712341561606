import { useTypedSelector } from "hooks/useTypedSelector";
import { FC, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Notification } from "components/Modal/Notification";
import { IAuthState } from "types/AuthInterface";

interface Props {}

export const AppLayout: FC<Props> = () => {
    const { isAuth } = useTypedSelector<IAuthState>(state => state.auth)

    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        // If you need to add home page with path="/" - move this check to DashboardLayout
        if (!isAuth) {
            // return <Navigate to="/login" replace />;
            navigate('/login', { replace: true, state: {message: "Не авторизовано", from: location.pathname} })
        } else if (['/', '/login'].includes(location.state?.from)) {
            navigate('/dashboard', { replace: true })
        } else if (location?.state?.from) {
            navigate(location.state.from, { replace: true })
        }
    }, [isAuth])

    return (
        <div className="app-layout">
            <Outlet/>
            <Notification/>
        </div>
    )
}