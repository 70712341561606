import {
    // compose,
    applyMiddleware,
    legacy_createStore as createStore
} from "redux"

import createSagaMiddleware from 'redux-saga'

import {
    // RootState,
    rootReducer
} from "store/reducers";

import { rootSaga } from "store/sagas";

const sagaMiddleware = createSagaMiddleware()

// const composeEnhancers =
//     typeof window === 'object' &&
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) :
//         compose;

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware))
// const configureStore = (preloadedState: RootState) => createStore(
//     rootReducer,
//     preloadedState,
//     // composeEnhancers(applyMiddleware(sagaMiddleware)),
//     applyMiddleware(sagaMiddleware),
// )

// const store = configureStore({})

sagaMiddleware.run(rootSaga)

export { store }