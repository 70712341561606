export function getTypedValue(
    value: string | number,
    dataType: string,
    min?: number,
    max?: number,
): number | string | undefined {
    
    if (value !== '' && dataType === 'number') {
        let numberValue = Number(value)

        // for case when user typed letter with number
        if (Number.isNaN(numberValue)) throw new Error('Number is not valid')

        // for case when min or max is set
        if (min !== undefined && numberValue < min) numberValue = min
        else if (max !== undefined && numberValue > max) numberValue = max

        return numberValue as number
    } else {
        return value
    }
}