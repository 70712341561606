import { FC } from "react";

interface Props {}

export const PermissionDenied: FC<Props> = () => {
    return (
        <div className="permission-denied">
            <h1>You don't have permission</h1>
        </div>
    )
}