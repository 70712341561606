import { AxiosResponse } from 'axios';
import UnconfirmedOrderService from 'services/UnconfirmedOrderService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    GET_UNCONFIRMED_ORDERS_LIST,
    UPDATE_UNCONFIRMED_ORDER_STATUS,
} from "store/types/unconfirmedOrderTypes";

import { IError } from 'types/ErrorInterface';
import { IWorkerCallback, IWorkerUpdateStatus } from 'types/SagaInterface';

import { setUnconfirmedOrdersList } from 'store/actions/unconfirmedOrderAction';

import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';


function* getUnconfirmedOrdersListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(UnconfirmedOrderService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setUnconfirmedOrdersList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список замовлень не вдалося"], error as IError ))
    }
}

function* updateUnconfirmedOrderStatusWorker({payload}: IWorkerUpdateStatus<number>) {
    const { id, status, callback } = payload
    try {
        const response: AxiosResponse = yield call(UnconfirmedOrderService.updateStatus, id, status)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true, data: response?.data})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Статус замовлення оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Статус замовлення оновити не вдалося"], error as IError ))
    }
}

export function* unconfirmedOrderWatcher() {
    yield takeLatest(GET_UNCONFIRMED_ORDERS_LIST, getUnconfirmedOrdersListWorker);
    yield takeLatest(UPDATE_UNCONFIRMED_ORDER_STATUS, updateUnconfirmedOrderStatusWorker);
}