import { FC } from "react";
import { Navigate } from "react-router-dom";

import { PageTabs } from "components/Tabs/PageTabs";

import { useTypedSelector } from "hooks/useTypedSelector";
import { IAuthState } from "types/AuthInterface";

import { READ_ORDER } from "store/types/policiesTypes";

const tabs = [
    {
        key: 'customer/confirmed',
        baseKey: 'customer',
        label: 'Клієнтські',
        children: [
            {key: 'confirmed', label: 'Підтверджені'},
            {key: 'unconfirmed', label: 'Не підтверджені'},
        ]
    },
    {key: 'components-orders', label: 'Операційні'},
]

interface Props {}

export const Order: FC<Props> = () => {
    const { authData, isAuth } = useTypedSelector<IAuthState>(state => state.auth)

    if (authData?.policies.includes(READ_ORDER) && isAuth) {
        return (
            <div className="order">
                <PageTabs tabs={tabs} basePath="/dashboard/order"/>
            </div>
        )
    }

    return <Navigate to="/permission-denied"/>
}