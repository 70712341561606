import { FC, useEffect, useMemo, useState } from "react";

import { RadioButtonPaper } from "components/Select/RadioButtonPaper";
import { EmptyDataInfo } from "components/Common/EmptyDataInfo";
import { DataLoading } from "components/Loader/DataLoading";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { IRecipeState } from "types/RecipeInterface";

import { Grid, Paper, Typography } from "@mui/material";

interface Props {
    value: number;
    onChange: (value: number) => void;
}

export const Step1: FC<Props> = ({ value, onChange }) => {
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)
    const [ loadingFailed, setLoadingFailed ] = useState<boolean>(false);
    const [ selectedRecipe, setSelectedRecipe ] = useState<number>(0)
    const { recipesList } = useTypedSelector<IRecipeState>(state => state.recipe)

    const { getRecipesList } = useActions()

    useEffect(() => {
        getRecipesList((success: boolean) => {
            if (!success) setLoadingFailed(true)
            setIsDataLoading(false)
        })
    }, [])

    useEffect(() => {
        setSelectedRecipe(value)
    }, [value])

    const computedRecipesList = useMemo(() => {
        return recipesList.map(item => ({
            key: item.product_type_id,
            label: item.name
        }))
    }, [recipesList])

    const computedPartsList = useMemo(() => {
        const recipe = recipesList.find(item => item.product_type_id === selectedRecipe) || null
        if (recipe) {
            return recipe.parts.map(item => ({
                label: item.name,
                quantity: item.quantity
            }))
        }
        return []
    }, [recipesList, selectedRecipe])

    function handleSelectRecipe(value: string | number) {
        const product_type = Number(value)
        setSelectedRecipe(product_type)
        onChange(product_type)
    }

    return (
        <Grid item xs={12} sx={{height: '100%'}}>

            <DataLoading isDataLoading={isDataLoading}>

                <Grid container spacing={6} sx={{height: '100%'}}>
                    <Grid item xs={6} sx={{height: '100%'}}>
                        
                        <EmptyDataInfo
                            entity="рецепта"
                            dataLength={computedRecipesList.length}
                            loadingFailed={loadingFailed}
                            to="/dashboard/warehouse/recipes/create"
                        >
                            <RadioButtonPaper
                                title="Виберіть рецепт:"
                                options={computedRecipesList}
                                grid={{xs: 12}}
                                paper={{elevation: 3, style: {padding: '3px 10px'}}}
                                value={selectedRecipe}
                                onChange={handleSelectRecipe}
                            />
                        </EmptyDataInfo>

                    </Grid>
                    <Grid item xs={6} sx={{height: '100%'}}>
                        {computedPartsList.length > 0 &&
                            <>
                                <Typography variant="subtitle1" color="primary" sx={{mb: 2}}>
                                    Необхідні компоненти:
                                </Typography>
                                <Grid
                                    container
                                    spacing={3}
                                    pb={1} pr={1}
                                    sx={{ maxHeight: '100%', overflowY: 'auto' }}
                                    className='scroll-bar-primary-y'
                                >
                                    {computedPartsList.map((item, idx: number) =>
                                        <Grid key={idx} item xs={12} sx={{mb: 1}}>
                                            <Paper elevation={3} sx={{display: 'inline', p: 1, mr: 2}}>
                                                {item.label}
                                            </Paper>
                                            -
                                            <Typography variant="body1" sx={{display: 'inline', ml: 2}}>
                                                {item.quantity}&nbsp;од.
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>

            </DataLoading>

        </Grid>
    )
}