import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { TableComponent } from 'components/Table/Table';

import { IOrder, SingleOrderDataType } from 'types/OrderInterface';
import { EProductQuantity } from 'types/ProductInterface';
import { IProductTypeState } from 'types/ProductTypeInterface';
import { ITableHeader, IActionItem } from "types/TableInterface";
import { ICallback } from 'types/SagaInterface';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    TextField,
    Grid,
    FormHelperText,
} from '@mui/material';

const tableHeaders: ITableHeader[] = [
    { key: "product_type_id", label: "ID", type: 'text', styles: {cellWidth: '10%', cellMinWidth: '100px'} },
    { key: "product_type", label: "Назва", type: 'text', styles: {cellWidth: '50%', cellMinWidth: '100px'} },
    { key: "quantity", label: "Кількість", type: 'input', styles: {cellWidth: '10%', cellMinWidth: '150px'} },
    {
        key: "actions",
        type: 'actions',
        headerActions: [] as IActionItem[],
        styles: {textAlign: 'right', cellWidth: '10%', cellMinWidth: '100px'}
    },  
]

const orderDataLabels: {[key: string]: string} = {
    last_name: "Прізвище",
    first_name: "Ім'я",
    // orderItem: "Тип зброї",
    brigade_name: "Назва бригади",
    work_region: "Район роботи",
    position: "Посада",
    phone: "Номер телефону",
    shipping_address: "Адреса доставки",
    notes: "Коментар"
}

const initialOrderData: {[key: string]: string} = {
    last_name: "",
    first_name: "",
    // orderItem: [],
    brigade_name: "",
    position: "",
    work_region: "",
    phone: "+380",
    shipping_address: "",
    notes: ""
}

const inputRows: {[key: string]: number} = {
    notes: 4
}

const grid: {[key: string]: number} = {
    last_name: 6,
    first_name: 6,
    // orderItem: 6,
    brigade_name: 6,
    position: 6,
    work_region: 6,
    phone: 6,
    shipping_address: 12,
    notes: 12
}

const requiredFields = ['last_name', 'first_name', 'position', 'phone', 'orderItem', 'notes', 'shipping_address']

const phoneMask = /^\+[0-9]{12}$/;

interface Props {}

export const CreateOrder: FC<Props> = () => {
    const [ createBtnDisabled, setCreateBtnDisabled ] = useState<boolean>(true)
    const [ productsQuantities, setProductsQuantities ] = useState<{[key: string]: number}[]>([])
    const [ orderData, setOrderData ] = useState<Partial<IOrder>>(initialOrderData)
    const { productTypeList } = useTypedSelector<IProductTypeState>(state => state.product_type)

    const { createOrder, getOrdersList, getProductTypeList } = useActions()

    const navigate = useNavigate()
    const location = useLocation();

    const closeModal = (res: any) => {
        let query = ''
        if (res?.data?.id && typeof res.data.id === 'number') query = `?orderId=${res.data.id}`
        else if (location.search) query = location.search
        navigate(`/dashboard/order/customer/confirmed${query}`)
    };

    useEffect(() => {
        getProductTypeList()
    }, [])

    function setFieldValue(event: ChangeEvent<HTMLInputElement>, key: string) {
        if (
            ( key === 'phone' && !/^\+\d*$/.test(event.target.value) ) ||
            ( ['first_name', 'last_name'].includes(key) && /[\d]/.test(event.target.value) )
        ) {
            return
        }
        setOrderData({...orderData, [key]: event.target.value});
    }

    function createOrderHandler() {
        setCreateBtnDisabled(true)

        let requestData: Partial<IOrder> = {...orderData}
        const orderItem = productsQuantities.map(el => ({
            product_type_id: el.product_type_id,
            quantity: el.quantity
        }))
        requestData = Object.assign({}, requestData, { orderItem: orderItem });

        createOrder(
            requestData,
            (res: ICallback) => {
                if(res.success) {
                    getOrdersList()
                    closeModal(res)
                }
                setCreateBtnDisabled(false)
        })
    }

    // // This function set quantity of product to the productsQuantities object when admin set quantity into the input
    function onChangeProductInput(quantity: (number | string), index: number) {
        setProductsQuantities(prevValue => {
            prevValue[index].quantity = Number(quantity)
            return [...prevValue]
        })
    }

    // Delete product orderItem
    function deleteProduct(idx: number) {
        setProductsQuantities(prevValue => {
            prevValue.splice(idx, 1)
            return [...prevValue]
        })
    }

    function addProductQuantity(product_type_id?: number | string, quantity?: number | string): void {
        if (product_type_id !== undefined && quantity !== undefined) {
            setProductsQuantities([
                ...productsQuantities, 
                {
                    customID: Date.now(),
                    product_type_id: Number(product_type_id),
                    quantity: Number(quantity)
                }
            ]);
        }
    }

    function getProductName(product_type_id: number): string | null {
        return productTypeList.find(el => el.product_type_id === product_type_id)?.product_type || null
    }

    const buildTableData = useMemo(() => {
        return productsQuantities.map((el, idx) => ({
                    tableId: idx,
                    table_row_key: `product_type_id_${el.product_type_id}_${idx}`,
                    product_type_id: el.product_type_id,
                    product_type: getProductName(el.product_type_id),
                    quantity: {
                        value: el.quantity ?? EProductQuantity.MIN_QUANTITY,
                        type: 'number',
                        min: 1,
                    },
                    actions: [
                        {
                            iconName: "Clear",
                            confirmAction: () => deleteProduct(idx),
                            tooltip: 'Видалити',
                        },
                    ]
                }))
    }, [productsQuantities])

    const multiSelectOptions = useMemo(() => {
        return productTypeList.map(el => ({
            key: el.product_type_id,
            label: el.product_type,
            input: {
                label: 'Кількість',
                type: 'number',
                min: EProductQuantity.MIN_QUANTITY,
                defaultValue: EProductQuantity.DEFAULT
            }
        }))
    }, [productTypeList])

    const computedTableHeaders = tableHeaders.map(el => {
        if (el.key === 'actions') {
            el.headerActions = [{
                iconName: 'AddCircleDropdown',
                options: multiSelectOptions,
                action: addProductQuantity
            }]
        }
        return el
    })

    function getHelpText(key: string): string {
        if (key === 'phone') return "Поле обовязкове у форматі +380971122333"
        return "Поле обовязкове"
    }

    function checkDisabled(): void {
        setCreateBtnDisabled(requiredFields.some(key => {
            if (key === 'orderItem') {
                return (productsQuantities.length === 0)
            } else if (key === 'phone') {
                return !phoneMask.test(orderData[key as keyof SingleOrderDataType]) 
            } else {
                return !(orderData[key as keyof SingleOrderDataType])
            }
        }))
    }

    function checkField(key: string): boolean {
        const value = orderData[key as keyof IOrder] || ''
        if (key === 'phone') return !phoneMask.test(String(value))
        else return !value
    }

    useEffect(() => {
        checkDisabled()
    }, [orderData, productsQuantities])

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Створити ордер</DialogTitle>

            <DialogContent dividers>

                <Grid container spacing={2}>
                    {Object.entries(orderData).map(([key, value]) => (
                        <Grid key={key} item xs={12} sm={grid[key]}>
                            <TextField
                                label={orderDataLabels[key]}
                                color="primary"
                                variant="outlined"
                                sx={{width: '100%'}}
                                multiline={Boolean(inputRows[key])}
                                rows={inputRows[key] ? inputRows[key] : 1}
                                value={value}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue(event, key)}
                                required={requiredFields.includes(key)}
                                error={requiredFields.includes(key) && checkField(key)}
                                helperText={(requiredFields.includes(key) && checkField(key)) && getHelpText(key)}
                                InputProps={key === 'phone' ? {
                                    inputProps: { mask: phoneMask },
                                } : {}}
                            />
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <TableComponent
                            headers={computedTableHeaders}
                            tableData={buildTableData}
                            onChangeRowInput={onChangeProductInput}
                            tableId="create-order-table"
                        />
                        {requiredFields.includes('orderItem') && productsQuantities.length === 0 &&
                            <FormHelperText error>
                                {"Виберіть тип зброї"}
                            </FormHelperText>
                        }
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={closeModal}>
                    Закрити
                </Button>
                <Button variant="contained" disabled={createBtnDisabled} onClick={createOrderHandler}>
                    Створити
                </Button>
            </DialogActions>
        </Dialog>
    )
}