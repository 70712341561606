import { AxiosResponse } from 'axios';
import ProductService from 'services/ProductService';

import {
    put,
    call,
    all,
    takeLatest,
    Effect
} from 'redux-saga/effects';

import {
    // GET_SINGLE_PRODUCT,
    GET_PRODUCTS_LIST,
    GET_AVAILABLE_PRODUCTS,
    GET_PRODUCTS_BY_STATUS_IDS,
    UPDATE_PRODUCT,
    CREATE_PRODUCT,
    DELETE_PRODUCT,
    SET_PRODUCT_STATUS,
    GET_PRODUCT_STATUS_LIST,
    ASSIGN_PRODUCT,
    UN_ASSIGN_PRODUCT,
} from "store/types/productTypes";

import { IError } from 'types/ErrorInterface';
import {
    IWorkerCallback,
    IWorkerCreate,
    IWorkerDelete,
    IWorkerGenericData,
    IWorkerId,
    IWorkerUpdate,
    IWorkerUpdateStatus
} from 'types/SagaInterface';

import { IAssignProduct, IProductCreate, IProductUpdate, ProductStatusType } from "types/ProductInterface"

import { setAvailableProducts, setProductStatusList, setProductsList } from 'store/actions/productAction';
import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';

function* createProductWorker({payload}: IWorkerCreate<IProductCreate>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.create, data)
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback(response.data)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Продукт створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Cтворити продукт не вдалося"], error as IError ))
    }
}

// function* getSingleProductWorker({payload: productId}: {type: string, payload: number}) {
//     try {
//         const response: AxiosResponse = yield call(ProductService.getSingle, productId)
//         if (response?.status === 200) {
//             yield put(setSingleProduct(response.data))
//         }
//     } catch {
//         yield put(setNotifications([{
//                 id: Date.now(),
//                 bgColor: 'error',
//                 msg: "Помилка з'єднання. Отримати дані компонента не вдалося"
//         }]))
//     }
// }

function* getProductsListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setProductsList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список продуктів не вдалося"], error as IError ))
    }
}

function* getAvailableProductsWorker({payload}: IWorkerId) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.getAvailableProducts, id)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setAvailableProducts(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список продуктів не вдалося"], error as IError ))
    }
}

function* getProductsByStatusIdsWorker({payload}: IWorkerGenericData<Array<number>>) {
    const { data, callback } = payload
    try {
        const promises = data.map(function* (id: number): Generator<Effect, any, any> {
            return yield call(ProductService.getProductsByStatus, id);
        });

        const response: AxiosResponse[] = yield all(promises);
        
        if (response.every((res: AxiosResponse) => res.status === 200)) {

            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setProductsList([...response.map(el => el?.data).flat()]))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список продуктів не вдалося"], error as IError ))
    }
}

function* updateProductWorker({payload}: IWorkerUpdate<IProductUpdate>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.update, id, data)
        
        if (response?.status === 200 && callback) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Продукт оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Оновити продукт не вдалося"], error as IError ))
    }
}

function* setProductStatusWorker({payload}: IWorkerUpdateStatus<ProductStatusType>) {
    const { id, status, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.updateProductStatus, id, status)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Статус продукта оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Змінити статус продукта не вдалося"], error as IError ))
    }
}

function* getProductStatusListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.getProductStatusList)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setProductStatusList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Отримати список статусів продуктів не вдалося"], error as IError ))
    }
}

function* deleteProductWorker({payload}: IWorkerDelete) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.delete, id)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Продукт видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Видалити продукт не вдалося"], error as IError ))
    }
}

function* assignProductWorker({payload}: IWorkerCreate<IAssignProduct>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.assignProduct, data)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Продукт прив'язано"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Прив'язати продукт не вдалося"], error as IError ))
    }
}

function* unAssignProductWorker({payload}: IWorkerCreate<IAssignProduct>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ProductService.unAssignProduct, data)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Продукт відв'язано"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Відв'язати продукт не вдалося"], error as IError ))
    }
}

export function* productWatcher() {
    yield takeLatest(CREATE_PRODUCT, createProductWorker);
    // yield takeLatest(GET_SINGLE_PRODUCT, getSingleProductWorker);
    yield takeLatest(GET_PRODUCTS_LIST, getProductsListWorker);
    yield takeLatest(GET_AVAILABLE_PRODUCTS, getAvailableProductsWorker);
    yield takeLatest(GET_PRODUCTS_BY_STATUS_IDS, getProductsByStatusIdsWorker);
    yield takeLatest(UPDATE_PRODUCT, updateProductWorker);
    yield takeLatest(SET_PRODUCT_STATUS, setProductStatusWorker);
    yield takeLatest(GET_PRODUCT_STATUS_LIST, getProductStatusListWorker);
    yield takeLatest(DELETE_PRODUCT, deleteProductWorker);
    yield takeLatest(ASSIGN_PRODUCT, assignProductWorker);
    yield takeLatest(UN_ASSIGN_PRODUCT, unAssignProductWorker);
}
