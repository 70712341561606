import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// import { BasicSelect } from 'components/Select/BasicSelect';
import { TableComponent } from 'components/Table/Table';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

// import { IProductTypeState } from 'types/ProductTypeInterface';
import { ITableHeader, IActionItem } from 'types/TableInterface';
import { IComponentState } from 'types/ComponentInterface';
import { IRecipeDTO, IRecipeState } from 'types/RecipeInterface';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
    Grid,
    TextField,
} from '@mui/material';

export enum EComponentQuantity {
    DEFAULT = 1,
    MIN_QUANTITY = 1,
}

const tableHeaders: ITableHeader[] = [
    { key: "id", label: "ID", type: 'text', styles: {cellWidth: '10%', cellMinWidth: '100px'} },
    { key: "name", label: "Назва", type: 'text', styles: {cellWidth: '50%', cellMinWidth: '100px'} },
    { key: "quantity", label: "Кількість", type: 'input', styles: {cellWidth: '10%', cellMinWidth: '150px'} },
    {
        key: "actions",
        type: 'actions',
        headerActions: [] as IActionItem[],
        styles: {textAlign: 'right', cellWidth: '10%', cellMinWidth: '100px'}
    }, 
]

const inputFields: string[] = []
const requiredFields: string[] = ['product_type_id', 'parts']

const recipeDataLabels: {[key: string]: string}  = {
    name: 'Назва',
    remark: 'Примітка',
    product_type_id: 'Тип продукту',
    parts: 'Компоненти'
}

const dataTypes: {[key: string]: string} = {
    name: 'string',
    remark: 'string',
}

interface Props {}

export const EditRecipe: FC<Props> = () => {
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(false)
    const [ updateBtnDisabled, setUpdateBtnDisabled ] = useState<boolean>(true)
    const [ recipeData, setRecipeData ] = useState<Partial<IRecipeDTO>>({})
    const [ componentsQuantities, setComponentsQuantities ] = useState<{[key: string]: number}[]>([])
    const [ initialComponentsQuantities, setInitialComponentsQuantities ] = useState<{[key: string]: number}[]>([])
    const { componentsList } = useTypedSelector<IComponentState>(state => state.component)
    const { stateRecipeData, recipesList } = useTypedSelector<IRecipeState>(state => state.recipe)

    const {
        getProductTypeList,
        getComponentsList,
        setSingleRecipe,
        createRecipe,
        updateRecipe,
        getRecipesList,
        deleteRecipeComponent,
    } = useActions()

    useEffect(() => {
        getProductTypeList()
        getComponentsList()
    }, [])

    const navigate = useNavigate()
    const location = useLocation()

    const closeModal = () => {
        navigate(`/dashboard/warehouse/recipes${location.search}`)
    };

    const params = useParams()

    // Instead of sending request to get single entity data
    useEffect(() => {
        const recipeInstance = recipesList.find(el => el.product_type_id === Number(params.recipeId)) || {}
        setRecipeData({...recipeInstance})
        setSingleRecipe({...recipeInstance})
    }, [componentsList, recipesList])

    useEffect(() => {
        if ('parts' in stateRecipeData) {
            setComponentsQuantities(JSON.parse(JSON.stringify(stateRecipeData.parts)))
            setInitialComponentsQuantities(JSON.parse(JSON.stringify(stateRecipeData.parts)))
        }
    }, [stateRecipeData])

    const selectOptions = useMemo(() => {
        const selectedComponents = componentsQuantities.map(el => el.component_type_id)

        return componentsList
            .filter(el => !selectedComponents.includes(el.id))
            .map(el => ({
                key: el.id,
                label: el.name,
                input: {
                    label: 'Кількість',
                    type: 'number',
                    min: EComponentQuantity.MIN_QUANTITY,
                    defaultValue: EComponentQuantity.DEFAULT
                }
            }))
    }, [componentsList, componentsQuantities])

    function deleteComponentHandler(component_type_id: number) {
        let last_component = false
        if (componentsQuantities.length === 1) last_component = true
        deleteRecipeComponent(
            params.recipeId,
            component_type_id,
            last_component,
            (success: boolean) => {
                if (success) {
                    getRecipesList()
                    if (last_component) closeModal()
                }
            }
        )
    }

    function addComponent(component_type_id?: number | string, quantity?: number | string): void {
        if (component_type_id !== undefined && quantity !== undefined && !isDataLoading) {
            setIsDataLoading(true)

            const requestData = {
                product_type_id: recipeData.product_type_id,
                parts: [{
                    component_type_id: Number(component_type_id),
                    quantity: Number(quantity)
                }]
            }
            createRecipe(
                requestData,
                (success: boolean) => {
                    if (success) {
                        getRecipesList(() => {
                            setIsDataLoading(false)
                        })
                    }
                }
            )
        }
    }

    const computedTableHeaders = tableHeaders.map(el => {
        if (el.key === 'actions') {
            el.headerActions = [{
                iconName: 'AddCircleDropdown',
                options: selectOptions,
                action: addComponent
            }]
        }
        return el
    })

    function getComponentName(id: number): string | null {
        return componentsList.find(el => el.id === id)?.name || null
    }

    const buildTableData = useMemo(() => {
        return componentsQuantities.map((el, index: number) => ({
            tableId: el.component_type_id,
            table_row_key: `component_type_id_${el.component_type_id}_${index}`,
            id: el.component_type_id,
            name: getComponentName(el.component_type_id),
            quantity: {
                value: el.quantity ?? EComponentQuantity.DEFAULT,
                type: 'number',
                min: EComponentQuantity.MIN_QUANTITY,
                onChange: (quantity: (number | string), idx: number) => {
                    onChangeComponentQuantity( quantity, el.component_type_id, idx )
                }
            },
            actions: [
                {
                    iconName: "Delete",
                    confirmAction: () => deleteComponentHandler(el.component_type_id)
                },
            ]
        }))
    }, [componentsQuantities])

    function onChangeComponentQuantity(quantity: (number | string), id: number, index: number) {
        setComponentsQuantities(prevValue => {
            prevValue[index].quantity = Number(quantity)
            return [...prevValue]
        })
    }
    
    function setFieldValue(event: ChangeEvent<HTMLInputElement>, key: string) {
        switch (dataTypes[key]) {
            case 'number': setRecipeData({...recipeData, [key]: Number(event.target.value)});
                break;
            default: setRecipeData({...recipeData, [key]: event.target.value});
        }
    }

    const changedData = useMemo(() => {
        let output = []
        for(const index in initialComponentsQuantities) {
            if (initialComponentsQuantities[index].quantity !== componentsQuantities[index].quantity) {
                output.push(componentsQuantities[index])
            }
        }
        const initialComponentsIds = initialComponentsQuantities.map(el => el.component_type_id)
        const newComponents = componentsQuantities.filter(el => !initialComponentsIds.includes(el.component_type_id))
        output = [...output, ...newComponents]

        return output
    }, [componentsQuantities])

    const checkRecipeDataValidation: boolean = useMemo(() => {
        return requiredFields.some(key => {
            if (key === 'parts' && componentsQuantities.length === 0) return true
            else if (!recipeData[key as keyof IRecipeDTO]) return true
        })
    }, [recipeData, componentsQuantities])

    useEffect(() => {
        const disabled: boolean = Boolean(changedData.length) && !checkRecipeDataValidation

        setUpdateBtnDisabled(!disabled)
    }, [checkRecipeDataValidation, changedData]);

    function getValue(key: string) {
        let value = recipeData[key as keyof IRecipeDTO] || ''
        return value
    }

    function updateRecipeHandler() {
        setUpdateBtnDisabled(true)

        const requestData = {
            product_type_id: recipeData.product_type_id,
            parts: changedData.map(el => ({
                component_type_id: el.component_type_id,
                quantity: el.quantity,
            })),
        }

        updateRecipe(
            requestData,
            (success: boolean) => {
                if(success) {
                    closeModal()
                    getRecipesList()
                }
                setUpdateBtnDisabled(false)
        })
    }

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Редагувати рецепт</DialogTitle>

            <DialogContent dividers>

                <Grid container spacing={2}>

                    {inputFields.map((key) => (
                        <Grid key={key} item xs={12} sm={6}>
                            <TextField
                                label={recipeDataLabels[key]}
                                variant="outlined"
                                sx={{width: '100%'}}
                                value={getValue(key)}
                                required={requiredFields.includes(key)}
                                error={!getValue(key)}
                                helperText={!getValue(key) && "Обов'язкове поле"}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue(event, key)}
                            />
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                            <TableComponent
                                headers={computedTableHeaders}
                                tableData={buildTableData}
                                tableId="edit-recipe-table"
                            />
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={closeModal}>Закрити</Button>
                <Button variant="contained" onClick={updateRecipeHandler} disabled={updateBtnDisabled}>
                    Зберегти
                </Button>
            </DialogActions>
        </Dialog>
    )
}