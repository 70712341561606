import { FC, Fragment, ReactNode, useMemo, useState } from 'react';

import { setWarningNotifications } from 'store/actions/notificationAction';

import { Box, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';

import {
    ChevronRight as ChevronRightIcon,
    ChevronLeft as ChevronLeftIcon,
    RestartAlt as RestartAltIcon,
    Done as DoneIcon,
} from '@mui/icons-material/';

const staticLabels: {[key: string]: string} = {
    finish: "Завершити",
    back: "Назад",
    next: "Далі",
    skip: "Пропустити",
    reset: 'Скинути',
    optional: "Необов'язковий",
    completed: "Завершено!"
}

interface IObject {
    [key: string]: string | number
}

interface IStep {
    key: string;
    label: string;
    optional: boolean;
}

interface ILabels {
    finish?: string;
    back?: string;
    next?: string;
    skip?: string;
    reset?: string;
    optional?: string;
    completed?: string;
}

export interface IStepsMapping {
    prevStep: string;
    currentStep: string;
    nextStep: string;
}

interface Props {
    steps: IStep[];
    labels?: ILabels;
    isNextDisabled: boolean;
    headingStyle?: IObject;
    contentPadding?: string;
    contentStyle?: IObject;
    footerStyle?: IObject;
    sx?: IObject;
    onBack?: (steps: IStepsMapping) => void;
    onNext?: (steps: IStepsMapping) => void;
    onSkip?: (steps: IStepsMapping) => void;
    onFinish?: (steps: IStepsMapping) => void;
    onReset?: () => void;
    children: ReactNode[];
}

export const HorizontalLinearStepper: FC<Props> = ({
    steps = [],
    labels,
    isNextDisabled,
    headingStyle = {},
    contentPadding,
    contentStyle = {},
    footerStyle = {},
    sx = {},
    onBack,
    onNext,
    onSkip,
    onFinish,
    onReset,
    children,
}) => {
    const [ activeStep, setActiveStep ] = useState(0);
    const [ skipped, setSkipped ] = useState(new Set<number>());

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const stepsMapping = useMemo(() => {
        return {
            prevStep: steps?.[activeStep-1]?.key,
            currentStep: steps?.[activeStep]?.key,
            nextStep: steps?.[activeStep+1]?.key,
        }
    }, [activeStep, steps])

    const isStepOptional = useMemo(() => {
        return steps[activeStep]?.optional === true;
    }, [activeStep, steps])

    const isFinal = useMemo(() => {
        return activeStep === steps.length - 1
    }, [activeStep, steps])

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (isFinal) {
            if (onFinish instanceof Function) onFinish(stepsMapping)
        } else {
            if (onNext instanceof Function) onNext(stepsMapping)
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (onBack instanceof Function) onBack(stepsMapping)
    };

    const handleSkip = () => {
        if (!isStepOptional) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            // throw new Error("You can't skip a step that isn't optional.");
            setWarningNotifications("Ви не можете пропустити крок, який є обов’язковим.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
        if (onSkip instanceof Function) onSkip(stepsMapping)
    };

    const handleReset = () => {
        setActiveStep(0);
        if (onReset instanceof Function) onReset()
    };

    const contentStyles = { pt: 3, pb: 1, padding: contentPadding, ...contentStyle }

    function getLabel(key: string) {
        if (labels && labels[key as keyof ILabels]) return labels[key as keyof ILabels];
        else if (staticLabels[key]) return staticLabels[key];
    }

    return (
        <Box sx={{ width: '100%', ...sx }}>
            <Stepper activeStep={activeStep} sx={headingStyle}>
                {steps.map((item: IStep, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: ReactNode;
                    } = {};
                    if (item.optional) {
                        labelProps.optional = (
                            <Typography variant="caption">{getLabel('optional')}</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={item.label} {...stepProps}>
                            <StepLabel {...labelProps}>{item.label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length ? (
                <Fragment>
                    <div style={{ ...contentStyles, padding: contentPadding}}>
                    {/* <Box sx={contentStyles}> */}
                        {(children.length === activeStep + 1)
                              ? (
                                children[activeStep]
                            ) : (
                                <Typography variant="h4" color="primary" sx={{ textAlign: 'center' }}>
                                    {getLabel('completed')}
                                </Typography>
                            )
                        }
                    {/* </Box> */}
                    </div>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>
                            {getLabel('reset')}
                            <RestartAltIcon/>
                        </Button>
                    </Box>
                </Fragment>
            ) : (
                <Fragment>
                    <Box sx={contentStyles}>
                        {children[activeStep]}
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, ...footerStyle }}>
                        {activeStep > 0 &&
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                <ChevronLeftIcon/>
                                {getLabel('back')}
                            </Button>
                        }

                        <Box sx={{ flex: '1 1 auto' }} />

                        {isFinal
                             ? (
                                <Button disabled={isStepOptional ? false : isNextDisabled} onClick={handleNext}>
                                    {getLabel('finish')}
                                    <DoneIcon/>
                                </Button>
                            ) : (
                                isStepOptional
                                    ? (
                                        <>
                                            <Button disabled={!isNextDisabled} onClick={handleSkip} sx={{ mr: 1 }}>
                                                {getLabel('skip')}
                                                <ChevronRightIcon/>
                                            </Button>
                                            <Button disabled={isNextDisabled} onClick={handleNext}>
                                                {getLabel('next')}
                                                <ChevronRightIcon/>
                                            </Button>
                                        </>
                                    ) : (
                                        <Button disabled={isNextDisabled} onClick={handleNext}>
                                            {getLabel('next')}
                                            <ChevronRightIcon/>
                                        </Button>
                                    )
                            )

                        }
                    </Box>
                </Fragment>
            )}
        </Box>
    );
}