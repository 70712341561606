import { FC } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { SubmitEntityModal } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';

import { IOrderItem, IUpdateOrderShipmentDTO } from 'types/OrderInterface';

const labels: {[key: string]: string} = {
    shipment_uid: 'ТТН',
    external_link: 'Посилання',
    notes: 'Примітка',
    submit: 'Відправити',
}

const initialData: IUpdateOrderShipmentDTO = {
    shipment_uid: '',
    external_link: '',
    notes: '',
}

const fieldsTypes: {[key: string]: string} = {
    shipment_uid: 'text',
    external_link: 'text',
    notes: 'text'
}

const inputRows: {[key: string]: number} = {
    notes: 4
}

const grid: {[key: string]: number} = {
    shipment_uid: 12,
    external_link: 12,
    notes: 12,
}

const order: {[key: string]: number} = {
    shipment_uid: 1,
    external_link: 2,
    notes: 3,
}

const requiredFields: Array<string> = []

export const DeliveryForm: FC = () => {

    const orderItem: IOrderItem = useOutletContext()

    const { updateOrderShipment, getAvailableOrdersShipment } = useActions()

    const params = useParams();

    if (orderItem?.shipment?.shipmentStatus?.shipment_status_id === 1) {
        return (
            <SubmitEntityModal
                title="Відправити замовлення"
                order={order}
                grid={grid}
                fieldsTypes={fieldsTypes}
                inputRows={inputRows}
                initialData={initialData}
                requiredFields={requiredFields}
                labels={labels}
                backRoute="/dashboard/movements/delivery-to-client"
                isDataExists={true}
                submitEnityAction={updateOrderShipment.bind(null, Number(params.orderItemId))}
                getEntityActionOnSubmit={getAvailableOrdersShipment}
            />
        )
    }

    return null
}