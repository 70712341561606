import { SET_OPERATORS_LIST } from "store/types/operatorTypes"
import { IOperatorState, OperatorAction } from "types/OperatorInterface"

const initialState: IOperatorState = {
    operatorsList: []
}

export const operatorReducer = (state = initialState, {type, payload}: OperatorAction): IOperatorState => {
    switch (type) {
        case SET_OPERATORS_LIST:
            return { ...state, operatorsList: payload }
        default:
            return state
    }
}