import { ElementType, FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTypedSelector } from "hooks/useTypedSelector";

import { IMenuState } from "types/MenuInterface";
import { IUserState } from "types/UserInterface";
import { IAuthState } from "types/AuthInterface";

import { READ_ORDER, READ_PRODUCTS, READ_WAREHOUSE } from "store/types/policiesTypes";

import './sidebar.scss'
import {
    useTheme,
    Drawer,
    IconButton,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";

import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
    // MoveToInbox as InboxIcon,
    WarningAmber as WarningAmberIcon,
    Warehouse as WarehouseIcon,
    LocalShipping as ShipmentIcon,
    ReceiptLong as ReceiptLongIcon,
    AdminPanelSettings,
    EventNote as EventNoteIcon,
} from '@mui/icons-material';

interface Props {
    drawerWidth: number
    isOpened: boolean
    closeDrawer: () => void
    DrawerHeader: ElementType;
}

export const Sidebar: FC<Props> = ({ drawerWidth, isOpened, closeDrawer, DrawerHeader }) => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { menu } = useTypedSelector<IMenuState>(state => state.menu)
    const { userRole } = useTypedSelector<IUserState>(state => state.user)

    const location = useLocation()

    const theme = useTheme();

    const isAdmin = (role: string): boolean => {
        if (userRole === 'admin' ) return true
        else if (userRole === 'manager' && role === userRole) return true
        return false
    }

    function isActiveMenuItem(name: string) {
        if (location.pathname.split('/')[2] === name) return true
        return false
    }

    const checkPermission = (key: string): boolean => {
        return (
            key === 'warehouse' && authData?.policies.includes(READ_WAREHOUSE) ||
            key === 'movements' && authData?.policies.includes(READ_PRODUCTS) ||
            key === 'order' && authData?.policies.includes(READ_ORDER) ||
            key === 'rsfs' ||
            key === 'watchlog'
        )
    }

    return (
        <div className="sidebar text-bg-secondary">
            <Drawer
                sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
                }}
                variant="persistent"
                anchor="left"
                open={isOpened}
            >
                <DrawerHeader>
                    <IconButton onClick={closeDrawer}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>

                <Divider />

                <List>
                    {menu.map((menuItem) => (isAdmin(menuItem.role) && (
                        checkPermission(menuItem.key) &&
                            <NavLink
                                key={menuItem.key}
                                style={{textDecoration: 'none', color: 'inherit'}}
                                to={menuItem.to}
                            >
                                <ListItemButton selected={isActiveMenuItem(menuItem.name)}>
                                    <ListItemIcon>
                                        {menuItem.key === 'warehouse' && <WarehouseIcon />}
                                        {menuItem.key === 'movements' && <ShipmentIcon />}
                                        {menuItem.key === 'order' && <ReceiptLongIcon />}
                                        {menuItem.key === 'rsfs' && <AdminPanelSettings />}
                                        {menuItem.key === 'watchlog' && <EventNoteIcon />}
                                    </ListItemIcon>

                                    <ListItemText primary={menuItem.label} />
                                    
                                    {menuItem.name === 'warehouse' &&
                                        <WarningAmberIcon fontSize="small" color="warning"/>
                                    }
                                </ListItemButton>
                            </NavLink>
                        )
                    ))}
                </List>
            </Drawer>
        </div>
    )
}