import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import { IUnconfirmedOrder } from "types/UnconfirmedOrderInterface";
import { IResponseMessage } from "types/ResponseInterface";

const path_name = '/unconfirmed-order-request'

export default class UnconfirmedOrderService {

    static async getAll(): Promise<AxiosResponse<IUnconfirmedOrder[]>> {
        return $api.get<IUnconfirmedOrder[]>(`${path_name}s`);
    }

    static async updateStatus(id: number, status: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.put<IResponseMessage>(`${path_name}/${id}/${status}`);
    }
}