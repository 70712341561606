import { FC, useMemo} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { IBasicSelectOption } from 'components/Select/BasicSelect';
import { SubmitEntityModal, INumberRange } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { ICreateOrderItem } from 'types/OrderInterface';
import { IProductType, IProductTypeState } from 'types/ProductTypeInterface';

const orderDataLabels: {[key: string]: string} = {
    product_type_id: 'Тип продукту',
    quantity: 'Кількість',
    shipping_address: 'Адреса',
}

const initialOrderItemData: ICreateOrderItem = {
    product_type_id: 0,
    quantity: 1,
    shipping_address: '',
}

const dataTypes: {[key: string]: string} = {
    quantity: 'number',
}

const fieldsTypes: {[key: string]: string} = {
    product_type_id: 'select',
    quantity: 'number',
    shipping_address: 'text',
}

const grid: {[key: string]: number} = {
    product_type_id: 6,
    quantity: 6,
    shipping_address: 12,
}

const order: {[key: string]: number} = {
    product_type_id: 1,
    quantity: 2,
    shipping_address: 3,
}

const numberRange: INumberRange = {
    quantity: {min: 1},
}

const requiredFields: Array<string> = ['product_type_id', 'shipping_address', 'quantity']

const emptyDataInfo = {
    entity: "типу продукту",
    to: "/dashboard/warehouse/product-types/create"
}

export const CreateOrderItem: FC = () => {
    const { productTypeList } = useTypedSelector<IProductTypeState>(state => state.product_type)

    const { createOrderItem, getOrdersList, getProductTypeList } = useActions()

    const basicSelectOptions: IBasicSelectOption[] = useMemo(() => {
        return productTypeList.map((el: IProductType) => ({
            key: el.product_type_id,
            label: el.product_type,
        }))
    }, [productTypeList])

    const params = useParams();
    const location = useLocation();

    return (
        <SubmitEntityModal
            title="Додати замовлення"
            order={order}
            grid={grid}
            dataTypes={dataTypes}
            fieldsTypes={fieldsTypes}
            numberRange={numberRange}
            initialData={initialOrderItemData}
            requiredFields={requiredFields}
            labels={orderDataLabels}
            backRoute={`/dashboard/order/customer/confirmed${location.search}`}
            emptyDataInfo={emptyDataInfo}
            basicSelectOptions={basicSelectOptions}
            isDataExists={!!(basicSelectOptions.length)}
            getEntityActionOnMount={getProductTypeList}
            submitEnityAction={createOrderItem.bind(null, Number(params.orderId))}
            getEntityActionOnSubmit={getOrdersList}
        />
    )
}