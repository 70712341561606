import { FC } from "react";
import { Typography, TypographyProps } from "@mui/material";
import Link from '@mui/material/Link';

interface Props extends TypographyProps {}

export const Copyright: FC<Props> = (props) => {
    
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="#">
                Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}