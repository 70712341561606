import { DELETE_NOTIFICATION, SET_NOTIFICATIONS } from "store/types/notificationTypes"
import { NotificationAction, INotificationState } from "types/NotificationInterface"

const initialState: INotificationState = {
    notifications: []
}

export const notificationReducer = (state = initialState, action: NotificationAction): INotificationState => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return { ...state, notifications: [...state.notifications, ...action.payload] }
        case DELETE_NOTIFICATION:
            return { ...state, notifications: state.notifications.filter(el => el.id !== action.payload) }
        default:
            return state
    }
}