import { FC } from "react";
import { ITableDataItem, ITableHeader } from "types/TableInterface";

import {
    ErrorOutline as ErrorOutlineIcon,
    WarningAmber as WarningAmberIcon,
} from '@mui/icons-material';

interface Props<T> {
    data: ITableDataItem<T>;
    header: ITableHeader;
}

export const TableTextIconType: FC<Props<any>> = ({ data, header }) => {
    return (
        <div className="table-text-icon-type" style={{display: 'flex', alignItems: 'center'}}>
            {data[header?.key]?.value}
            
            {data[header?.key]?.iconName === 'WarningAmber' &&
                <WarningAmberIcon
                    sx={{marginLeft: '5px'}}
                    fontSize={data[header?.key]?.fontSize ?? 'small'}
                    color={data[header?.key]?.color ?? 'warning'}
                />
            }
            {data[header?.key]?.iconName === 'ErrorOutline' &&
                <ErrorOutlineIcon
                    sx={{marginLeft: '5px'}}
                    fontSize={data[header?.key]?.fontSize ?? 'small'}
                    color={data[header?.key]?.color ?? 'error'}
                />
            }
        </div>
    )
}