import { FC } from "react";

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { ITableDataItem, ITableHeader, IActionItem } from "types/TableInterface";
import { INotificationState } from 'types/NotificationInterface';

import {
    Tooltip,
    CircularProgress,
} from '@mui/material';

import {
    Download as DownloadIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    Add as AddIcon,
    Launch as LaunchIcon,
    Clear as ClearIcon,
    LibraryAdd,
    HourglassTop,
    VerifiedUser,
    RemoveModerator,
    LocalShipping as LocalShippingIcon,
    Verified as VerifiedIcon,
    // PersonOff,
    // NoAccounts,
    // Block,
    PhonelinkErase,
} from '@mui/icons-material';

interface Props<T> {
    data: ITableDataItem<T>;
    header: ITableHeader;
}

export const TableActionsType: FC<Props<any>> = ({ data, header }) => {
    const { notifications } = useTypedSelector<INotificationState>(state => state.notification)

    const { setNotifications } = useActions()

    function confirmAction(actionItem: IActionItem) {
        const confirmationIndex = notifications?.findIndex(el => el?.id === 'confirm-action')
        
        if (confirmationIndex < 0) {
            setNotifications([
                {
                    id: 'confirm-action',
                    severity: actionItem?.severity ? actionItem.severity : 'warning',
                    bgColor: actionItem?.bgColor ? actionItem.bgColor : 'warning',
                    duration: actionItem?.duration ? actionItem.duration : 20000,
                    msg: actionItem?.confirmationMsg ? actionItem.confirmationMsg : 'Підтвердіть видалення',
                    btn: [{
                        label: actionItem?.cancelBtn?.label ? actionItem.cancelBtn.label : 'Відмінити',
                        variant: actionItem?.cancelBtn?.variant ? actionItem.cancelBtn.variant : 'contained',
                        color: actionItem?.cancelBtn?.color ? actionItem.cancelBtn.color : 'primary',
                        action: actionItem?.cancelBtn?.action instanceof Function && actionItem.cancelBtn.action,
                    },{
                        label: actionItem?.confirmBtn?.label ? actionItem.confirmBtn.label : 'Підтвердити',
                        variant: actionItem?.confirmBtn?.variant ? actionItem.confirmBtn.variant : 'contained',
                        color: actionItem?.confirmBtn?.color ? actionItem.confirmBtn.color : 'error',
                        action: actionItem.confirmAction
                    }]
                },
            ])
        }
    }

    function handleItemAction(actionItem: IActionItem) {
        if (actionItem?.confirmAction instanceof Function) {
            confirmAction(actionItem)
        }
        if (actionItem?.action instanceof Function) {
            actionItem?.action()
        }
    }

    return (
        <div
            className="table-actions-type"
            style={{display: 'inline-block', position: "relative", whiteSpace: 'nowrap'}}
        >

            <CircularProgress
                size={25}
                sx={{
                    position: "absolute",
                    right: 0,
                    visibility: data?.rowSettings?.loading ? 'visible' : 'hidden'
                }}
            />
            
            {data[header?.key]?.map((actionItem: IActionItem, index: number) =>
                <div
                    className='action-icon'
                    key={actionItem?.iconName + index}
                >
                    <Tooltip title={actionItem?.tooltip}>
                        <div
                            className="action-icon-wrapper"
                            style={{visibility: data?.rowSettings?.loading ? 'hidden' : 'visible'}}
                            onClick={() => {
                                if(!actionItem?.disabled){
                                    handleItemAction(actionItem)
                                }
                            } }
                        >
                            {actionItem?.iconName === 'Delete' &&
                                <DeleteIcon color={actionItem?.iconColor || "error"}/>
                            }
                            {actionItem?.iconName === 'Clear' &&
                                <ClearIcon color={actionItem?.iconColor || "error"}/>
                            }
                            {actionItem?.iconName === 'Edit' &&
                                <EditIcon color={actionItem?.iconColor || "success"}/>
                            }
                            {actionItem?.iconName === 'Add' &&
                                <AddIcon color={actionItem?.iconColor || "primary"}/>
                            }
                            {actionItem?.iconName === 'Launch' &&
                                <LaunchIcon color={actionItem?.iconColor || "primary"}/>
                            }
                            {actionItem?.iconName === 'LibraryAdd' &&
                                <LibraryAdd color={actionItem?.iconColor || "primary"}/>
                            }
                            {actionItem?.iconName === 'HourglassTop' &&
                                <HourglassTop color={actionItem?.iconColor || 'primary'}/>
                            }
                            {actionItem?.iconName === 'VerifiedUser' &&
                                <VerifiedUser color={actionItem?.iconColor || 'success'}/>
                            }
                            {actionItem?.iconName === 'RemoveModerator' &&
                                <RemoveModerator color={actionItem?.iconColor || 'error'}/>
                            }
                            {actionItem?.iconName === 'PhonelinkErase' &&
                                <PhonelinkErase color={actionItem?.iconColor || 'error'}/>
                            }
                            {actionItem?.iconName === 'Download' &&
                                <DownloadIcon color={actionItem?.iconColor || 'primary'}/>
                            }
                            {actionItem?.iconName === 'LocalShipping' &&
                                <LocalShippingIcon color={actionItem?.iconColor || 'primary'}/>
                            }
                            {actionItem?.iconName === 'Verified' &&
                                <VerifiedIcon color={actionItem?.iconColor || 'primary'}/>
                            }
                        </div>
                    </Tooltip>
                </div>
            )}
        </div>
    )
}