import { FC } from "react";

import { Droppable } from "react-beautiful-dnd";

import { Paper } from "@mui/material";

import {
    ControlPointDuplicate as ControlPointDuplicateIcon,
    KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
} from "@mui/icons-material";

interface Props {}

export const DroppableShift: FC<Props> = () => {

    return (
        <Droppable droppableId="new-list" key="new-list">
            {(provided, snapshot) => (
                <Paper
                    elevation={10}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                        width: "100%",
                        backgroundColor: "#f2f2f2",
                        minHeight: 50,
                        maxHeight: 50,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {snapshot.isDraggingOver
                        ?
                        <KeyboardDoubleArrowUpIcon sx={{ fontSize: 50 }} color="success" />
                        :
                        <ControlPointDuplicateIcon sx={{ fontSize: 50 }} color="primary" />
                    }
                    {provided.placeholder}
                </Paper>
            )}
        </Droppable>
    )
}