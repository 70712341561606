import { IMenuState } from "types/MenuInterface"

const initialState: IMenuState = {
    menu: [
        { key: 'warehouse', label: 'Складування', to: '/dashboard/warehouse/components', name: 'warehouse', role: 'manager' },
        { key: 'movements', label: 'Переміщення', to: '/dashboard/movements/construction', name: 'movements', role: 'manager' },
        { key: 'order', label: 'Замовлення', to: '/dashboard/order/customer/confirmed', name: 'order', role: 'manager' },
        { key: 'rsfs', label: 'RSFS', to: '/dashboard/rsfs/operator', name: 'rsfs', role: 'admin' },
        { key: 'watchlog', label: 'Журнал подій', to: '/dashboard/watch-logs', name: 'watch-logs', role: 'admin' },
    ]
}

export const menuReducer = (state = initialState): IMenuState => {
    return state
}