import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import { IProductType, IProductTypeCreate, IProductTypeUpdate } from "types/ProductTypeInterface";
import { IResponseMessage } from "types/ResponseInterface";

const path_name = '/product-type'

export default class ProductTypeService {

    static async create(data: IProductTypeCreate): Promise<AxiosResponse<number>> {
        return $api.post<number>(`${path_name}`, data);
    }

    // static async getSingle(id: number): Promise<AxiosResponse<IProductType>> {
    //     return $api.get<IProductType>(`${path_name}/${id}`);
    // }

    static async getAll(): Promise<AxiosResponse<IProductType[]>> {
        return $api.get<IProductType[]>(`${path_name}`);
    }

    static async update(id: number, data: IProductTypeUpdate): Promise<AxiosResponse<IResponseMessage>> {
        return $api.patch<IResponseMessage>(`${path_name}/${id}`, data);
    }

    static async delete(id: number): Promise<AxiosResponse<IResponseMessage>> {
        return $api.delete<IResponseMessage>(`${path_name}/${id}`);
    }
}