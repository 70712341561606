import { AxiosResponse } from 'axios';
import SeriesService from 'services/SeriesService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    GET_SERIES_LIST,
    GET_AVAILABLE_SERIES,
    CREATE_SERIES,
    DELETE_SERIES,
    DELETE_SERIAL_NUMBER,
} from "store/types/seriesTypes";

import { IError } from 'types/ErrorInterface';
import { IWorkerCallback, IWorkerDelete, IWorkerUnknown } from 'types/SagaInterface';

import { setSeriesList, setAvailableSeries } from 'store/actions/seriesAction';
import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';


function* createSeriesWorker({payload}: IWorkerUnknown) {
    const { action_type, series_id, data, callback } = payload
    try {
        let response: AxiosResponse | null = null
        if (action_type === 'create-series') {
            response = yield call(SeriesService.create, data)
        } else if (action_type === 'create-serial-number') {
            response = yield call(SeriesService.createSerialNumber, {count: data.serialNumberCount, series_id})
        }
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback({status: 'success', success: true, data: response?.data})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Серію створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Cтворити серію не вдалося"], error as IError ))
    }
}

function* getSeriesListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(SeriesService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setSeriesList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список серій не вдалося"], error as IError ))
    }
}

function* getAvailableSeriesWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(SeriesService.getAvailableSeries)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setAvailableSeries(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список серій не вдалося"], error as IError ))
    }
}

interface IWorkerTypedDelete extends IWorkerDelete {
    payload: IWorkerDelete['payload'] & {
        action_type: string
    }
}

function* deleteSeriesWorker({payload}: IWorkerTypedDelete) {
    const { id, action_type, callback } = payload
    try {
        let response: AxiosResponse | null = null
        if (action_type === 'delete_series') {
            response = yield call(SeriesService.delete, id)
        } else if (action_type === 'delete_serial_number') {
            response = yield call(SeriesService.deleteSerialNumber, id)
        }
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Серію видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Видалити серію не вдалося"], error as IError ))
    }
}

export function* seriesWatcher() {
    yield takeLatest(CREATE_SERIES, createSeriesWorker);
    yield takeLatest(GET_SERIES_LIST, getSeriesListWorker);
    yield takeLatest(GET_AVAILABLE_SERIES, getAvailableSeriesWorker);
    yield takeLatest(DELETE_SERIES, deleteSeriesWorker);
    yield takeLatest(DELETE_SERIAL_NUMBER, deleteSeriesWorker);
}
