import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Step1 } from './CreateProductSteps/Step1';
import { Step2 } from './CreateProductSteps/Step2';
import { Step3 } from './CreateProductSteps/Step3';
import { Step4 } from './CreateProductSteps/Step4';
import { Step5 } from './CreateProductSteps/Step5';

import { HorizontalLinearStepper, IStepsMapping } from 'components/Stepper/HorizontalLinearStepper';

import { useActions } from 'hooks/useActions';

import { IProductCreate } from 'types/ProductInterface';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    Button,
} from '@mui/material';

const initialProductData = {
    product_type_id: 0,
    serial_number_id: 0,
    notes: '',
}

const steps = [
    {key: 'product_type', label: 'Рецепт', optional: false},
    {key: 'series', label: 'Серія', optional: false},
    {key: 'serial_number', label: 'Серійний номер', optional: false},
    {key: 'note', label: 'Примітка', optional: true},
    {key: 'result', label: 'Результат', optional: false},
]

interface Props {}

export const CreateProduct: FC<Props> = () => {
    const [ submitBtnDisabled, setSubmitBtnDisabled ] = useState<boolean>(true)
    const [ isNextDisabled, setIsNextDisabled ] = useState<boolean>(true)
    const [ productData, setProductData ] = useState<IProductCreate>(initialProductData)
    const [ selectedSeries, setSelectedSeries ] = useState<number>(0)

    const {
        createProduct,
        getProductsList,
        setRecipesList,
        setSeriesList,
        setSingleSeries,
    } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    const closeModal = (id?: number | any) => {
        let query = ''
        if (id && typeof id === 'number') query = `?productId=${id}`
        else if (location.search) query = location.search
        navigate(`/dashboard/warehouse/products${query}`)
    };

    useEffect(() => {
        return () => {
            handleReset()
        }
    }, [])

    function handleReset() {
        setProductData(initialProductData)
        setRecipesList([])
        setSeriesList([])
        setSingleSeries({})
        setSelectedSeries(0)
        setSubmitBtnDisabled(true)
    }

    function handleSelectData(value: number | string, key: string) {
        setProductData({...productData, [key]: value})
        setIsNextDisabled(false)
    }

    function handleSelectSeries(value: number) {
        setSelectedSeries(value)
        setIsNextDisabled(false)
    }

    function handleBack (stepsMapping: IStepsMapping) {
        if (stepsMapping.prevStep === 'note' && !productData.notes) setIsNextDisabled(true) 
        else setIsNextDisabled(false)
    }

    function handleNext(stepsMapping: IStepsMapping) {
        if (stepsMapping.nextStep === 'series' && selectedSeries) return
        else if (stepsMapping.nextStep === 'serial_number' && productData.serial_number_id) return
        else if (stepsMapping.nextStep === 'note' && productData.notes) return
        else if (stepsMapping.nextStep === 'result') setIsNextDisabled(false)
        else setIsNextDisabled(true)
    }

    useEffect(() => {
        if (productData.notes) {
            setIsNextDisabled(false)
        } else {
            setIsNextDisabled(true)
        }
    }, [productData.notes])

    function createProductHandler() {
        setSubmitBtnDisabled(true)

        createProduct(
            productData,
            (id: number) => {
                if(id) {
                    getProductsList()
                    closeModal(id)
                }
                setSubmitBtnDisabled(false)
        })
    }

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">Створення продукт</DialogTitle>

            <DialogContent sx={{minHeight: '300px'}} dividers>
                <HorizontalLinearStepper
                    sx={{height: '50vh', display: 'flex', flexDirection: 'column'}}
                    headingStyle={{height: '50px'}}
                    contentPadding="25px 30px 5px"
                    contentStyle={{flex: '1 1 auto', maxHeight: 'calc(100% - 100px)'}}
                    footerStyle={{height: '50px'}}
                    steps={steps}
                    labels={{finish: 'Підтвердити'}}
                    isNextDisabled={isNextDisabled}
                    onBack={handleBack}
                    onNext={handleNext}
                    onSkip={handleNext}
                    onFinish={() => setSubmitBtnDisabled(false)}
                    onReset={handleReset}
                >
                    {/* FIRST STEP - SELECT RECIPE */}
                    <Step1
                        value={productData.product_type_id}
                        onChange={(value) => handleSelectData(value, 'product_type_id')}
                    />

                    {/* SECOND STEP - SELECT SERIES */}
                    <Step2
                        value={selectedSeries}
                        onChange={handleSelectSeries}
                    />

                    {/* THIRD STEP - SELECT SERIAL NUMBER */}
                    <Step3
                        value={productData.serial_number_id}
                        onChange={(value) => handleSelectData(value, 'serial_number_id')}
                    />

                    {/* FOURTH STEP - WRITE NOTE */}
                    <Step4
                        value={productData.notes}
                        onChange={(value) => handleSelectData(value, 'notes')}
                    />

                    {/* FIFTH STEP - CHECK ALL INFO */}
                    <Step5 data={productData}/>

                    {/* RESULT - CREATE PRODUCT */}
                    <Step5 data={productData}/>

                </HorizontalLinearStepper>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={closeModal}>Закрити</Button>

                {!submitBtnDisabled &&
                    <Button
                        variant="contained"
                        disabled={submitBtnDisabled}
                        onClick={createProductHandler}
                    >
                        Створити продукт
                    </Button>
                }
            </DialogActions>
        </Dialog>
    )
}