import {
    SET_SINGLE_PRODUCT_TYPE,
    SET_PRODUCT_TYPE_LIST,
} from "store/types/productTypeTypes"

import { IProductType, IProductTypeState, ProductTypeAction } from "types/ProductTypeInterface"

const initialState: IProductTypeState = {
    stateProductTypeData: {} as IProductType,
    productTypeList: [] as IProductType[]
}

export const productTypeReducer = (state = initialState, {type, payload}: ProductTypeAction): IProductTypeState => {
    switch (type) {
        case SET_SINGLE_PRODUCT_TYPE:
            return { ...state, stateProductTypeData: payload }
        case SET_PRODUCT_TYPE_LIST:
            return { ...state, productTypeList: payload }
        default:
            return state
    }
}