// import { FC } from "react";
// import { Route, Routes } from "react-router-dom";

import { Warehouse } from 'pages/Dashboard/Warehouse/Warehouse';

import { Components } from 'pages/Dashboard/Warehouse/Components/Components';
import { CreateComponent } from 'pages/Dashboard/Warehouse/Components/CreateComponent';
import { EditComponent } from 'pages/Dashboard/Warehouse/Components/EditComponent';

import { ProductTypes } from "pages/Dashboard/Warehouse/ProductType/ProductTypes";
import { CreateProductType } from "pages/Dashboard/Warehouse/ProductType/CreateProductType";
import { EditProductType } from "pages/Dashboard/Warehouse/ProductType/EditProductType";

import { Recipes } from 'pages/Dashboard/Warehouse/Recipes/Recipes';
import { CreateRecipe } from 'pages/Dashboard/Warehouse/Recipes/CreateRecipe';
import { EditRecipe } from 'pages/Dashboard/Warehouse/Recipes/EditRecipe';

import { Products } from 'pages/Dashboard/Warehouse/Products/Products';
import { CreateProduct } from 'pages/Dashboard/Warehouse/Products/CreateProduct';
import { EditProduct } from 'pages/Dashboard/Warehouse/Products/EditProduct';

import { Series } from 'pages/Dashboard/Warehouse/Series/Series';
import { CreateSeries } from 'pages/Dashboard/Warehouse/Series/CreateSeries';

import { WRITE_WAREHOUSE } from 'store/types/policiesTypes';

export const WarehouseRoutes = (policies?: Array<string>) => [
    {
        id: "warehouse",
        path: "warehouse",
        element: <Warehouse/>,
        children: [
            {
                id: "components",
                path: "components",
                element: <Components/>,
                children: policies?.includes(WRITE_WAREHOUSE) ? [
                    { id: "create-component", path: "create", element: <CreateComponent/> },
                    { id: "edit-component", path: "edit/:componentId", element: <EditComponent/> },
                ] : []
            },
            {
                id: "product-types",
                path: "product-types",
                element: <ProductTypes/>,
                children: policies?.includes(WRITE_WAREHOUSE) ? [
                    { id: "create-product-type", path: "create", element: <CreateProductType/> },
                    { id: "edit-product-type", path: "edit/:productTypeId", element: <EditProductType/> },
                ] : []
            },
            {
                id: "recipes",
                path: "recipes",
                element: <Recipes/>,
                children: policies?.includes(WRITE_WAREHOUSE) ? [
                    { id: "create-recipe", path: "create", element: <CreateRecipe/> },
                    { id: "edit-recipe", path: "edit/:recipeId", element: <EditRecipe/> },
                ] : []
            },
            {
                id: "products",
                path: "products",
                element: <Products/>,
                children: policies?.includes(WRITE_WAREHOUSE) ? [
                    { id: "create-product", path: "create", element: <CreateProduct/> },
                    { id: "edit-product", path: "edit/:productId", element: <EditProduct/> },
                ] : []
            },
            {
                id: "series",
                path: "series",
                element: <Series/>,
                children: policies?.includes(WRITE_WAREHOUSE) ? [
                    { id: "create-series", path: "create/:type", element: <CreateSeries/> },
                    { id: "create-serial-number", path: "create/:type/:seriesId", element: <CreateSeries/> },
                ] : []
            },
        ]
    }
]

// interface Props {}

// export const WarehouseRouter: FC<Props> = () => {
//     return (
//         <Routes>
//             <Route path="/" element={<Warehouse/>}>
//                 <Route path="components" element={<Components/>}>
//                     <Route path="create" element={<CreateComponent/>}/>
//                     <Route path="edit/:componentId" element={<EditComponent/>}/>
//                 </Route>
//                 <Route path="product-types" element={<ProductTypes/>}>
//                     <Route path="create" element={<CreateProductType/>}/>
//                     <Route path="edit/:productTypeId" element={<EditProductType/>}/>
//                 </Route>
//                 <Route path="recipes" element={<Recipes/>}>
//                     <Route path="create" element={<CreateRecipe/>}/>
//                     <Route path="edit/:recipeId" element={<EditRecipe/>}/>
//                 </Route>
//                 <Route path="products" element={<Products/>}>
//                     <Route path="create" element={<CreateProduct/>}/>
//                     <Route path="edit/:productId" element={<EditProduct/>}/>
//                 </Route>
//                 <Route path="series" element={<Series/>}>
//                     <Route path="create/:type" element={<CreateSeries/>}/>
//                     <Route path="create/:type/:seriesId" element={<CreateSeries/>}/>
//                 </Route>
//             </Route>
//         </Routes>
//     )
// }