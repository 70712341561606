import { FC } from "react";

import { Droppable } from "react-beautiful-dnd";

// import { FilterSortBar } from "components/FilterSort/FilterSortBar";
import { DraggableCardChip } from "./DraggableCardChip";

import { IChip } from "types/MaterialInterface";

import { List, ListSubheader, Paper } from "@mui/material";

export interface IDraggableCardListData {
    title: string;
    subtitle?: string;
    quantity?: number;
}

export interface IListItem {
    id: string | number;
    content: string;
    chip: IChip;
    isDisabled?: boolean;
}

interface Props {
    list: IListItem[];
    listId: string;
    data: IDraggableCardListData;
    showScrollBar?: boolean;
    style?: {[key: string]: string | number};
}

export const DraggableCardList: FC<Props> = ({
    list,
    listId,
    data,
    showScrollBar = false,
    style = { maxHeight: "400px", width: 400, },
}) => {

    return (
        <Droppable droppableId={listId} key={listId}>
            {(provided, snapshot) => (
                <Paper
                    elevation={10}
                    style={{
                        marginBottom: "8px",
                        overflow: "auto",
                        backgroundColor: snapshot.isDraggingOver ? "lightblue" : "#f2f2f2",
                        ...style
                    }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={showScrollBar ? "scroll-bar-primary-y" : "scroll-bar-primary-y-hide"}
                >
                    <List
                        aria-labelledby={data?.title}
                        style={{ padding: 8 }}
                    >
                        <ListSubheader
                            id="nested-list-subheader"
                            style={{
                                height: "100%",
                                fontSize: "18px",
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: snapshot.isDraggingOver ? "lightblue" : "#f2f2f2",
                            }}
                        >
                            <div>{data?.title}</div>
                            <div
                                style={{
                                    fontWeight: "bold",
                                    color: data?.quantity === list?.length ? "green" : "",
                                }}
                            >
                                {data?.subtitle}
                            </div>
                        </ListSubheader>

                        {/* <FilterSortBar
                            slidersRange={[]}
                            keyName={'header.key'}
                            data={{filter: [], sort: {}}}
                            filterData={{}}
                            sortData={[]}
                        /> */}

                        {list?.map((item: IListItem, index: number) => (
                            <DraggableCardChip
                                key={item?.id}
                                id={item?.id}
                                index={index}
                                content={item?.content}
                                chip={item?.chip}
                                isDisabled={item?.isDisabled}
                            />
                        ))}
                        {provided.placeholder}
                    </List>
                </Paper>
            )}
        </Droppable>
    );
}