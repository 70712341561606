import { FC, Fragment, ReactNode } from "react";

import { Box, CircularProgress } from "@mui/material";

interface Props {
    isDataLoading: boolean;
    children: ReactNode;
}

export const DataLoading: FC<Props> = ({ children, isDataLoading = true }) => {
    return (
        <Fragment>
            {isDataLoading
                ?
                    <Box sx={{width: "100%", height: '100%', display: 'flex', alignItems: 'center'}}>
                        <CircularProgress sx={{display: 'block', margin: '0 auto'}}/>
                    </Box>
                :
                    children
            }
        </Fragment>
    )
}