import {
    FC,
    useState,
    useEffect,
    MouseEvent,
    ChangeEvent,
    KeyboardEvent,
    FocusEvent
} from 'react';

import { getTypedValue } from 'utils/helpers/getTypedValue';

import {
    FormControl,
    MenuItem,
    TextField,
    Select,
    SelectChangeEvent,
    Paper,
    ClickAwayListener,
    MenuList
} from '@mui/material';

import { AddCircle } from '@mui/icons-material';

interface IValues {
    [key: string | number]: string | number
}

interface IInput {
    label?: string;
    type?: string;
    min?: number;
    max?: number;
    defaultValue?: number | string;
};

interface IOption {
    key: number | string;
    label: string;
    input?: IInput;
}

interface Props {
    options: IOption[];
    onChange: (key: number | string, value: number | string) => void;
    inputWidth?: string;
    iconColor?: "inherit" | "primary" | "disabled" | "error" | "action" | "secondary" | "info" | "success" | "warning";
    iconFontSize?: 'inherit' | 'large' | 'medium' | 'small';
}

export const InputSelect: FC<Props> = ({
    options = [],
    onChange,
    inputWidth = '100px',
    iconColor = 'primary',
    iconFontSize = 'medium'
}) => {
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState<IValues>({});

    useEffect(() => {
        if (open === true) {
            let initialValues: IValues = {}
            options.forEach(el => {
                if (el.key && el?.input?.defaultValue !== undefined) {
                    initialValues[el.key] = el?.input?.defaultValue;
                }
            })
            setValues(initialValues)
        }
    }, [open])

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: SelectChangeEvent) => {
        const key = event.target.value
        onChange(key, values[key])
        // setOpen(false);
    };

    function handleTextFieldClick (event: MouseEvent<HTMLDivElement>) {
        event.stopPropagation();
    };

    function handleItemClick(event: MouseEvent<HTMLLIElement>, key: (string | number), input: IInput) {
        let value = checkMinMaxValue(values[key], key, input)

        onChange(key, value)
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>, key: (string | number), input: IInput) => {
        if (event.key === 'Enter') {
            let value = checkMinMaxValue(values[key], key, input)

            setValues({...values, [key]: value})
            onChange(key, value)
            // setOpen(false);
        }
    };

    function checkMinMaxValue(value: string | number, key: (string | number), input: IInput) {
        // try {
            let newValue = getTypedValue(value, 'number', input?.min, input?.max)
            newValue = newValue === '' && input?.min ? input.min : value

            return newValue
        // } catch {}
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement>, key: (string | number), input: IInput) {
        try {
            const value = getTypedValue(event?.target?.value, 'number', input?.min, input?.max)

            setValues({...values, [key]: value as number})
        } catch {}
    }

    function handleInputBlur(event: FocusEvent<HTMLInputElement>, key: (string | number), input: IInput) {
        let value: string | number = event?.target?.value || ''

        value = checkMinMaxValue(value, key, input)
        
        setValues({...values, [key]: value})
    }

    return (
        <FormControl fullWidth>
            <AddCircle color={iconColor} fontSize={iconFontSize} onClick={handleOpen} />
            <Select
                open={open}
                labelId="simple-select-label"
                id="simple-select"
                value={''}
                onChange={handleChange}
                // onClose={handleClose}
                sx={{ visibility: 'hidden', height: 0, width: 0, overflow: 'hidden' }}
            >
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="input-select-menu-list" autoFocusItem>
                            {options.map((item: IOption) => (
                                <MenuItem
                                    divider
                                    key={item.key}
                                    value={item.key}
                                    sx={{display: 'flex', justifyContent: 'space-between'}}
                                    onClick={(e: MouseEvent<HTMLLIElement>) => {
                                        handleItemClick(e, item.key, (item.input as IInput))
                                    }}
                                >
                                    {item.label}

                                    {item.input && (
                                        <TextField
                                            size='small'
                                            style={{width: inputWidth, marginLeft: '50px'}}    
                                            label={item.input.label}
                                            value={values[item.key] ?? (item.input.defaultValue ?? '')}
                                            type={item.input.type || 'text'}
                                            variant="standard"
                                            onClick={handleTextFieldClick}
                                            onKeyDown={
                                                (e: KeyboardEvent<HTMLInputElement>) => {
                                                    handleKeyPress(e, item.key, (item.input as IInput))
                                                }
                                            }
                                            onChange={
                                                (e: ChangeEvent<HTMLInputElement>) => {
                                                    handleInputChange(e, item.key, (item.input as IInput))
                                                }
                                            }
                                            onBlur={
                                                (e: FocusEvent<HTMLInputElement>) => {
                                                    handleInputBlur(e, item.key, (item.input as IInput))
                                                }
                                            }
                                        />
                                    )}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Select>
        </FormControl>
    );
}
