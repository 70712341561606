import { AxiosResponse } from 'axios';
import ComponentService from 'services/ComponentService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    // GET_SINGLE_COMPONENT,
    GET_COMPONENTS_LIST,
    UPDATE_SINGLE_COMPONENT,
    CREATE_COMPONENT,
    DELETE_COMPONENT,
} from "store/types/componentTypes";

import { IError } from 'types/ErrorInterface';
import { IComponent } from "types/ComponentInterface"
import { IWorkerCallback, IWorkerCreate, IWorkerDelete, IWorkerUpdate } from 'types/SagaInterface';

import {
    setComponentsList,
    // setSingleComponent,
} from 'store/actions/componentAction';
import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';


function* createComponentWorker({payload}: IWorkerCreate<IComponent>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentService.create, data)
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback({status: 'success', success: true, data: response?.data})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Компонент створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Cтворити компонент не вдалося"], error as IError ))
    }
}

// function* getSingleComponentWorker({payload: id}: {type: string, payload: number}) {
//     try {
//         // const response: AxiosResponse = yield call(ComponentService.getSingle, id)
//         // if (response?.status === 200) {
//         //     yield put(setSingleComponent(response.data))
//         // }
//     } catch(error) {
//         yield put(setErrorNotifications( ["Отримати компонент не вдалося"], error as IError ))
//     }
// }

function* getComponentsListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setComponentsList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список компонентів не вдалося"], error as IError ))
    }
}

function* updateComponentWorker({payload}: IWorkerUpdate<IComponent>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentService.update, id, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Компонент оновлено"))
            }
        }
    } catch(error) {
        yield put(setErrorNotifications( ["Оновити компонент не вдалося"], error as IError ))
        
        if (callback instanceof Function) callback({status: 'error', success: false})
    }
}

function* deleteComponentWorker({payload}: IWorkerDelete) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentService.delete, id)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Компонент видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Видалити компонент не вдалося"], error as IError ))
    }
}

export function* componentWatcher() {
    yield takeLatest(UPDATE_SINGLE_COMPONENT, updateComponentWorker);
    yield takeLatest(CREATE_COMPONENT, createComponentWorker);
    // yield takeLatest(GET_SINGLE_COMPONENT, getSingleComponentWorker);
    yield takeLatest(GET_COMPONENTS_LIST, getComponentsListWorker);
    yield takeLatest(DELETE_COMPONENT, deleteComponentWorker);
}
