import { useDispatch } from "react-redux"
import { bindActionCreators, ActionCreatorsMapObject } from "redux"
import ActionCreators from 'store/actions/index'


export const useActions = (): ActionCreatorsMapObject => {
    const dispatch = useDispatch()
    
    return bindActionCreators(ActionCreators, dispatch)
    
    // const boundActionCreators = bindActionCreators(ActionCreators, dispatch)

    // const safeActions = new Proxy(boundActionCreators, {
    //     get(target: any, prop: any) {
    //         if (!(prop in target) || typeof target[prop] !== "function") {
    //             console.log(ActionCreators);
    //             // Handle the case when the action doesn't exist
    //             // throw new Error(`Action '${prop}' not found in ActionCreators`);
    //             dispatch({
    //                 type: 'SET_NOTIFICATIONS',
    //                 payload: [{
    //                     id: 'action-not-exists',
    //                     variant: 'warning',
    //                     duration: 20000,
    //                     msg: `Action '${prop}' not found in ActionCreators`,
    //                 }]
    //             })
    //         }
    //         return target[prop];
    //     },
    // });

    // return safeActions;
}