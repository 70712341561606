import { FC, useEffect, useMemo, useState } from "react";

import { RadioButtonPaper } from "components/Select/RadioButtonPaper";

import { useTypedSelector } from "hooks/useTypedSelector";

import { ISeriesState } from "types/SeriesInterface";

import { Grid } from "@mui/material";

interface Props {
    value: number;
    onChange: (value: number) => void;
}

export const Step3: FC<Props> = ({ value, onChange }) => {
    const [ selectedSerialNumber, setSelectedSerialNumber ] = useState<number>(0)
    const { seriesData } = useTypedSelector<ISeriesState>(state => state.series)

    useEffect(() => {
        setSelectedSerialNumber(value)
    }, [value])

    const computedSerialNumbers = useMemo(() => {
        if (seriesData?.serial_numbers) {
            return seriesData.serial_numbers.map(item => ({
                key: item.serial_number_id,
                label: item.code
            }))
        }
        return []
    }, [seriesData])

    function handleSelectSerialNumber(value: string | number) {
        const serial_number_id = Number(value)
        setSelectedSerialNumber(serial_number_id)
        onChange(serial_number_id)
    }

    return (
        <Grid container spacing={3} sx={{height: '100%'}}>
            <Grid item xs={12} sx={{height: '100%'}}>
                {computedSerialNumbers.length > 0 &&
                    <RadioButtonPaper
                        title="Виберіть серійний номер:"
                        options={computedSerialNumbers}
                        grid={{xs: 6, sm: 4, md: 3}}
                        paper={{elevation: 3, style: {padding: '3px 10px'}}}
                        value={selectedSerialNumber}
                        onChange={handleSelectSerialNumber}
                    />
                }
            </Grid>
        </Grid>
    )
}