import { ChangeEvent, FC, useEffect, useState } from 'react';

import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Grid,
    Typography,
    Paper
} from '@mui/material';

interface IOption {
    key: string | number;
    label: string;
}

type ValueType = string | number
type OptionType = IOption

interface IObject {
    [key: string]: string | number | IObject
}

interface Props {
    title?: string;
    value: ValueType;
    options: OptionType[];
    grid?: {[key: string]: number};
    paper?: {[key: string]: string | number | IObject};
    onChange: (newValue: ValueType) => void;
}

export const RadioButtonPaper: FC<Props> = ({
    title,
    value,
    options = [],
    grid = null,
    paper = null,
    onChange
}) => {
    const [ localValue, setLocalValue ] = useState(value);
  
    const handleChange = (event: React.SyntheticEvent, checked: boolean) => {
        const newValue = (event.target as HTMLInputElement).value
        setLocalValue(newValue);
        onChange(newValue)
    };

    useEffect(() => {
        setLocalValue(value)
    }, [value])

    return (
        <>
            <Typography variant="subtitle1" color="primary" sx={{mb: 2}}>
                {title}
            </Typography>

            <Grid
                className='scroll-bar-primary-y'
                container
                spacing={2}
                pb={1} pr={1}
                sx={{ maxHeight: '100%', overflowY: 'auto' }}
            >
                {options.map((option: IOption, idx: number) =>
                    <Grid key={idx} item {...grid}>
                        <Paper
                            elevation={paper?.elevation ? paper.elevation as number : 3}
                            sx={paper?.style ? paper.style as IObject : {}}
                        >
                            <FormControlLabel
                                checked={localValue === option.key}
                                value={option.key}
                                control={<Radio/>}
                                label={option.label}
                                onChange={handleChange}
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
