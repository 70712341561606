import {
    SET_RECIPES_LIST,
    GET_RECIPES_LIST,
    SET_SINGLE_RECIPE,
    // GET_SINGLE_RECIPE,
    CREATE_RECIPE,
    UPDATE_SINGLE_RECIPE,
    DELETE_RECIPE,
    DELETE_RECIPE_COMPONENT,
} from "store/types/recipeTypes";

import { IPartDTO, IRecipeDTO } from "types/RecipeInterface";
import { ICallback } from "types/SagaInterface";

export const setRecipesList = (recipesList: IRecipeDTO[]) => ({
    type: SET_RECIPES_LIST,
    payload: recipesList,
});

export const getRecipesList = (callback: (success: boolean) => void) => ({
    type: GET_RECIPES_LIST,
    payload: {
        callback
    }
});

export const setSingleRecipe = (recipeData: IRecipeDTO) => ({
    type: SET_SINGLE_RECIPE,
    payload: recipeData
})

// export const getSingleRecipe = (recipeId: number) => ({
//     type: GET_SINGLE_RECIPE,
//     payload: recipeId
// })

export const createRecipe = (data: IRecipeDTO, callback: (res: ICallback) => void) => ({
    type: CREATE_RECIPE,
    payload: {
        data,
        callback,
    }
})

export const updateRecipe = (data: IRecipeDTO, callback: (success: boolean) => void) => ({
    type: UPDATE_SINGLE_RECIPE,
    payload: {
        data,
        callback,
    }
})

export const deleteRecipe = (product_type_id: number, parts: IPartDTO[], callback: (success: boolean) => void) => ({
    type: DELETE_RECIPE,
    payload: {
        product_type_id,
        parts,
        callback
    }
})

export const deleteRecipeComponent = (
    product_type_id: number,
    component_type_id: number,
    last_component: boolean,
    callback: (success: boolean) => void
) => ({
    type: DELETE_RECIPE_COMPONENT,
    payload: {
        product_type_id,
        component_type_id,
        last_component,
        callback
    }
})