import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { ITableHeader } from "types/TableInterface";
import {
    IUnconfirmedOrder,
    IUnconfirmedOrderState,
    IUnconfirmedOrderStatus,
} from "types/UnconfirmedOrderInterface";
import { IProductType, IProductTypeState } from "types/ProductTypeInterface";
import { ICallback } from "types/SagaInterface";
import { IButtonSelectOption } from "components/Button/ButtonSelect";

import { WRITE_ORDER } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";
import { getUnconfirmedOrderStatusColor } from "utils/helpers/getStatusColor";

const orderItemTableHeaders: ITableHeader[] = [
    { key: "order_item_id", label: "ID", type: 'text' },
    { key: "product_type", label: "Назва", type: 'text' },
]

interface IStatusAccumulator {
    options: IButtonSelectOption[];
    data: {[key: number]: {}};
}

interface Props {}

export const UnconfirmedOrders: FC<Props> = () => {
    const [ statusLoading, setStatusLoading ] = useState<boolean>(false)
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { productTypeList } = useTypedSelector<IProductTypeState>(state => state.product_type)
    const {
        unconfirmedOrdersList,
        unconfirmedOrderStatusList
    } = useTypedSelector<IUnconfirmedOrderState>(state => state.unconfirmed_order)

    const {
        getProductTypeList,
        getUnconfirmedOrdersList,
        updateUnconfirmedOrderStatus,
    } = useActions()

    const navigate = useNavigate()

    useEffect(() => {
        const promises: Promise<void>[] = [];

        promises.push(new Promise<void>((resolve) => getProductTypeList(() => resolve())));
        promises.push(new Promise<void>((resolve) => getUnconfirmedOrdersList(() => resolve())));

        Promise.all(promises).finally(() => setIsDataLoading(false))
    }, []);

    const [ searchParams ] = useSearchParams()
    const [ searchOrderId, setSearchOrderId ] = useState<number | null>(null);

    useEffect(() => {
        const orderIdParam = Number(searchParams.get("orderId")) || null;
        setSearchOrderId(orderIdParam);
    }, [searchParams]);

    function handleUnconfirmedOrderStatus(order_id: number, status_id: number | string) {
        setStatusLoading(true)
        updateUnconfirmedOrderStatus(order_id, status_id, (res: ICallback) => {
            setStatusLoading(false)
            if (res.success && typeof res.data === 'number') {
                navigate(`/dashboard/order/customer/confirmed?orderId=${res.data}`)
            }
        })
    }

    function getProductTypeLabels(list: number[]) {
        const output: string[] = []
        productTypeList.forEach((el: IProductType) => {
            if (el?.product_type && list.includes(el?.product_type_id)) output.push(el.product_type)
        })
        return output.join(', ')
    }

    const unconfirmedOrderStatusesAcc = useMemo(() => {
        return unconfirmedOrderStatusList.reduce((acc: IStatusAccumulator, item: IUnconfirmedOrderStatus) => {
            acc.data[item?.unconfirmed_status_id] = {
                label: item?.unconfirmed_status_name || '',
                value: item?.unconfirmed_status_id,
                color: getUnconfirmedOrderStatusColor(item?.unconfirmed_status_id)
            }
            acc.options.push({
                label: item?.unconfirmed_status_name || '',
                key: item?.unconfirmed_status_id,
                color: getUnconfirmedOrderStatusColor(item?.unconfirmed_status_id),
                selfExclude: true,
            })
            return acc
        }, {options: [], data: {}})
    }, [unconfirmedOrderStatusList])

    const computedTableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "project_kind", label: "Тип зброї", type: 'tooltip', action: (text: string) => getShortText(text, 8), settings: { filter: ['search'], sort: ['ASC', 'DESC'] }},
            { key: "last_name", label: "Прізвище", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "first_name", label: "Ім'я", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "position", label: "Посада", type: 'tooltip', action: getShortText, styles: {textAlign: 'center'}, settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "phone_number", label: "Номер телефону", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "unit_number", label: "Назва бригади", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            {
                key: "status",
                label: "Статус",
                type: authData?.policies.includes(WRITE_ORDER) ? 'select' : 'chip',
                styles: { buttonWidth: '100%', textAlign: 'center' },
                settings: {
                    filter: [{
                        key: 'button-list',
                        options: unconfirmedOrderStatusList?.map((item: IUnconfirmedOrderStatus) => ({
                                    label: item?.unconfirmed_status_name,
                                    key: item?.unconfirmed_status_id,
                                    btnColor: getUnconfirmedOrderStatusColor(item?.unconfirmed_status_id)
                                }))
                    }],
                    sort: ['ASC', 'DESC']
                }
            },
        ]
        // if ([WRITE_ORDER].some(el => authData?.policies.includes(el))) {
        //     output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        // }
        return output
    }, [unconfirmedOrderStatusList, authData?.policies])

    const buildTableData = useMemo(() => {
        let data = unconfirmedOrdersList

        if (searchOrderId) {
            data = unconfirmedOrdersList?.filter(el => searchOrderId === el?.id)
        }

        return data?.map((item: IUnconfirmedOrder, index: number) => {
            return {
                tableId: item?.id,
                table_row_key: `order_id_${item?.id}_${index}`,
                id: item?.id,
                project_kind: getProductTypeLabels(item?.project_kind),
                last_name: item?.last_name,
                first_name: item?.first_name,
                position: item?.position,
                phone_number: item?.phone_number,
                unit_number: item?.unit_number,
                status: authData?.policies.includes(WRITE_ORDER) ? {
                    options: unconfirmedOrderStatusesAcc.options,
                    color: getUnconfirmedOrderStatusColor(item?.status),
                    value: item?.status,
                    action: (status_id: (number | string)) => {
                        handleUnconfirmedOrderStatus( item?.id, status_id )
                    }
                } : unconfirmedOrderStatusesAcc.data[item?.status],
                rowSettings: {
                    loading: statusLoading,
                    toggleAccordion: searchOrderId === item?.id
                },
                accordionData: {
                    headers: orderItemTableHeaders,
                    tableData: item?.project_kind?.map((product_type_id: number, idx: number) => ({
                        tableId: product_type_id,
                        table_row_key: `product_type_id_${product_type_id}_${idx}`,
                        order_item_id: product_type_id,
                        product_type: productTypeList.find(el => el.product_type_id === product_type_id)?.product_type,
                        rowSettings: {
                            loading: statusLoading,
                        },
                    }))
                }
            }
        })
    }, [
        unconfirmedOrdersList,
        unconfirmedOrderStatusesAcc,
        orderItemTableHeaders,
        productTypeList,
        searchOrderId,
        statusLoading,
        authData?.policies
    ])

    return (
        <div className="customer">

            <DataLoading isDataLoading={isDataLoading}>

                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={computedTableHeaders}
                        tableData={buildTableData}
                        tableId="orders-table"
                        amount_per_page={12}
                        searchEntityId={searchOrderId}
                    />
                }

            </DataLoading>

            <Outlet/>
        </div>
    )
}