import { ElementType, FC } from "react";

import { TableHeader } from "components/Table/TableHead/TableHeader";
import { TableSortingFilter } from "components/Table/TableHead/TableSortingFilter";

import { IFilterData, ITableDataItem, ITableHeader, ITableItemStyles } from "types/TableInterface";

import { TableHead } from "@mui/material";

interface Props<T> {
    tableId: string;
    zIndex: number;
    tableData: ITableDataItem<T>[];
    headers: ITableHeader[];
    filterData: IFilterData;
    sortData: string[];
    setSortDataHandler: Function;
    setFilterDataHandler: Function;
    getHeaderStyles: (styles: ITableItemStyles | undefined) => void;
    StyledTableCell: ElementType;
}

export const TableHeadComponent: FC<Props<any>> = ({
    tableId,
    zIndex,
    tableData,
    headers,
    filterData,
    sortData,
    setSortDataHandler,
    setFilterDataHandler,
    getHeaderStyles,
    StyledTableCell,
}) => {

    return (
        <TableHead sx={{position: 'sticky', top: 0, zIndex}}>
            <TableHeader
                tableId={tableId}
                headers={headers}
                getHeaderStyles={getHeaderStyles}
                StyledTableCell={StyledTableCell}
            />

            
            {(tableData?.length > 1 && headers?.some(el => (el?.settings?.filter || el?.settings?.sort))) &&
                <TableSortingFilter
                    tableData={tableData}
                    headers={headers}
                    filterData={filterData}
                    sortData={sortData}
                    setSortData={setSortDataHandler}
                    setFilterData={setFilterDataHandler}
                />
            }
        </TableHead>
    )
}