import { ElementType, FC } from "react";
import { useNavigate } from "react-router-dom";

import { InputSelect } from "components/Select/InputSelect";

import { IActionItem, IOption, ITableHeader, ITableItemStyles } from "types/TableInterface";

import { AddCircle } from "@mui/icons-material";
import { TableRow } from "@mui/material";

interface Props {
    tableId: string;
    headers: ITableHeader[];
    getHeaderStyles: (styles: ITableItemStyles | undefined) => void;
    StyledTableCell: ElementType;
}

export const TableHeader: FC<Props> = ({tableId, headers, getHeaderStyles, StyledTableCell}) => {

    const navigate = useNavigate()

    function handleActionItem(actionItem: IActionItem) {
        if (actionItem?.action) actionItem.action(tableId)
        if (actionItem?.to) navigate(actionItem.to)
    }

    return (
        <TableRow>
            {headers?.map((header: ITableHeader) => (
                <StyledTableCell
                    sx={getHeaderStyles(header?.styles)}
                    key={header?.key}
                    align={header?.styles?.textAlign ?? 'inherit'}
                >
                    {header?.label && header.label}

                    {header?.headerActions !== undefined && header?.headerActions?.map((actionItem: IActionItem, index: number) =>
                        <div
                            style={{textAlign: header?.styles?.textAlign ?? 'inherit'}}
                            key={actionItem?.iconName + index}
                            className='action-icon'
                        >
                            {actionItem?.iconName === 'AddCircleDropdown' &&
                                <InputSelect
                                    options={actionItem?.options as IOption[]}
                                    onChange={(key, values) => actionItem?.action && actionItem.action(key, values)}
                                />
                            }
                            {actionItem?.iconName === 'AddCircle' &&
                                <AddCircle
                                    color={actionItem?.iconColor || 'primary'}
                                    fontSize={actionItem?.iconFontSize || 'medium'}
                                    onClick={handleActionItem.bind(null, actionItem)}
                                />
                            }
                        </div>
                    )}
                </StyledTableCell>
            ))}
        </TableRow>
    )
}