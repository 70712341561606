import $api from "services/http/ERP_API";
import { AxiosResponse } from "axios";

import { IRecipeDTO } from "types/RecipeInterface";

const path_name = '/receipt'

export default class RecipeService {

    static async create(data: IRecipeDTO): Promise<AxiosResponse<IRecipeDTO>> {
        return $api.post<IRecipeDTO>(`${path_name}`, data);
    }

    // static async getSingle(id: number): Promise<AxiosResponse<RecipeResponse>> {
    //     return $api.get<RecipeResponse>(`${path_name}/${id}`);
    // }

    static async getAll(): Promise<AxiosResponse<IRecipeDTO[]>> {
        return $api.get<IRecipeDTO[]>(`${path_name}`);
    }

    static async update(data: IRecipeDTO): Promise<AxiosResponse<IRecipeDTO>> {
        return $api.patch<IRecipeDTO>(`${path_name}`, data);
    }

    static async delete(product_type_id: number, component_type_id: number): Promise<AxiosResponse<void>> {
        return $api.delete<void>(`${path_name}/${product_type_id}/${component_type_id}`);
    }
}