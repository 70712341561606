import { FC, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import { DragDropContext, DropResult } from "react-beautiful-dnd";

import { DataLoading } from "components/Loader/DataLoading";
import { DraggableCardList } from "components/DragAndDrop/DraggableCardList";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { IChip } from "types/MaterialInterface";
import { ICallback } from "types/SagaInterface";
import {
    IProductDTO,
    IProductState,
} from "types/ProductInterface";

import { WRITE_PRODUCTS } from "store/types/policiesTypes";

import { getProductStatusColor } from "utils/helpers/getStatusColor";

import { Box } from "@mui/material";

const reorder = (
    list: Item[],
    startIndex: number,
    endIndex: number
): Item[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

interface Item {
    id: number;
    content: string;
    chip: IChip;
    isDisabled?: boolean;
}

interface ListState {
    [key: string]: Item[]
}

interface Props {}

export const ConstructionDnD: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)
    const { productsList } = useTypedSelector<IProductState>(state => state.product)
    const { productStatusList } = useTypedSelector<IProductState>(state => state.product)
    const [ dataLists, setDataLists ] = useState<ListState>({});

    const {
        setProductsList,
        getProductsByStatusIds,
        setProductStatus,
        getProductStatusList,
    } = useActions()

    useEffect(() => {
        getProductStatusList()
        setProductsList([])
        getProductsByStatusIds([1,2,3], () => {
            setIsDataLoading(false)
        })
    }, [])

    // const navigate = useNavigate()

    const handleSetProductStatus = (id: number, status: number, callback: Function) => {
        setProductStatus(
            id,
            status,
            (res: ICallback) => {
                if (res.success) getProductsByStatusIds([1,2,3])
                callback(res)
            }
        );
    }

    const productStatusName = useMemo(() => {
        return productStatusList.reduce((acc: any, el) => {
            acc[el.status_id] = el.status_title
            return acc
        }, {})
    }, [productStatusList])

    useEffect(() => {
        const output: ListState = [...productsList]
        .reduce((acc: any, el: IProductDTO) => {
            const data = {
                id: el.product_id,
                content: el?.product_type.product_type,
                isDisabled: !authData?.policies.includes(WRITE_PRODUCTS),
                chip: {
                    label: productStatusName[el?.product_status_id],
                    color: getProductStatusColor(el?.product_status_id)
                }
            }
            if (el?.product_status_id === 1) acc?.['1'].push(data)
            if (el?.product_status_id === 2) acc?.['2'].push(data)
            if (el?.product_status_id === 3) acc?.['3'].push(data)
            return acc
        }, {
            '1': [],
            '2': [],
            '3': [],
        })
        console.log(output)
        setDataLists(output)
    }, [productsList, productStatusName, authData?.policies])

    const onDragEnd = (result: DropResult) => {
        console.log(result)
        const {
            source,
            destination,
            draggableId,
        } = result;

        if (!destination) return;

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                dataLists[source.droppableId],
                source.index,
                destination.index
            );

            setDataLists({ ...dataLists, [source.droppableId]: items });
        } else {
            const temp = JSON.parse(JSON.stringify(dataLists))
            handleSetProductStatus(
                Number(draggableId),
                Number(destination.droppableId),
                (res: ICallback) => {
                    if (!res?.success) setDataLists({...temp})
                }
            )
            const sourceList = dataLists[source.droppableId];
            const destList = dataLists[destination.droppableId] ?? [];
            const [removed] = sourceList.splice(source.index, 1);

            destList.splice(destination.index, 0, removed);

            const data = {
                ...dataLists,
                [destination.droppableId]: destList,
            };
            if (sourceList.length > 0)
                data[source.droppableId] = sourceList;
            else delete data[source.droppableId];

            setDataLists({ ...data });
        }
    }

    return (
        <div className="products">

            <DataLoading isDataLoading={isDataLoading}>

                <DragDropContext onDragEnd={onDragEnd}>

                    <Box sx={{display: 'flex', gap: '25px', height: '750px'}}>

                        {Object.keys(dataLists).map((key: string) => (
                            <DraggableCardList
                                key={key}
                                list={dataLists?.[(key)]}
                                listId={key}
                                data={{
                                    title: productStatusName?.[key],
                                    subtitle: String(dataLists?.[key]?.length),
                                }}
                                showScrollBar={true}
                                style={{maxHeight: "100%", width: '100%'}}
                            />
                        ))}
                    </Box>

                </DragDropContext>

            </DataLoading>

            <Outlet/>
        </div>
    )
}