import { SET_SOFTWARE_LIST } from "store/types/softwareTypes"
import { ISoftwareState, SoftwareAction } from "types/SoftwareInterface"

const initialState: ISoftwareState = {
    softwareList: [],
}

export const softwareReducer = (state = initialState, {type, payload}: SoftwareAction): ISoftwareState => {
    switch (type) {
        case SET_SOFTWARE_LIST:
            return { ...state, softwareList: payload }
        default:
            return state
    }
}