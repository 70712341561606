import { SET_SERIES_LIST, SET_SINGLE_SERIES, SET_AVAILABLE_SERIES } from "store/types/seriesTypes"
import { ISeriesDTO, ISeriesState, SeriesAction } from "types/SeriesInterface"

const initialState: ISeriesState = {
    seriesData: {} as ISeriesDTO,
    seriesList: [],
    availableSeries: [],
}

export const seriesReducer = (state = initialState, {type, payload}: SeriesAction): ISeriesState => {
    switch (type) {
        case SET_SINGLE_SERIES:
            return { ...state, seriesData: payload }
        case SET_SERIES_LIST:
            return { ...state, seriesList: payload }
        case SET_AVAILABLE_SERIES:
            return { ...state, availableSeries: payload }
        default:
            return state
    }
}