import { FC, Fragment, ReactNode, useMemo } from "react";
import { NavLink } from "react-router-dom";

import { Typography } from "@mui/material";

interface Props {
    entity: string;
    dataLength: number;
    loadingFailed: boolean;
    to: string;
    gender?: string
    children: ReactNode;
}

export const EmptyDataInfo: FC<Props> = ({
    entity,
    dataLength,
    loadingFailed = false,
    to,
    gender = 'm',
    children
}) => {

    const available = useMemo(() => {
        let output = ''
        switch (gender) {
            case 'm': output = 'доступного'
                break
            case 'f': output = 'доступної'
                break
        }
        return output
    }, [gender])

    return (
        <Fragment>
            {dataLength > 0
                ?
                    children
                :
                <Typography variant="subtitle1" color="error" gutterBottom>
                    {loadingFailed
                        ?
                        <>Виникла помилка при завантаженні даних</>
                        :
                        <>
                            Не знайдено {available} {entity}. <br/>
                            Для створення перейдіть, будь ласка, за цим посиланням -&nbsp;
                            <NavLink to={to}>
                                створення {entity}
                            </NavLink>
                        </>
                    }
                </Typography>
            }
        </Fragment>
    )
}