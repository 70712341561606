import {
    CHANGE_APP_STATUS,
    GET_OPERATORS_LIST,
    SET_OPERATORS_LIST,
} from "store/types/operatorTypes";

import { OperatorDTO } from "types/OperatorInterface";

export const setOperatorsList = (operatorsList: OperatorDTO[]) => ({
    type: SET_OPERATORS_LIST,
    payload: operatorsList,
});

export const getOperatorsList = (callback: (success: boolean) => void) => ({
    type: GET_OPERATORS_LIST,
    payload: {
        callback
    }
});

export const changeAppStatus = (data: {app_id: number, activationStatus: number}, callback: (success: boolean) => void) => ({
    type: CHANGE_APP_STATUS,
    payload: {
        data,
        callback,
    }
})