import { LoginForm } from "components/Auth/LoginForm";
import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";
import { FC, useEffect, useState } from "react";
import { IAuthState } from "types/AuthInterface";

import './login.scss'

interface Props {}

export const Login: FC<Props> = () => {
    const { isAuth, isLoading } = useTypedSelector<IAuthState>(state => state.auth)
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);

    const { loginAction, checkAuthAction } = useActions()

    const checkFn = async () => {
        await checkAuthAction()
        setContentLoaded(true);
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            checkFn()
        } else {
            setContentLoaded(true);
        }
    }, [])

    if (isLoading) {
        return <div>...loading</div>
    }

    if (!contentLoaded) {
        return null;
    }

    if (!isAuth) {
        return (
            <div className="login">
                <LoginForm loginAction={loginAction}/>
            </div>
        )
    }

    return null
}