import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { ITableHeader } from "types/TableInterface";
import { IPartDTO, IRecipeDTO, IRecipeState } from "types/RecipeInterface";

import { READ_WAREHOUSE, WRITE_WAREHOUSE } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";

import { AddCircle } from '@mui/icons-material';
import { IconButton } from "@mui/material";

interface Props {}

export const Recipes: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { recipesList } = useTypedSelector<IRecipeState>(state => state.recipe)
    const [ recipesListIsLoaded, setRecipesListIsLoaded ] = useState<boolean>(false)
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);

    const { getRecipesList, deleteRecipe } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    const [ searchParams ] = useSearchParams()
    const [ searchProductTypeId, setSearchProductTypeId ] = useState<number | null>(null);

    useEffect(() => {
        const idParam = Number(searchParams.get("productTypeId")) || null;
        setSearchProductTypeId(idParam);
    }, [searchParams]);

    function deleteRecipeHandler(id: number, parts: IPartDTO[]) {
        setStatusLoading(true)
        deleteRecipe(
            id,
            parts,
            (success: boolean) => {
                if (success) getRecipesList(() => setStatusLoading(false))
                else setStatusLoading(false)
            }
        );
    }

    const tableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "product_type_id", label: "ID типу продукту", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "name", label: "Назва", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
        ]
        if ([WRITE_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [authData?.policies])
    
    const componentTableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "component_type_id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "name", label: "Назва", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "notes", label: "Примітка", type: 'text', action: getShortText, settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "quantity", label: "Кількість", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
        ]
        if ([READ_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [authData?.policies])

    const buildTableData = useMemo(() => {
        let data = recipesList

        if (searchProductTypeId) {
            data = recipesList?.filter(el => searchProductTypeId === el?.product_type_id)
        }

        return data?.map((item: IRecipeDTO, index: number) => {
            return {
                tableId: item?.product_type_id,
                table_row_key: `product_type_id_${item?.product_type_id}_${index}`,
                name: item?.name,
                product_type_id: item?.product_type_id,
                rowSettings: {
                    loading: statusLoading,
                },
                actions: authData?.policies.includes(WRITE_WAREHOUSE) ? [
                    {
                        iconName: "Edit",
                        action: () => {
                            if (item?.product_type_id) {
                                navigate(`/dashboard/warehouse/recipes/edit/${item.product_type_id}${location.search}`)
                            }
                        },
                        disabled: item?.isBusy,
                        iconColor: item?.isBusy && 'disabled',
                        tooltip: item?.isBusy ? 'Рецепт у використанні' : 'Редагувати',
                    },
                    {
                        iconName: "Delete",
                        confirmAction: () => deleteRecipeHandler(item?.product_type_id, item?.parts),
                        confirmationMsg: `Ви впевнені що хочете видалити рецепт?`,
                        disabled: item?.isBusy,
                        iconColor: item?.isBusy && 'disabled',
                        tooltip: item?.isBusy ? 'Рецепт у використанні' : 'Видалити',
                    },
                ] : [],
                accordionData: {
                    headers: componentTableHeaders,
                    tableData: item?.parts?.map((el: IPartDTO, idx: number) => ({
                        ...el,
                        tableId: el?.component_type_id,
                        table_row_key: `component_type_id_${el?.component_type_id}_${idx}`,
                        rowSettings: {
                            loading: statusLoading,
                        },
                        actions: authData?.policies.includes(READ_WAREHOUSE) ? [
                            {
                                iconName: "Launch",
                                action: () => {
                                    if (el?.component_type_id) navigate(`/dashboard/warehouse/components?componentId=${el.component_type_id}`)
                                },
                                tooltip: 'Перейти до компонента',
                            },
                        ] : []
                    }))
                }
            }
        })
    }, [recipesList, statusLoading, searchProductTypeId, authData?.policies])

    function toCreateRecipe() {
        navigate(`/dashboard/warehouse/recipes/create${location.search}`)
    }

    useEffect(() => {
        getRecipesList((success: boolean) => {
            if(success) setRecipesListIsLoaded(true)
            setIsDataLoading(false)
        })
    }, [])

    return (
        <div className="Recipes">
            {authData?.policies.includes(WRITE_WAREHOUSE) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateRecipe}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 && 
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId="recipes-list-table"
                        searchEntityId={searchProductTypeId}
                    />
                }
            </DataLoading>

            {recipesListIsLoaded && <Outlet/>}
        </div>
    )
}