import { FC, useMemo} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal, INumberRange } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IOrderState, ISingleOrderItem } from 'types/OrderInterface';

const orderDataLabels: {[key: string]: string} = {
    quantity: 'Кількість',
    shipping_address: 'Адреса',
    submit: 'Зберегти'
}

const dataTypes: {[key: string]: string} = {
    quantity: 'number',
}

const fieldsTypes: {[key: string]: string} = {
    quantity: 'number',
    shipping_address: 'text',
}

const grid: {[key: string]: number} = {
    quantity: 4,
    shipping_address: 8,
}

const order: {[key: string]: number} = {
    quantity: 1,
    shipping_address: 2,
}

const numberRange: INumberRange = {
    quantity: {min: 1},
}

const requiredFields: Array<string> = ['shipping_address', 'quantity']

export const EditOrderItem: FC = () => {
    const { stateOrderItem } = useTypedSelector<IOrderState>(state => state.order)

    const { updateOrderItem, getOrdersList, getSingleOrderItem } = useActions()

    const initialOrderItemData: ISingleOrderItem = useMemo(() => {
        return {
            quantity: stateOrderItem.quantity,
            shipping_address: stateOrderItem.shipping_address,
        }
    }, [stateOrderItem])

    const params = useParams();
    const location = useLocation();

    return (
        <SubmitEntityModal
            title="Редагувати замовлення"
            order={order}
            grid={grid}
            dataTypes={dataTypes}
            fieldsTypes={fieldsTypes}
            numberRange={numberRange}
            initialData={initialOrderItemData}
            requiredFields={requiredFields}
            labels={orderDataLabels}
            backRoute={`/dashboard/order/customer/confirmed${location.search}`}
            isDataExists={true}
            getEntityActionOnMount={getSingleOrderItem.bind(null, params.orderItemId)}
            submitEnityAction={updateOrderItem.bind(null, params.orderItemId)}
            getEntityActionOnSubmit={getOrdersList}
        />
    )
}