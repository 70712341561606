import { FC, ReactNode } from "react";
import { styled } from "@mui/material";

interface MainProps {
    open?: boolean;
    width: number
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProps>(({ theme, open, width }) => {
    return {
        marginTop: '64px',
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: `-${width}px`,
        width: '100%',
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            width: `calc(100% - ${width}px)`,
            marginLeft: `${width}px`,
            // marginLeft: 0,
        }),
    }
});

interface Props {
    children: ReactNode
    drawerWidth: number
    isOpened: boolean
}

export const DashboardContent: FC<Props> = ({children, isOpened, drawerWidth }) => {
    return (
        <Main open={isOpened} width={drawerWidth}>
            {children}
        </Main>
    )
}