import { SET_IS_AUTH, SET_AUTH_IS_LOADING, SET_AUTH_DATA } from "store/types/authTypes"
import { IAuthState, AuthAction } from "types/AuthInterface"

const initialState: IAuthState = {
    isLoading: false,
    isAuth: false,
    authData: null,
}

export const authReducer = (state = initialState, {type, payload}: AuthAction): IAuthState => {
    switch (type) {
        case SET_AUTH_IS_LOADING:
            return { ...state, isLoading: payload }
        case SET_IS_AUTH:
            return { ...state, isAuth: payload }
        case SET_AUTH_DATA:
            return { ...state, authData: payload }
        default:
            return state
    }
}