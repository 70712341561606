import $api from "services/http/RSFS_API";
import { AxiosResponse } from "axios";

import {
  IApplicationDTO,
  IFileUpload,
  IFirmwareDTO,
} from "types/SoftwareInterface";

export default class SoftwareService {
  static async getApplications(): Promise<AxiosResponse<IApplicationDTO[]>> {
    return $api.get<IApplicationDTO[]>(`/admin-available-applications`);
  }

  static async getFirmwares(): Promise<AxiosResponse<IFirmwareDTO[]>> {
    return $api.get<IFirmwareDTO[]>(`/admin-available-firmwares`);
  }

  static async downloadApplication(id: number): Promise<AxiosResponse<string>> {
    return $api.get<string>(`/admin-application-data/${id}`);
  }

  static async downloadFirmware(id: number): Promise<AxiosResponse<string>> {
    return $api.get<string>(`/admin-firmware-data/${id}`);
  }

  // TODO: use without interceptors
  static async uploadFile(url: string, file: File): Promise<void> {
    try {
      const response = await $api.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      console.log("File uploaded successfully:", response.status);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }

  static async uploadTwoStep(
    data: IFileUpload,
    initialURL: string,
    finalURL: string
  ): Promise<AxiosResponse<any>> {
    // const initialURL = "/app-initial-multipart-upload";
    // const finalURL = "/app-finalize-multipart-upload";

    const initialResponse = await $api.post<{ url: string; guid: string }>(
      initialURL,
      {
        title: data.title,
      }
    );

    const file = data.file as File;

    const { url, guid } = initialResponse.data;

    await SoftwareService.uploadFile(url, file);

    const response = await $api.post(finalURL, {
      title: data.title,
      version: data.version,
      guid,
    });

    return response.data;
  }

  static async uploadApplication(
    data: IFileUpload
  ): Promise<AxiosResponse<IApplicationDTO>> {
    const initialURL = "/app-initial-multipart-upload";
    const finalURL = "/app-finalize-multipart-upload";

    return await SoftwareService.uploadTwoStep(data, initialURL, finalURL);
  }

  static async uploadFirmware(
    data: IFileUpload
  ): Promise<AxiosResponse<IApplicationDTO>> {
    // const formData: FormData = new FormData();
    // if (data.file) formData.append("file", data.file);
    // if (data.title) formData.append("title", data.title);
    // if (data.version) formData.append("version", data.version);

    // return $api.post<IApplicationDTO>(`/upload-firmware`, formData);

    const initialURL = "/firmware-initial-multipart-upload";
    const finalURL = "/firmware-finalize-multipart-upload";

    return await SoftwareService.uploadTwoStep(data, initialURL, finalURL);
  }
}
