import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { FileUploadInput } from 'components/Input/FileUploadInput';

import { useActions } from 'hooks/useActions';

import { IFileUpload } from 'types/SoftwareInterface';

import {
    DialogTitle,
    DialogContent,
    DialogActions,
    Dialog,
    TextField,
    Grid,
    Button
} from '@mui/material';

const initialData: Partial<IFileUpload> = {
    // file: '',
    title: '',
    version: ''
}

const inputFields: string[] = ['title', 'version']

const requiredFields: string[] = ['file', 'title', 'version']

const fileDataLabels: {[key: string]: string}  = {
    file: 'Виберіть файл',
    title: 'Назва',
    version: 'Версія',
}

const inputTypes: {[key: string]: string} = {
    // file: 'file',
    title: 'text',
    version: 'text',
}

interface Props {}

export const UploadSoftware: FC<Props> = () => {
    const [ updateBtnDisabled, setUpdateBtnDisabled ] = useState<boolean>(true)
    const [ uploadData, setUploadData ] = useState<Partial<IFileUpload>>(initialData)
    const [ fileState, setFileState ] = useState<File | null>(null);

    const { title, entity }: {[key: string]: string} = useOutletContext();

    const { uploadSoftware, uploadFirmware, getSoftwareList } = useActions()

    const navigate = useNavigate()
    const closeModal = () => {
        navigate(`/dashboard/rsfs/${entity}`)
    };
    
    const isFieldsValidated = useMemo(() => {
        return requiredFields.some(key => uploadData[key as keyof IFileUpload] && key !== 'file')
    }, [uploadData])

    useEffect(() => {
        const disabled: boolean = isFieldsValidated && fileState instanceof File

        setUpdateBtnDisabled(!disabled)
    }, [isFieldsValidated, fileState]);

    function setFieldValue(event: ChangeEvent<HTMLInputElement>, key: string) {
        switch (inputTypes[key]) {
            case 'number': setUploadData({...uploadData, [key]: Number(event.target.value)});
                break;
            default: setUploadData({...uploadData, [key]: event.target.value});
        }
    }

    function handleFileChange(file: File) {
        if (file) setFileState(file);

        if (!uploadData.title && file?.name) {
            setUploadData({...uploadData, title: file.name});
        }
    }

    function createComponentHandler() {
        setUpdateBtnDisabled(true)

        const fileData: IFileUpload = {
            file: fileState as File,
            title: uploadData.title as string,
            version: uploadData.version as string
        } 

        uploadSoftware(
            fileData,
            entity,
            (success: boolean) => {
                if(success) {
                    getSoftwareList(entity)
                    closeModal()
                }
                setUpdateBtnDisabled(false)
        })
    }

    return (
        <Dialog
            open={true}
            onClose={closeModal}
            maxWidth="md"
            fullWidth={true}
        >
            <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>

            <DialogContent dividers>

                <Grid container spacing={2}>

                    <Grid item xs={12}>
                        <FileUploadInput
                            label={fileDataLabels.file}
                            required={requiredFields.includes('file')}
                            error={!(fileState instanceof File)}
                            onChange={(file: File) => handleFileChange(file)}
                        />
                    </Grid>

                    {Object.entries(uploadData).map(([key, value]) => (
                        inputFields.includes(key) && (
                            <Grid key={key} item xs={12} sm={6}>
                                <TextField
                                    label={fileDataLabels[key]}
                                    variant="outlined"
                                    sx={{width: '100%'}}
                                    value={value}
                                    type={inputTypes[key]}
                                    onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue(event, key)}
                                    required={requiredFields.includes(key)}
                                    error={requiredFields.includes(key) && !value}
                                    helperText={(requiredFields.includes(key) && !value) && "Поле обов'язкове"}
                                />
                            </Grid>
                        )
                    ))}
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="outlined" onClick={closeModal}>Закрити</Button>
                <Button variant="contained" onClick={createComponentHandler} disabled={updateBtnDisabled}>
                    Завантажити
                </Button>
            </DialogActions>
        </Dialog>
    )
}