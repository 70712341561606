import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IComponent, IComponentState } from 'types/ComponentInterface';

const labels: {[key: string]: string} = {
    name: 'Назва',
    note: 'Примітка',
    submit: 'Зберегти',
}

const initialData = {
    name: '',
    note: '',
}

const fieldsTypes: {[key: string]: string} = {
    name: 'text',
    note: 'text',
}

const inputRows: {[key: string]: number} = {
    note: 6
}

const grid: {[key: string]: number} = {
    name: 12,
    note: 12,
}

const order: {[key: string]: number} = {
    name: 1,
    note: 2,
}

const requiredFields: Array<string> = ['name']

export const EditComponent: FC = () => {
    const { componentsList } = useTypedSelector<IComponentState>(state => state.component)
    const [ componentData, setComponentData ] = useState<Partial<IComponent>>(initialData)

    const { updateComponent, getComponentsList } = useActions()

    const params = useParams()
    const location = useLocation()

    useEffect(() => {
        const componentInstance = componentsList.find(el => el.id === Number(params.componentId)) || null
        if (componentInstance) {
            setComponentData({
                name: componentInstance?.name || '',
                note: componentInstance?.note || '',
            })
        }
    }, [componentsList, params])

    return (
        <SubmitEntityModal
            title={`Редагувати компонент ${params.componentId}`}
            order={order}
            grid={grid}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            initialData={componentData}
            requiredFields={requiredFields}
            labels={labels}
            backRoute={`/dashboard/warehouse/components${location.search}`}
            isDataExists={true}
            submitEnityAction={updateComponent.bind(null, Number(params.componentId))}
            getEntityActionOnSubmit={getComponentsList}
        />
    )
}