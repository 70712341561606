import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { RSFS } from 'pages/Dashboard/RSFS/RSFS';

import { Operator } from 'pages/Dashboard/RSFS/Operator/Operator';

import { Software } from 'pages/Dashboard/RSFS/Software/Software';
import { UploadSoftware } from "pages/Dashboard/RSFS/Software/UploadSoftware";

interface Props {}

export const RSFSRouter: FC<Props> = () => {
    return (
        <Routes>
            <Route path="/" element={<RSFS/>}>
                <Route path="operator" element={<Operator/>}/>
                <Route path="application" element={<Software/>}>
                    <Route path="upload" element={<UploadSoftware/>}/>
                </Route>
                <Route path="firmware" element={<Software/>}>
                    <Route path="upload" element={<UploadSoftware/>}/>
                </Route>
            </Route>
        </Routes>
    )
}