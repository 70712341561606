import {
    CREATE_PRODUCT_TYPE,
    // GET_SINGLE_PRODUCT_TYPE,
    SET_SINGLE_PRODUCT_TYPE,
    GET_PRODUCT_TYPE_LIST,
    SET_PRODUCT_TYPE_LIST,
    UPDATE_PRODUCT_TYPE,
    DELETE_PRODUCT_TYPE,
} from "store/types/productTypeTypes";

import {
    IProductType,
    IProductTypeCreate,
    IProductTypeUpdate
} from "types/ProductTypeInterface";

import { ICallback } from "types/SagaInterface";

export const createProductType = (
    data: IProductTypeCreate,
    callback: (res: ICallback) => void
) => ({
    type: CREATE_PRODUCT_TYPE,
    payload: {
        data,
        callback,
    }
})

// export const getSingleProductType = (productTypeId: number) => ({
//     type: GET_SINGLE_PRODUCT_TYPE,
//     payload: productTypeId
// })

export const setSingleProductType = (data: IProductType) => ({
    type: SET_SINGLE_PRODUCT_TYPE,
    payload: data
})

export const getProductTypeList = (callback: (res: ICallback) => void) => ({
    type: GET_PRODUCT_TYPE_LIST,
    payload: {
        callback
    }
});

export const setProductTypeList = (list: IProductType[]) => ({
    type: SET_PRODUCT_TYPE_LIST,
    payload: list,
});

export const updateProductType = (
    id: number,
    data: IProductTypeUpdate,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_PRODUCT_TYPE,
    payload: {
        id,
        data,
        callback,
    }
})

export const deleteProductType = (id: number, callback: (res: ICallback) => void) => ({
    type: DELETE_PRODUCT_TYPE,
    payload: {
        id,
        callback
    }
})