import { NotificationItem } from 'components/Modal/NotificationItem';

import { useTypedSelector } from 'hooks/useTypedSelector';

import { INotificationState } from 'types/NotificationInterface';

import './notification.scss'

export const Notification = () => {
    const { notifications } = useTypedSelector<INotificationState>(state => state.notification)

    return (
        <div className="notification">
            {notifications.map(item =>
                <NotificationItem
                    key={item.id}
                    id={item.id}
                    duration={item.duration && item.duration}
                    severity={item.severity}
                    bgColor={item.bgColor as "success" | "error" | "warning" | "info"}
                    msg={item.msg}
                    btn={item.btn ?? null}
                />
            )}
        </div>
    )
}
