import { FC, useEffect, useState } from "react";

import { BasicSelect } from "components/Select/BasicSelect";

import { Chip, Box, Paper } from '@mui/material';

import { IFilterData, ITableHeader } from "types/TableInterface";

const basicSelectOptions = [
    { key: 10, label: 10 },
    { key: 20, label: 20 },
    { key: 50, label: 50 },
    { key: 100, label: 100 },
]

export interface IHeaderLabels {
    [key: string]: string
}

interface Props {
    sortData: string[];
    filterData: IFilterData;
    headers: ITableHeader[];
    has_pagination: boolean;
    per_page: number;
    setPerPage: Function;
    updateSortData: (value: string[]) => void;
    updateFilterData: (value: IFilterData) => void;
}

export const TableFilterLabels: FC<Props> = ({
    sortData,
    filterData,
    headers,
    has_pagination,
    per_page,
    setPerPage,
    updateSortData,
    updateFilterData,
}) => {

    // const [ headerLabels, setHeaderLabels ] = useState<IHeaderLabels>({})
    const [ labels, setLabels ] = useState<IHeaderLabels>({})

    function getChipLabel(key: string) {
        const keys = key.split('|')
        let label = labels[keys[0]] + ': '
        const value = filterData[key] as string | number | number[]
        if (['number', 'string'].includes(typeof value)) label = label + value
        else if (Array.isArray(value)) label = label + `${value[0]} - ${value[1]}`
        return label
    }

    useEffect(() => {
        let labels: IHeaderLabels = {}
        headers?.forEach((item: ITableHeader) => {
            if (item?.key && item?.label) {
                labels = Object.assign({}, labels, { [item?.key]: item?.label });
            }
        })

        setLabels({...labels})
    }, [headers])

    return (
        <div className="">

            {/* {( has_pagination || (!has_pagination && sortData.length > 0) ) && ( */}
            {sortData.length > 0 && (
                <Paper
                    elevation={5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 2,
                        marginBottom: 3
                    }}
                >
                    {/* {has_pagination &&
                        <BasicSelect
                            sx={{width: '140px', marginRight: '25px'}}
                            size="small"
                            label="Кількість на сторінці"
                            value={per_page}
                            options={basicSelectOptions}
                            onChange={(s: string) => setPerPage(Number(s))}
                        />
                    } */}

                    {sortData.length > 0 && (
                        <Box>
                            Сортування: &nbsp;
                            <Chip label={`${labels[sortData[0]]}: ${sortData[1]}`} onDelete={() => updateSortData([])}/>
                        </Box>
                    )}
                </Paper>
            )}

            {Object.keys(filterData).length > 0 &&
                <Paper
                    elevation={5}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                        marginBottom: 3
                    }}
                >
                    <Box>
                        {Object.entries(filterData).map(([key, value]) => (
                            <Chip
                                sx={{margin: 1}}
                                key={key}
                                label={getChipLabel(key)}
                                onDelete={() => {
                                    delete filterData[key]
                                    updateFilterData({...filterData})
                                }}
                            />
                        ))}
                        {Object.keys(filterData).length > 0 &&
                            <Chip label="Очистити фільтр" color='error' onClick={() => updateFilterData({})} />
                        }
                    </Box>
                </Paper>
            }
        </div>
    )
}