import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { IProductType, IProductTypeState } from "types/ProductTypeInterface";
import { ITableHeader } from "types/TableInterface";
import { ICallback } from "types/SagaInterface";

import { WRITE_WAREHOUSE } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";

import { AddCircle } from '@mui/icons-material';
import { IconButton } from "@mui/material";

interface Props {}

export const ProductTypes: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { productTypeList } = useTypedSelector<IProductTypeState>(state => state.product_type)
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);

    const {
        getProductTypeList,
        deleteProductType,
    } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    function toCreateProductType() {
        navigate(`/dashboard/warehouse/product-types/create${location.search}`)
    }

    const [ searchParams ] = useSearchParams()
    const [ searchProductTypeId, setSearchProductTypeId ] = useState<number | null>(null);

    useEffect(() => {
        const idParam = Number(searchParams.get("productTypeId")) || null;
        setSearchProductTypeId(idParam);
    }, [searchParams]);

    function deleteProductTypeHandler(id: number) {
        setStatusLoading(true)
        deleteProductType(
            id,
            (res: ICallback) => {
                if (res.success) getProductTypeList(() => setStatusLoading(false))
                setStatusLoading(false)
            }
        );
    }

    const tableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            {
                key: "product_type_id", label: "ID", type: 'text', styles: {cellMaxWidth: '1%'},
                settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] }
            }, {
                key: "product_type", label: "Назва", type: 'text',
                settings: { filter: ['search'], sort: ['ASC', 'DESC'] }, styles: {cellMaxWidth: '2%', cellMinWidth: '150px'}
            }, {
                key: "required_quantity", label: "Необхідно", type: 'text',
                settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] }, styles: {cellMaxWidth: '1%', cellMinWidth: '20px'}
            }, {
                key: "note", label: "Примітка", type: 'tooltip', action: (t: string) => getShortText(t, 130),
                settings: { filter: ['search'], sort: ['ASC', 'DESC'] }, styles: {cellMaxWidth: '50%'}
            },
        ]
        if ([WRITE_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right', cellMaxWidth: '1%'} })
        }
        return output
    }, [authData?.policies])

    const buildTableData = useMemo(() => {
        let data = productTypeList

        if (searchProductTypeId) {
            data = productTypeList?.filter(el => searchProductTypeId === el?.product_type_id)
        }

        return data?.map((item: IProductType, index: number) => {
            return {
                tableId: item?.product_type_id,
                table_row_key: `product_type_id_${item?.product_type_id}_${index}`,
                product_type_id: item?.product_type_id,
                product_type: item?.product_type || '',
                note: item?.note || '',
                required_quantity: item?.required_quantity || '',
                rowSettings: {
                    loading: statusLoading,
                },
                actions: authData?.policies.includes(WRITE_WAREHOUSE) ? [
                    {
                        iconName: "Edit",
                        action: () => {
                            if (item?.product_type_id) {
                                navigate(`/dashboard/warehouse/product-types/edit/${item.product_type_id}${location.search}`)
                            }
                        },
                        tooltip: 'Редагувати',
                    },
                    {
                        iconName: "Delete",
                        confirmAction: () => deleteProductTypeHandler(item?.product_type_id),
                        confirmationMsg: `Ви впевнені що хочете видалити тип продукту ID ${item?.product_type_id}?`,
                        disabled: item?.is_busy,
                        iconColor: item?.is_busy && 'disabled',
                        tooltip: item?.is_busy ? 'Тип продукту у використанні' : 'Видалити',
                    },
                ] : []
            }
        })
    }, [productTypeList, statusLoading, searchProductTypeId, authData?.policies])

    useEffect(() => {
        getProductTypeList(() => setIsDataLoading(false))
    }, [])

    return (
        <div className="product-types">
            {authData?.policies.includes(WRITE_WAREHOUSE) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateProductType}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId="product-types-table"
                        searchEntityId={searchProductTypeId}
                    />
                }
            </DataLoading>

            <Outlet/>
        </div>
    )
}