import $api from "services/http/RSFS_API";
import { AxiosResponse } from "axios";

import { OperatorDTO } from "types/OperatorInterface";

export default class OperatorService {

    static async changeAppStatus(id: number, activationStatus: number): Promise<AxiosResponse<number>> {
        return $api.put<number>(`/app-status/${id}`, {activationStatus});
    }

    static async getOperators(): Promise<AxiosResponse<OperatorDTO[]>> {
        return $api.get<OperatorDTO[]>(`operators`);
    }
}