import { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { IProductState, IProductUpdate } from 'types/ProductInterface';

const labels: {[key: string]: string} = {
    notes: 'Примітка',
    submit: 'Зберегти',
}

const initialData = {
    notes: '',
}

const fieldsTypes: {[key: string]: string} = {
    notes: 'text',
}

const inputRows: {[key: string]: number} = {
    notes: 6
}

const grid: {[key: string]: number} = {
    notes: 12,
}

const order: {[key: string]: number} = {
    notes: 1,
}

const requiredFields: Array<string> = []

export const EditProduct: FC = () => {
    const [ productData, setProductData ] = useState<IProductUpdate>(initialData)
    const { productsList } = useTypedSelector<IProductState>(state => state.product)

    const { updateProduct, getProductsList } = useActions()

    const params = useParams()
    const location = useLocation()

    useEffect(() => {
        const productInstance = productsList.find(el => (
            el.product_id === Number(params.productId)
        )) || null

        if (productInstance) {
            setProductData({
                notes: productInstance?.notes || '',
            })
        }
    }, [productsList, params])

    return (
        <SubmitEntityModal
            title={`Редагувати продукт ${params.productId}`}
            order={order}
            grid={grid}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            initialData={productData}
            requiredFields={requiredFields}
            labels={labels}
            backRoute={`/dashboard/warehouse/products${location.search}`}
            isDataExists={true}
            submitEnityAction={updateProduct.bind(null, Number(params.productId))}
            getEntityActionOnSubmit={getProductsList}
        />
    )
}