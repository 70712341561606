import { FC, useMemo } from "react";

import {
    PieChart,
    // pieArcLabelClasses
} from '@mui/x-charts/PieChart';

// const data = [
//     { id: 0, value: 10, label: 'series A' },
//     { id: 1, value: 15, label: 'series B' },
//     { id: 2, value: 20, label: 'series C' },
// ];

export interface IPieChartItem {
    value: number;
    label: string;
    color?: string;
}

interface Props {
    data: IPieChartItem[];
    sx: {[key: string]: string | number};
    size: { height?: number, width?: number };
    slotProps: {[key: string]: any};
}

export const TableChartPieType: FC<Props> = ({
    data = [],
    sx = {},
    size = { height: 50, width: 50 },
    slotProps = {}
}) => {

    return (
        <div className="table-chart-pie-type">
            <PieChart
                height={size.height}
                width={size.width}
                slotProps={slotProps}
                series={[
                    {
                        data,
                        // arcLabel: (item) => `${item.label} (${item.value})`,
                        // arcLabelMinAngle: 45,
                        // highlightScope: { faded: 'global', highlighted: 'item' },
                        // faded: { innerRadius: 30, additionalRadius: -30 },
                        innerRadius: 5,
                        outerRadius: 23,
                        paddingAngle: 2,
                        cornerRadius: 3,
                        // startAngle: 360,
                        // endAngle: 0,
                        // cx: 150,
                        // cy: 150,
                    },
                ]}
                sx={{
                //     [`& .${pieArcLabelClasses.root}`]: {
                //     fill: 'white',
                //     fontWeight: 'bold',
                //     },
                    ...sx,
                }}
            />
        </div>
    )
}