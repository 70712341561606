import { AxiosResponse } from 'axios';
import OperatorService from 'services/OperatorService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    CHANGE_APP_STATUS,
    GET_OPERATORS_LIST,
} from "store/types/operatorTypes";

import { IError } from 'types/ErrorInterface';
import { IWorkerCallback, IWorkerGenericData } from 'types/SagaInterface';

import { setOperatorsList} from 'store/actions/operatorAction';
import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';


function* changeAppStatusWorker({payload}: IWorkerGenericData<{app_id: number, activationStatus: number}>) {
    const { data: {app_id, activationStatus}, callback } = payload
    try {
        const response: AxiosResponse = yield call(OperatorService.changeAppStatus, app_id, activationStatus)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Статус оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)

        yield put(setErrorNotifications( ["Змінити статус не вдалося"], error as IError ))
    }
}

function* getOperatorsWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(OperatorService.getOperators)

        if (response?.status === 200) {
            if (callback instanceof Function) callback(true)

            yield put(setOperatorsList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback(false)
        
        yield put(setErrorNotifications( ["Отримати список операторів не вдалося"], error as IError ))
    }
}

export function* operatorWatcher() {
    yield takeLatest(CHANGE_APP_STATUS, changeAppStatusWorker);
    yield takeLatest(GET_OPERATORS_LIST, getOperatorsWorker);
}
