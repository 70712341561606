import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { SubmitEntityModal } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';

import { ICallback } from 'types/SagaInterface';

const labels: {[key: string]: string} = {
    name: 'Назва',
    note: 'Примітка',
}

const initialData = {
    name: '',
    note: '',
}

const fieldsTypes: {[key: string]: string} = {
    name: 'text',
    note: 'text',
}

const inputRows: {[key: string]: number} = {
    note: 6
}

const grid: {[key: string]: number} = {
    name: 12,
    note: 12,
}

const order: {[key: string]: number} = {
    name: 1,
    note: 2,
}

const requiredFields: Array<string> = ['name']

export const CreateComponent: FC = () => {

    const { createComponent, getComponentsList } = useActions()

    const location = useLocation();

    function backRoute(res: ICallback) {
        let query = ''
        if (res?.data && typeof res.data === 'number') query = `?componentId=${res.data}`
        else if (location.search) query = location.search
        return `/dashboard/warehouse/components${query}`
    }

    return (
        <SubmitEntityModal
            title="Створити новий компонент"
            order={order}
            grid={grid}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            initialData={initialData}
            requiredFields={requiredFields}
            labels={labels}
            backRoute={backRoute}
            isDataExists={true}
            submitEnityAction={createComponent}
            getEntityActionOnSubmit={getComponentsList}
        />
    )
}