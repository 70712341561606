import axios from 'axios'
import { IAuthData } from 'types/AuthInterface'

import { tokenStore } from 'store/tokenStore';

// let store: any

// export const injectStore = (_store: any) => {
//   store = _store
// }

export const createAPI = (API_URL: string) => {
    const $api = axios.create({
        withCredentials: true,
        baseURL: API_URL
    })

    $api.interceptors.request.use((config) => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['X-Authorization'] = `Bearer ${tokenStore.get()}`;

        return config;
    });

    $api.interceptors.response.use((config) => {
        return config
    }, async (error) => {
        console.log(error);
        const originalRequest = error.config
        if (error.response.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true
            try {
                const response = await axios.get<IAuthData>(`${API_URL}/refresh-token`, {withCredentials: true})
                await tokenStore.set(response?.data?.jwtToken)
                return $api.request(originalRequest)
            } catch (e) {
                console.log('Not authorized');
                
            }
        }
        throw error
    })

    return $api
}

// X-Authorization юзай такой хедер для авторизації
// https://admin.dev.afoue.org/auth

// /auth-request

// /refresh-token

// на рефреш просто пусти пост