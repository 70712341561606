import { FC, useMemo } from "react";

import { useTypedSelector } from "hooks/useTypedSelector";

import { IProductCreate } from "types/ProductInterface";
import { IRecipeState } from "types/RecipeInterface";
import { ISeriesState } from "types/SeriesInterface";

import { Grid, Paper, Typography } from "@mui/material";

interface IResult {
    title: string;  
    label: string;
    grid: number;
}

interface Props {
    data: IProductCreate;
}

export const Step5: FC<Props> = ({data}) => {
    const { recipesList } = useTypedSelector<IRecipeState>(state => state.recipe)
    const { seriesData } = useTypedSelector<ISeriesState>(state => state.series)

    const resultData = useMemo(() => {
        let output = []

        const recipe = recipesList.find(item => item?.product_type_id === data?.product_type_id) || null
        if (recipe) {
            output.push({
                title: 'Тип продукту',
                label: recipe.name,
                grid: 6
            })
        }

        let serial_number = null
        if (seriesData?.serial_numbers) {
            serial_number = seriesData.serial_numbers.find(item => item?.serial_number_id === data?.serial_number_id)
        }
        if (serial_number?.code && seriesData?.series_code) {
            output.push({
                title: 'Серія',
                label: `${seriesData.series_code} - ${serial_number.code}`,
                grid: 6
            })
        }

        output.push({
            title: 'Примітка',
            label: data.notes,
            grid: 12
        })

        return output
    }, [recipesList, seriesData, data])

    return (
        <Grid
            container
            spacing={2}
            pb={1} pr={1}
            sx={{ maxHeight: '100%', overflowY: 'auto' }}
            className='scroll-bar-primary-y'
        >
            {resultData.map((item: IResult, idx: number) =>
                <Grid key={idx} item xs={item.grid}>
                    <Paper elevation={4} sx={{padding: '20px'}}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            {item.title}:
                        </Typography>
                        <Typography variant="body1" color="primary" style={{ fontStyle: 'italic'}}>
                            {item.label}
                        </Typography>
                    </Paper>
                </Grid>
            )}
        </Grid>
    )
}