import { FC, useEffect, useMemo, useState } from "react";

import { RadioButtonPaper } from "components/Select/RadioButtonPaper";
import { DataLoading } from "components/Loader/DataLoading";
import { EmptyDataInfo } from "components/Common/EmptyDataInfo";

import { useActions } from "hooks/useActions";
import { useTypedSelector } from "hooks/useTypedSelector";

import { ISeriesState } from "types/SeriesInterface";
import { ICallback } from "types/SagaInterface";

import { Grid, Paper, Typography } from "@mui/material";

interface Props {
    value: number;
    onChange: (value: number) => void;
}

export const Step2: FC<Props> = ({ value, onChange }) => {
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true)
    const [ loadingFailed, setLoadingFailed ] = useState<boolean>(false);
    const [ selectedSeries, setSelectedSeries ] = useState<number>(0)
    const { availableSeries } = useTypedSelector<ISeriesState>(state => state.series)
    const { seriesData } = useTypedSelector<ISeriesState>(state => state.series)

    const { getAvailableSeries, setSingleSeries } = useActions()

    useEffect(() => {
        getAvailableSeries((res: ICallback) => {
            if (!res.success) setLoadingFailed(true)
            setIsDataLoading(false)
        })
    }, [])

    useEffect(() => {
        setSelectedSeries(value)
    }, [value])

    const computedSeriesList = useMemo(() => {
        return availableSeries.map(item => ({
            key: item.series_id,
            label: item.series_code
        }))
    }, [availableSeries])

    function handleSelectSeries(value: string | number) {
        const series_id = Number(value)
        setSelectedSeries(series_id)
        onChange(series_id)
        const series = availableSeries.find(el => el.series_id === series_id) || null
        if (series) setSingleSeries(series)
    }

    const computedSerialNumbers = useMemo(() => {
        if (seriesData?.serial_numbers) {
            return seriesData.serial_numbers.map(item => ({
                key: item.serial_number_id,
                label: item.code
            }))
        }
        return []
    }, [seriesData])

    return (
        <Grid item xs={12} sx={{height: '100%'}}>

            <DataLoading isDataLoading={isDataLoading}>

                <Grid container spacing={3} sx={{height: '100%'}}>
                    <Grid item xs={6} sx={{height: '100%'}}>
                        
                        <EmptyDataInfo
                            entity="серії"
                            dataLength={computedSeriesList.length}
                            loadingFailed={loadingFailed}
                            gender='female'
                            to="/dashboard/warehouse/series/create/series"
                        >
                            <RadioButtonPaper
                                title="Виберіть серію:"
                                options={computedSeriesList}
                                grid={{xs: 12, sm: 6, md: 6}}
                                paper={{elevation: 3, style: {padding: '3px 10px'}}}
                                value={selectedSeries}
                                onChange={handleSelectSeries}
                            />
                        </EmptyDataInfo>
                    </Grid>
                    <Grid item xs={6} sx={{height: '100%'}}>
                        {computedSerialNumbers.length > 0 &&
                            <>
                                <Typography variant="subtitle1" color="primary" sx={{mb: 2}}>
                                    Доступні серійні номери:
                                </Typography>
                                <Grid
                                    container
                                    spacing={2}
                                    pb={1} pr={1}
                                    sx={{ maxHeight: '100%', overflowY: 'auto' }}
                                    className='scroll-bar-primary-y'
                                >
                                    {computedSerialNumbers.map((item, idx: number) =>
                                        <Grid key={idx} item xs={12} sm={6} md={3}>
                                            <Paper elevation={3} sx={{padding: '5px 8px'}}>
                                                {item.label}
                                            </Paper>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>

            </DataLoading>

        </Grid>
    )
}