import { FC, useMemo} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal, INumberRange } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';

interface IModalData {
    title: string;
    labels: {[key: string]: string};
}

const initialData: {[key: string]: number} = {
    serialNumberCount: 0,
}

const dataTypes: {[key: string]: string} = {
    serialNumberCount: 'number',
}

const fieldsTypes: {[key: string]: string} = {
    serialNumberCount: 'number',
}

const helpText: {[key: string]: string} = {
    serialNumberCount: 'Введіть кількість від 1 до 20',
}

const numberRange: INumberRange = {
    serialNumberCount: { min: 1, max: 20 },
}

const requiredFields: Array<string> = ['serialNumberCount']

export const CreateSeries: FC = () => {
    const { createSeries, getSeriesList } = useActions()

    const params = useParams();
    const location = useLocation()

    const modalData: IModalData = useMemo(() => {
        let data = {
            title: '',
            labels: {
                serialNumberCount: '',
            }
        }
        if (params?.type === "series") {
            data = {
                title: 'Створити серію',
                labels: {
                    serialNumberCount: 'Кількість серійних номерів',
                }
            }
        } else if (params?.type === "serial-number") {
            data = {
                title: 'Додати серійний номер',
                labels: {
                    serialNumberCount: 'Кількість серійних номерів',
                }
            }
        }
        return data
    }, [params])

    function backRoute(res?: any) {
        console.log(res)
        let query = ''
        if (res?.data?.id && typeof res.data.id === 'number') query = `?seriesId=${res.data}`
        else if (location.search) query = location.search
        return `/dashboard/warehouse/series${query}`
    }

    return (
        <SubmitEntityModal
            title={modalData.title}
            dataTypes={dataTypes}
            fieldsTypes={fieldsTypes}
            numberRange={numberRange}
            initialData={initialData}
            requiredFields={requiredFields}
            labels={modalData.labels}
            backRoute={backRoute}
            helpText={helpText}
            isDataExists={true}
            submitEnityAction={createSeries.bind(null, `create-${params.type}`, Number(params.seriesId))}
            getEntityActionOnSubmit={getSeriesList}
        />
    )
}