
export const CREATE_PRODUCT = "CREATE_PRODUCT"
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT"
export const SET_SINGLE_PRODUCT = "SET_SINGLE_PRODUCT"
export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST"
export const SET_PRODUCTS_LIST = "SET_PRODUCTS_LIST"
export const GET_AVAILABLE_PRODUCTS = "GET_AVAILABLE_PRODUCTS"
export const SET_AVAILABLE_PRODUCTS = "SET_AVAILABLE_PRODUCTS"
export const GET_PRODUCTS_BY_STATUS_IDS = "GET_PRODUCTS_BY_STATUS_IDS"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const SET_PRODUCT_STATUS = "SET_PRODUCT_STATUS"
export const SET_PRODUCT_STATUS_LIST = "SET_PRODUCT_STATUS_LIST"
export const GET_PRODUCT_STATUS_LIST = "GET_PRODUCT_STATUS_LIST"
export const DELETE_PRODUCT = "DELETE_PRODUCT"
export const ASSIGN_PRODUCT = "ASSIGN_PRODUCT"
export const UN_ASSIGN_PRODUCT = "UN_ASSIGN_PRODUCT"