import { FC, useEffect, useMemo, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { SubmitEntityModal } from 'pages/Dashboard/Common/SubmitEntityModal';

import { useActions } from 'hooks/useActions';
import { useTypedSelector } from 'hooks/useTypedSelector';

import { EOrderStatus, IOrder, IOrderState } from 'types/OrderInterface';

const orderDataLabels: {[key: string]: string} = {
    last_name: "Прізвище",
    first_name: "Ім'я",
    brigade_name: "Назва бригади",
    position: 'Посада',
    work_region: "Район роботи",
    phone: "Номер телефону",
    notes: "Коментар",
    submit: 'Зберегти',
}

const inputRows: {[key: string]: number} = {
    notes: 4
}

const grid: {[key: string]: number} = {
    last_name: 6,
    first_name: 6,
    brigade_name: 6,
    position: 6,
    work_region: 6,
    phone: 6,
    notes: 12,
}

const requiredFields: Array<string> = ['last_name', 'first_name', 'position', 'phone']

export const EditOrderSimple: FC = () => {
    const [ orderData, setOrderData ] = useState<Partial<IOrder>>({})
    const { orderList } = useTypedSelector<IOrderState>(state => state.order)

    const { updateOrder, getOrdersList } = useActions()

    const params = useParams();
    const location = useLocation();

    useEffect(() => {
        const orderInstance = orderList.find(el => el.order_id === Number(params.orderId)) || {}
        setOrderData({...orderInstance})
    }, [orderList, params])

    const initialOrderData: any = useMemo(() => {
        return {
            last_name: orderData?.last_name,
            first_name: orderData?.first_name,
            brigade_name: orderData?.brigade_name,
            position: orderData?.position,
            work_region: orderData?.work_region,
            phone: orderData?.phone,
            notes: orderData?.notes,
        }
    }, [orderData])

    const fieldsTypes = useMemo(() => {
        let types: {[key: string]: string} = { notes: 'text' }
        if (orderData?.status_id !==undefined && orderData.status_id !== EOrderStatus.DECLINED) {
            types = {
                phone: 'tel',
                last_name: 'text',
                first_name: 'text',
                brigade_name: 'text',
                work_region: 'text',
                position: 'text',
                notes: 'text',
            }
        }
        return types
    }, [orderData])

    return (
        <SubmitEntityModal
            title="Редагувати замовлення"
            grid={grid}
            fieldsTypes={fieldsTypes}
            inputRows={inputRows}
            initialData={initialOrderData}
            requiredFields={requiredFields}
            labels={orderDataLabels}
            backRoute={`/dashboard/order/customer/confirmed${location.search}`}
            isDataExists={true}
            submitEnityAction={updateOrder.bind(null, Number(params?.orderId), orderData?.person_id)}
            getEntityActionOnSubmit={getOrdersList}
        />
    )
}