import {
    SET_COMPONENTS_LIST,
    GET_COMPONENTS_LIST,
    SET_SINGLE_COMPONENT,
    // GET_SINGLE_COMPONENT,
    CREATE_COMPONENT,
    UPDATE_SINGLE_COMPONENT,
    DELETE_COMPONENT,
} from "store/types/componentTypes";

import { IComponent } from "types/ComponentInterface";
import { ICallback } from "types/SagaInterface";

export const setComponentsList = (componentsList: IComponent[]) => ({
    type: SET_COMPONENTS_LIST,
    payload: componentsList,
});

export const getComponentsList = (callback: (res: ICallback) => void) => ({
    type: GET_COMPONENTS_LIST,
    payload: {
        callback
    }
});

export const setSingleComponent = (componentData: IComponent) => ({
    type: SET_SINGLE_COMPONENT,
    payload: componentData
})

// export const getSingleComponent = (componentId: number) => ({
//     type: GET_SINGLE_COMPONENT,
//     payload: componentId
// })

export const createComponent = (data: IComponent, callback: (res: ICallback) => void) => ({
    type: CREATE_COMPONENT,
    payload: {
        data,
        callback,
    }
})

export const updateComponent = (id: number, data: IComponent, callback: (res: ICallback) => void) => ({
    type: UPDATE_SINGLE_COMPONENT,
    payload: {
        id,
        data,
        callback,
    }
})

export const deleteComponent = (id: number, callback: (res: ICallback) => void) => ({
    type: DELETE_COMPONENT,
    payload: {
        id,
        callback
    }
})