import { AxiosResponse } from 'axios';
import ComponentOrderService from 'services/ComponentOrderService';

import {
    put,
    call,
    takeLatest
} from 'redux-saga/effects';

import {
    CREATE_COMPONENT_ORDER,
    // GET_SINGLE_COMPONENT_ORDER,
    GET_COMPONENTS_ORDERS_LIST,
    UPDATE_COMPONENT_ORDER,
    DELETE_COMPONENT_ORDER,
    SET_COMPONENT_ORDER_MOVEMENT_STATUS,
} from "store/types/componentOrderTypes";

import { IError } from 'types/ErrorInterface';
import { IComponentOrder, ICreateComponentOrder } from "types/ComponentOrderInterface"
import { IWorkerCallback, IWorkerCreate, IWorkerDelete, IWorkerUpdate, IWorkerUpdateStatus } from 'types/SagaInterface';

import { setComponentsOrdersList } from 'store/actions/componentOrderAction';

import { setErrorNotifications, setSuccessNotifications } from 'store/actions/notificationAction';
import { OrderNumberType } from 'types/OrderInterface';


function* createComponentOrderWorker({payload}: IWorkerCreate<ICreateComponentOrder>) {
    const { data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentOrderService.create, data)
        
        if (response?.status === 201) {
            if (callback instanceof Function) callback({status: 'success', success: true, data: response?.data})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення компонента створено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Cтворити замовлення компонента не вдалося"], error as IError ))
    }
}

// function* getSingleComponentOrderWorker({payload: id}: {type: string, payload: number}) {
//     try {
//         // const response: AxiosResponse = yield call(ComponentOrderService.getSingle, id)
//         // if (response?.status === 200) {
//         //     yield put(setSingleComponentOrder(response.data))
//         // }
//     } catch(error) {
//         yield put(setErrorNotifications( ["Отримати замовлення компонент не вдалося"], error as IError ))
//     }
// }

function* getComponentsOrdersListWorker({payload}: IWorkerCallback) {
    const { callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentOrderService.getAll)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            yield put(setComponentsOrdersList(response.data))
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Отримати список замовлень компонентів не вдалося"], error as IError ))
    }
}

function* updateComponentOrderWorker({payload}: IWorkerUpdate<IComponentOrder>) {
    const { id, data, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentOrderService.update, id, data)

        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення компонент оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Оновити замовлення компонент не вдалося"], error as IError ))
    }
}

function* deleteComponentOrderWorker({payload}: IWorkerDelete) {
    const { id, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentOrderService.delete, id)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Замовлення компонента видалено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Видалити замовлення компонента не вдалося"], error as IError ))
    }
}

function* setComponentOrderMovementStatusWorker({payload}: IWorkerUpdateStatus<OrderNumberType>) {
    const { id, status, callback } = payload
    try {
        const response: AxiosResponse = yield call(ComponentOrderService.updateMovementStatus, id, status)
        
        if (response?.status === 200) {
            if (callback instanceof Function) callback({status: 'success', success: true})

            if (response?.data?.message) {
                yield put(setSuccessNotifications(response.data.message))
            } else {
                yield put(setSuccessNotifications("Статус оновлено"))
            }
        }
    } catch(error) {
        if (callback instanceof Function) callback({status: 'error', success: false})

        yield put(setErrorNotifications( ["Змінити статус не вдалося"], error as IError ))
    }
}

export function* componentOrderWatcher() {
    yield takeLatest(CREATE_COMPONENT_ORDER, createComponentOrderWorker);
    // yield takeLatest(GET_COMPONENT_ORDER, getSingleComponentOrderWorker);
    yield takeLatest(GET_COMPONENTS_ORDERS_LIST, getComponentsOrdersListWorker);
    yield takeLatest(UPDATE_COMPONENT_ORDER, updateComponentOrderWorker);
    yield takeLatest(DELETE_COMPONENT_ORDER, deleteComponentOrderWorker);
    yield takeLatest(SET_COMPONENT_ORDER_MOVEMENT_STATUS, setComponentOrderMovementStatusWorker);
}
