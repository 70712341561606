import { FC, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';

import { Login } from 'pages/Auth/Login';

import { AppLayout } from 'components/Layout/AppLayout';
import { DashboardLayout } from 'components/Layout/DashboardLayout';
// import { ManagerLayout } from 'components/Layout/ManagerLayout';
// import { AdminLayout } from 'components/Layout/AdminLayout';

import { WarehouseRoutes } from './modules/WarehouseRouter';
import { MovementsRoutes } from './modules/MovementsRouter';
import { OrderRoutes } from './modules/OrderRouter';
import { RSFSRouter } from './modules/RSFSRouter';
import { WatchLogsRouter } from './modules/WatchLogsRouter';

import { PermissionDenied } from 'pages/PermissionDenied';
import { NotFound } from 'pages/404';

import { useTypedSelector } from 'hooks/useTypedSelector';

import { IAuthState } from 'types/AuthInterface';

interface Props {}

export const AppRouter: FC<Props> = () => {
    const { isAuth, authData } = useTypedSelector<IAuthState>(state => state.auth)

    const routes = useMemo(() => {
        return [
        {
            id: "app-layout",
            path: "/",
            element: <AppLayout/>,
            children: [
                { id: "login", path: "login", element: <Login/> },
                {
                    id: "dashboard",
                    path: "dashboard",
                    element: <DashboardLayout/>,
                    children: [
                        ...WarehouseRoutes(authData?.policies),
                        ...MovementsRoutes(authData?.policies),
                        ...OrderRoutes(authData?.policies),
                        { path: "rsfs/*", element: <RSFSRouter/> },
                        { path: "watch-logs/*", element: <WatchLogsRouter/> },
                    ]
                }
            ]
        },
        { id: "permission-denied", path: "/permission-denied", element: <PermissionDenied/> },
        { id: "not-found", path: "*", element: <NotFound/> },
    ]}, [authData?.policies, isAuth])

    return useRoutes(routes);

    // return (
    //     <>
    //         <BrowserRouter>
    //             <Routes>
    //                 <Route path="/" element={<AppLayout/>}>
    //                     <Route path="/login" element={<Login/>}/>

    //                     <Route path="dashboard" element={<DashboardLayout/>}>

    //                         <Route element={<ManagerLayout/>}>
    //                             <Route path="warehouse/*" element={<WarehouseRouter/>}/>

    //                             <Route path="movements/*" element={<MovementsRouter/>}/>

    //                             <Route path="order/*" element={<OrderRouter/>}/>
    //                         </Route>

    //                         <Route element={<AdminLayout/>}>
    //                             <Route path="rsfs/*" element={<RSFSRouter/>}/>

    //                             <Route path="watch-logs/*" element={<WatchLogsRouter/>}/>
    //                         </Route>

    //                     </Route>
    //                 </Route>

    //                 <Route path="/permission-denied" element={<PermissionDenied/>}/>
    //                 <Route path="*" element={<NotFound/>}/>
    //             </Routes>
    //         </BrowserRouter>
    //     </>
    // );
}
