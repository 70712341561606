// import { FC } from "react";
// import { Route, Routes } from "react-router-dom";

import { Movements } from 'pages/Dashboard/Movements/Movements';
import { ConstructionDnD } from "pages/Dashboard/Movements/Construction/ConstructionDnD";

import { DeliveryToClient } from "pages/Dashboard/Movements/DeliveryToClient/DeliveryToClient";
import { DeliveryForm } from "pages/Dashboard/Movements/DeliveryToClient/DeliveryForm";

import { ReturningFromClient } from "pages/Dashboard/Movements/ReturningFromClient/ReturningFromClient";

import { DeliveryStatus } from "pages/Dashboard/Movements/DeliveryStatus/DeliveryStatus";

import { WRITE_PRODUCTS } from 'store/types/policiesTypes';

export const MovementsRoutes = (policies?: Array<string>) => [
    {
        id: "movements",
        path: "movements",
        element: <Movements/>,
        children: [
            {
                id: "construction",
                path: "construction",
                element: <ConstructionDnD/>,
                children: policies?.includes(WRITE_PRODUCTS) ? [] : []
            },
            {
                id: "delivery-to-client",
                path: "delivery-to-client",
                element: <DeliveryToClient/>,
                children: policies?.includes(WRITE_PRODUCTS) ? [
                    { id: "delivery-to-client-form", path: "delivery-form/:orderItemId", element: <DeliveryForm/> },
                ] : []
            },
            {
                id: "returning-from-client",
                path: "returning-from-client",
                element: <ReturningFromClient/>,
                children: policies?.includes(WRITE_PRODUCTS) ? [] : []
            },
            {
                id: "delivery-status",
                path: "delivery-status",
                element: <DeliveryStatus/>,
                children: policies?.includes(WRITE_PRODUCTS) ? [] : []
            },
        ]
    }
]

// interface Props {}

// export const MovementsRouter: FC<Props> = () => {
//     return (
//         <Routes>
//             <Route path="/" element={<Movements/>}>

//                 <Route path="construction" element={<ConstructionDnD/>}/>

//                 <Route path="delivery-to-client" element={<DeliveryToClient/>}>
//                     <Route path="delivery-form/:orderItemId" element={<DeliveryForm/>}/>
//                 </Route>

//                 <Route path="returning-from-client" element={<ReturningFromClient/>}/>

//                 <Route path="delivery-status" element={<DeliveryStatus/>}/>

//             </Route>
//         </Routes>
//     )
// }