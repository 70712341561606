import { ChangeEvent, FC, MouseEvent, useEffect, useMemo, useRef, useState } from "react";

import { IFilterData } from "types/TableInterface";

import { Box, Button, MenuItem, Paper, Popover, Slider, TextField, Typography } from "@mui/material";

import {
    ErrorOutline as ErrorOutlineIcon,
    WarningAmber as WarningAmberIcon,
    FilterAlt as FilterAltIcon,
} from '@mui/icons-material';

interface IOption {
    label: string;
    key: string | number;
    iconName: string;
    iconFontSize?: "inherit" | "small" | "medium" | "large";
    iconColor?: "primary" | "inherit" | "secondary" | "error" | "info" | "success" | "warning";
    btnColor: "primary" | "inherit" | "secondary" | "error" | "info" | "success" | "warning";
    btnVariant: "contained" | "outlined" | "text"
}

export interface IFilterElement {
    key: string;
    label?: string;
    type?: string;
    options?: IOption[];
    action: (value?: string | number | number[]) => void;
}

interface Props {
    range: number[];
    keyName: string;
    filterData: IFilterData;
    data: IFilterElement[];
}

export const Filter: FC<Props> = ({ range = [], keyName, data, filterData }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sliderValue, setSliderValue] = useState<number[]>([0, 100]);
    const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
    const prevRangeRef = useRef<number[]>(range);

    const handleClick = (event: MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.target as HTMLElement);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function debounce(callback: (value: string) => void, newValue: string, delay: number) {
        if (timeoutId) clearTimeout(timeoutId)
        setTimeoutId(setTimeout(() => {callback(newValue)}, delay))
    }

    const searchHandler = (e: ChangeEvent<HTMLInputElement>, action: () => void) => {
        const newValue = e.target.value;
        setSearchTerm(newValue);
        debounce(action, newValue, 100);
    }

    useEffect(() => {
        if (Array.isArray(range) && !prevRangeRef.current?.length && range.length) {
            setSliderValue([...range])
        }
    }, [range])

    useEffect(() => {
        if (!filterData[`${keyName}|search`]) setSearchTerm('')

        const rangeSliderValue = filterData[`${keyName}|range-slider`]
        if (Array.isArray(rangeSliderValue) && (rangeSliderValue as number[]).length) {
            setSliderValue([...rangeSliderValue as number[]])
        }
    }, [filterData, keyName])

    useEffect(() => {
        const rangeSliderValue = filterData[`${keyName}|range-slider`]
        if (Array.isArray(rangeSliderValue) && (rangeSliderValue as number []).length) {
            setSliderValue([...rangeSliderValue as number[]])
        } else if (Array.isArray(range) && range.length) {
            setSliderValue([...range])
        }

        if (filterData[`${keyName}|search`]) setSearchTerm(filterData[`${keyName}|search`] as string)
    }, [])

    const marks = useMemo(() => {
        if (range.length) {
            let arr = [{ value: range[0], label: range[0] }]
            if ((range[1] - range[0]) > 20) {
                const middle = Math.floor(((range[1] - range[0]) / 2) + range[0])
                arr.push({ value: middle, label: middle })
            }
            arr.push({ value: range[1], label: range[1] })
            return arr
        }
        return []
    }, [range])

    return (
        <div className="">
            <FilterAltIcon
                className="action-icon"
                color="primary"
                sx={{verticalAlign: 'bottom'}}
                onClick={handleClick}
            />
            <Popover
                id="sorting-dropdown"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Paper
                    sx={{display: 'flex', gap: '10px', flexDirection: 'column'}}
                    elevation={3}
                    style={{padding: '10px'}}
                >
                    {data.map((el: IFilterElement, index: number) => (
                        <Box key={index}>
                            {el.key === 'search' &&
                                <TextField
                                    key={'search' + index}
                                    label={el.label}
                                    value={searchTerm}
                                    type={el.type}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => searchHandler(e, el.action)}
                                />
                            }
                            {el.key === 'limit' && (
                                el.options && el.options.map((item: IOption, idx: number) => (
                                    <MenuItem
                                        key={idx}
                                        onClick={() => {handleClose(); el.action(item.key)}}
                                    >
                                        {item.iconName === 'WarningAmber' &&
                                            <WarningAmberIcon
                                                sx={{marginRight: '10px'}}
                                                fontSize={item.iconFontSize ?? 'small'}
                                                color={item.iconColor ?? 'warning'}
                                            />
                                        }
                                        
                                        {item.iconName === 'ErrorOutline' &&
                                            <ErrorOutlineIcon
                                                sx={{marginRight: '10px'}}
                                                fontSize={item.iconFontSize ??'small'}
                                                color={item.iconColor ?? 'error'}
                                            />
                                        }

                                        {item.label}
                                    </MenuItem>
                                ))
                            )}
                            {el.key === 'button-list' && (
                                el.options && el.options.map((item: IOption, idx: number) => (
                                    <Button
                                        sx={{display: 'block', width: '100%', marginBottom: '15px'}}
                                        key={idx}
                                        color={item.btnColor ?? 'initial'}
                                        variant={item.btnVariant ?? 'contained'}
                                        onClick={() => {handleClose(); el.action(item.key)}}
                                    >
                                        {item.label}
                                    </Button>
                                ))
                            )}
                            {el.key === 'range-slider' &&
                                <Box sx={{ minWidth: '150px', padding: '15px 15px 0' }}>
                                    <Typography gutterBottom>
                                        {el.label}:&nbsp;
                                        {filterData[`${keyName}|${el.key}`] ? (filterData[`${keyName}|${el.key}`] as number[])[0] : range ? range[0] : 0}
                                        &nbsp;-&nbsp;
                                        {filterData[`${keyName}|${el.key}`] ? (filterData[`${keyName}|${el.key}`] as number[])[1] : range ? range[1] : 100}
                                    </Typography>
                                    <Slider
                                        key={'range-slider' + index}
                                        valueLabelDisplay="auto"
                                        marks={marks}
                                        min={range ? range[0] : 0}
                                        step={1}
                                        max={range ? range[1] : 100}
                                        getAriaLabel={() => 'Temperature range'}
                                        value={sliderValue}
                                        onChange={(e, newValue: number | number[]) => {setSliderValue(newValue as number[])}}
                                        onMouseUp={() => el.action(sliderValue)}
                                    />
                                </Box>
                            }
                        </Box>
                    ))}
                </Paper>
            </Popover>
        </div>
    )
}