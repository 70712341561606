import { ElementType, FC, Fragment, MutableRefObject, SyntheticEvent, useEffect, useMemo, useRef } from "react";

import { TableComponent } from "components/Table/Table";
import { TableActionsType } from "components/Table/TableBody/TableColumnType/TableActionsType";
import { TableChartPieType } from "components/Table/TableBody/TableColumnType/TableChartPieType";
import { TableInputType } from "components/Table/TableBody/TableColumnType/TableInputType";
import { TableTextIconType } from "components/Table/TableBody/TableColumnType/TableTextIconType";
import { TableSelectType } from "components/Table/TableBody/TableColumnType/TableSelectType";

import { ITableDataItem, ITableHeader, ITableItemStyles } from "types/TableInterface";

import {
    TableCell,
    TableRow,
    Tooltip,
    Box,
    Chip,
} from '@mui/material';

import './table-row-component.scss'

interface Props<T> {
    zIndex: number;
    tableComponentRef: MutableRefObject<HTMLDivElement | null>;
    data: ITableDataItem<T>;
    headers: ITableHeader[];
    index: number;
    onChangeInput?: (value: string | number, index: number) => void;
    getHeaderStyles: (styles: ITableItemStyles | undefined) => void;
    StyledTableRow: ElementType;
    StyledTableCell: ElementType;
}

export const TableRowComponent: FC<Props<any>> = ({
    zIndex,
    tableComponentRef,
    data,
    headers,
    index,
    onChangeInput,
    getHeaderStyles,
    StyledTableRow,
    StyledTableCell
}) => {
    
    const tableRowRef = useRef<HTMLElement | null>(null);

    function handleTableRowClick(event: SyntheticEvent<HTMLElement>) {
        const target = event?.target as HTMLElement;
        const parentNode = target?.parentNode as HTMLElement | null;
        if (parentNode) toggleAccordion(parentNode)

        if (data?.rowSettings?.action instanceof Function) data.rowSettings.action()
    }

    function toggleAccordion(target: HTMLElement) {
        if (target) {
            const panel = target?.nextElementSibling?.querySelector('.panel');

            if (panel as HTMLElement && panel instanceof HTMLElement) {
                if (panel?.style?.height) {
                    panel?.classList?.remove('opened')
                    panel.style.height = '';
                } else {
                    panel?.classList?.add('opened')
                    panel.style.height = panel?.scrollHeight + "px";
                }
            }
        }
    }

    function fitPanelContent() {
        const panels = document.querySelectorAll('.panel.opened');
        if (panels) panels?.forEach((panel) => {
            if (panel instanceof HTMLElement) {
                const panel_content = panel.querySelector('.panel__content');

                if (panel_content instanceof HTMLElement && panel?.style?.height && panel_content?.scrollHeight) {
                    if (parseFloat(panel?.style?.height) !== panel_content?.scrollHeight) {
                        panel.style.height = panel_content?.scrollHeight + 'px';
                    }
                }
            }
        });
    }

    // Initailized to resize accordeon when sorting or filtering data
    function onSortingFilterChange() {}

    useEffect(() => {
        fitPanelContent()
    }, [data?.accordionData?.tableData, onSortingFilterChange])

    useEffect(() => {
        if (data?.rowSettings?.toggleAccordion !== undefined) {
            if (data?.rowSettings?.toggleAccordion === true && tableRowRef?.current) {
                toggleAccordion(tableRowRef.current)
            }
        }
    }, [data?.rowSettings?.toggleAccordion])

    useEffect(() => {
        if (data?.rowSettings?.focused?.status) {
            const tableElement = tableComponentRef?.current
            const rowElement = tableRowRef?.current

            if (tableElement && rowElement) {
                const tableRect = tableElement?.getBoundingClientRect()
                const rowRect = rowElement?.getBoundingClientRect()

                if (tableRect && rowRect) {
                    const middleOfTable = tableRect.height / 2;
                    tableElement.scrollTop = rowRect.top - tableRect.top - middleOfTable;
                }

                if (rowElement?.className) {
                    rowElement.className = "table-row-component focus-success"

                    setTimeout(() => {
                            rowElement.className = "table-row-component"
                        if (data?.rowSettings?.focused?.onFinish) {
                            data?.rowSettings?.focused?.onFinish()
                        }
                    }, data?.rowSettings?.focused?.duration ?? 4500)
                }
            }
        }
    }, [data?.rowSettings?.focused])

    const hasChildren = useMemo(() => {
        if (data?.accordionData && data?.accordionData?.headers && data?.accordionData?.tableData) {
            return true
        }
        return false
    }, [data?.accordionData])

    return (
        <Fragment>
            <StyledTableRow
                ref={tableRowRef}
                className='table-row-component'
                style={{cursor: hasChildren ? 'pointer' : 'initial'}}
                onClick={handleTableRowClick}
            >
                {headers?.map((header: ITableHeader) => (
                    <StyledTableCell
                        sx={getHeaderStyles(header?.styles)}
                        key={header?.key}
                        component="th"
                        scope="row"
                        align={header?.styles?.textAlign ?? 'inherit'}
                    >
                        {(!header?.type || header?.type === 'text') && data[header?.key]}

                        {header?.type === 'input' &&
                            <TableInputType
                                data={data?.[header?.key]}
                                index={index}
                                onChangeInput={onChangeInput}
                            />
                        }

                        {header?.type === 'text-icon' &&
                            <TableTextIconType data={data} header={header}/>
                        }

                        {(header?.type === 'tooltip' && data?.[header?.key]) &&
                            <Tooltip title={data[header.key]}>
                                <span>{header?.action(data[header.key])}</span>
                            </Tooltip>
                        }

                        {header?.type === 'select' &&
                            <TableSelectType data={data} header={header} zIndex={zIndex}/>
                        }

                        {(header?.type === 'chart-pie' && data?.[header?.key]?.data) &&
                            <TableChartPieType
                                data={data[header.key].data}
                                sx={data[header.key]?.sx}
                                size={data[header.key]?.size}
                                slotProps={data[header.key]?.slotProps}
                            />
                        }

                        {(header?.type === 'actions' && data?.[header?.key]?.length > 0) &&
                            <TableActionsType data={data} header={header}/>
                        }

                        {(header?.type === 'chip' && data?.[header?.key]?.label) &&
                            <Chip
                                label={data[header.key].label}
                                color={data[header.key]?.color || 'info'}
                                sx={data[header.key]?.style}
                            />
                        }
                    </StyledTableCell>
                ))}
            </StyledTableRow>

            {hasChildren &&
                <TableRow>
                    <TableCell colSpan={headers?.length} sx={{padding: 0}}>
                        <Box className="panel">
                            <div className='panel__content'>
                                <TableComponent
                                    headers={data?.accordionData?.headers}
                                    tableData={data?.accordionData?.tableData}
                                    tableId={data?.tableId}
                                    has_pagination={false}
                                />
                            </div>
                        </Box>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    )
}