import { FC, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { TableComponent } from "components/Table/Table";
import { DataLoading } from "components/Loader/DataLoading";

import { useTypedSelector } from "hooks/useTypedSelector";
import { useActions } from "hooks/useActions";

import { IAuthState } from "types/AuthInterface";
import { IComponent, IComponentState } from "types/ComponentInterface";
import { ITableHeader } from "types/TableInterface";

import { WRITE_WAREHOUSE } from "store/types/policiesTypes";

import { getShortText } from "utils/helpers/gerShortText";

import { AddCircle } from '@mui/icons-material';
import { IconButton } from "@mui/material";

const availableEntitySettings = {
    filter: [{
        key: 'limit',
        options: [
            {label: 'Завершується', key: [1, 5], iconName: 'WarningAmber'},
            {label: 'Завершився', key: [0, 0], iconName: 'ErrorOutline'}
        ]},
        'search',
        'range-slider'
    ],
    sort: ['ASC', 'DESC']
}

interface Props {}

export const Components: FC<Props> = () => {
    const { authData } = useTypedSelector<IAuthState>(state => state.auth)
    const { componentsList } = useTypedSelector<IComponentState>(state => state.component)
    const [ statusLoading, setStatusLoading ] = useState(false);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(true);

    const {
        getComponentsList,
        // deleteComponent,
    } = useActions()

    const navigate = useNavigate()
    const location = useLocation()

    function toCreateComponent() {
        navigate(`/dashboard/warehouse/components/create${location.search}`)
    }

    const [ searchParams ] = useSearchParams()
    const [ searchComponentId, setSearchComponentId ] = useState<number | null>(null);

    useEffect(() => {
        const idParam = Number(searchParams.get("componentId")) || null;
        setSearchComponentId(idParam);
    }, [searchParams]);

    function checkAvailability(quantity: number) {
        if (quantity === 0) {
            return { value: quantity, iconName: 'ErrorOutline' }
        } else if (quantity <= 5) {
            return { value: quantity, iconName: 'WarningAmber' }
        } else {
            return { value: quantity }
        }
    }

    // const componentsList: any = [];
    // for (let i = 1; i <= 200; i++) {
    //     componentsList.push({
    //         id: i,
    //         name: `component ${String.fromCharCode(65 + (i % 26))}`,
    //         note: 'remark',
    //         available: Math.floor(Math.random() * 20),
    //         in_way: Math.floor(Math.random() * 10),
    //         supplier: `Supplier ${String.fromCharCode(65 + (i % 5))}`,
    //         price: Math.floor(Math.random() * 1000),
    //     });
    // }

    // function deleteComponentHandler(id: number) {
    //     setStatusLoading(true)
    //     deleteComponent(
    //         id,
    //         (res: ICallback) => {
    //             if (res.success) getComponentsList(() => setStatusLoading(false))
    //             setStatusLoading(false)
    //         }
    //     );
    // }

    const tableHeaders: ITableHeader[] = useMemo(() => {
        let output: ITableHeader[] = [
            { key: "id", label: "ID", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "name", label: "Назва", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "note", label: "Примітка", type: 'tooltip', action: getShortText, settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "available", label: "Доступність", type: 'text-icon', settings: availableEntitySettings },
            { key: "in_way", label: "У дорозі", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "required_quantity", label: "Необхідно", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
            { key: "supplier", label: "Постачальник", type: 'text', settings: { filter: ['search'], sort: ['ASC', 'DESC'] } },
            { key: "price", label: "Ціна", type: 'text', settings: { filter: ['search', 'range-slider'], sort: ['ASC', 'DESC'] } },
        ]
        if ([WRITE_WAREHOUSE].some(el => authData?.policies.includes(el))) {
            output.push({ key: "actions", type: 'actions', styles: {textAlign: 'right'} })
        }
        return output
    }, [authData?.policies])
    
    const buildTableData = useMemo(() => {
        let data = componentsList

        if (searchComponentId) {
            data = componentsList?.filter(el => searchComponentId === el?.id)
        }

        return data?.map((item: IComponent, index: number) => {
            return {
                tableId: item?.id,
                table_row_key: `component_id_${item?.id}_${index}`,
                id: item?.id,
                name: item?.name,
                note: item?.note,
                available: checkAvailability(item?.available),
                in_way: item?.in_way,
                supplier: item?.supplier,
                price: item?.price,
                required_quantity: item?.required_quantity,
                rowSettings: {
                    loading: statusLoading,
                },
                actions: authData?.policies.includes(WRITE_WAREHOUSE) ? [
                    {
                        iconName: "Edit",
                        action: () => {
                            if (item?.id) {
                                navigate(`/dashboard/warehouse/components/edit/${item.id}${location.search}`)
                            }
                        },
                        tooltip: 'Редагувати',
                    },
                    // {
                    //     iconName: "Delete",
                    //     confirmAction: () => deleteComponentHandler(item?.id),
                    //     confirmationMsg: `Ви впевнені що хочете видалити компонент ID ${item?.id}?`,
                    //     disabled: item?.isBusy,
                    //     iconColor: item?.isBusy && 'disabled',
                    //     tooltip: item?.isBusy ? 'Компонент у використанні' : 'Видалити',
                    // },
                ] : []
            }
        })
    }, [componentsList, statusLoading, searchComponentId, authData?.policies])

    useEffect(() => {
        getComponentsList(() => setIsDataLoading(false))
    }, [])

    return (
        <div className="components">
            {authData?.policies.includes(WRITE_WAREHOUSE) &&
                <IconButton
                    color="primary"
                    edge="start"
                    size="large"
                    sx={{m: 0, marginTop: '-25px'}}
                    onClick={toCreateComponent}
                >
                    <AddCircle fontSize="large"/>
                </IconButton>
            }

            <DataLoading isDataLoading={isDataLoading}>
                {buildTableData.length > 0 &&
                    <TableComponent
                        headers={tableHeaders}
                        tableData={buildTableData}
                        tableId="components-list-table"
                        searchEntityId={searchComponentId}
                    />
                }
            </DataLoading>

            <Outlet/>
        </div>
    )
}