// import { FC } from "react";
// import { Route, Routes } from "react-router-dom";

import { Order } from "pages/Dashboard/Order/Order";

import { CustomerOrder } from "pages/Dashboard/Order/Customer/CustomerOrder";
import { EditOrderSimple } from "pages/Dashboard/Order/Customer/EditOrderSimple";
import { EditOrderItem } from "pages/Dashboard/Order/Customer/EditOrderItem";
import { CreateOrder } from 'pages/Dashboard/Order/Customer/CreateOrder';
import { CreateOrderItem } from "pages/Dashboard/Order/Customer/CreateOrderItem";
import { AssignProductDnD } from "pages/Dashboard/Order/Customer/AssignProductDnD";

import { UnconfirmedOrders } from "pages/Dashboard/Order/Unconfirmed/UnconfirmedOrders";

import { ComponentsOrders } from "pages/Dashboard/Order/Component/ComponentsOrders";
import { CreateComponentOrder } from "pages/Dashboard/Order/Component/CreateComponentOrder";
import { EditComponentOrder } from "pages/Dashboard/Order/Component/EditComponentOrder";

import { WRITE_ORDER } from "store/types/policiesTypes";

export const OrderRoutes = (policies?: Array<string>) => [
    {
        id: "order",
        path: "order",
        element: <Order/>,
        children: [
            {
                id: "confirmed-orders",
                path: "customer/confirmed",
                element: <CustomerOrder/>,
                children: policies?.includes(WRITE_ORDER) ? [
                    { id: "create-order", path: "create", element: <CreateOrder/> },
                    { id: "edit-order", path: "edit/:orderId", element: <EditOrderSimple/> },
                    { id: "create-order-item", path: "create-order-item/:orderId", element: <CreateOrderItem/> },
                    { id: "edit-order-item", path: "edit/:orderId/:orderItemId", element: <EditOrderItem/> },
                    {
                        id: "assign-product-to-order-item",
                        path: "assign-product/:orderItemId/:movementId/:productType/:productTypeId/:quantity",
                        element: <AssignProductDnD/>
                    },
                ] : []
            },
            {
                id: "unconfirmed-orders",
                path: "customer/unconfirmed",
                element: <UnconfirmedOrders/>,
                children: policies?.includes(WRITE_ORDER) ? [] : []
            },
            {
                id: "components-orders",
                path: "components-orders",
                element: <ComponentsOrders/>,
                children: policies?.includes(WRITE_ORDER) ? [
                    { id: "create-component-order", path: "create", element: <CreateComponentOrder/> },
                    { id: "edit-component-order", path: "edit/:orderId", element: <EditComponentOrder/> },
                ] : []
            },
        ]
    }
]

// interface Props {}

// export const OrderRouter: FC<Props> = () => {
//     return (
//         <Routes>
//             <Route path="/" element={<Order/>}>
//                 <Route path="customer/confirmed" element={<CustomerOrder/>}>
//                     <Route path="create" element={<CreateOrder/>}/>
//                     <Route path="create-order-item/:orderId" element={<CreateOrderItem/>}/>
//                     <Route path="edit/:orderId" element={<EditOrderSimple/>}/>
//                     <Route path="edit/:orderId/:orderItemId" element={<EditOrderItem/>}/>
//                     <Route path="assign-product/:orderItemId/:movementId/:productType/:productTypeId/:quantity" element={<AssignProductDnD/>}/>
//                 </Route>

//                 <Route path="customer/unconfirmed" element={<UnconfirmedOrders/>}>

//                 </Route>

//                 <Route path="components-orders" element={<ComponentsOrders/>}>
//                     <Route path="create" element={<CreateComponentOrder/>}/>
//                     <Route path="edit/:orderId" element={<EditComponentOrder/>}/>
//                 </Route>
//             </Route>
//         </Routes>
//     )
// }