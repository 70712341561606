import {
    SET_SINGLE_SERIES,
    GET_SINGLE_SERIES,
    SET_SERIES_LIST,
    GET_SERIES_LIST,
    SET_AVAILABLE_SERIES,
    GET_AVAILABLE_SERIES,
    CREATE_SERIES,
    DELETE_SERIES,
    DELETE_SERIAL_NUMBER,
} from "store/types/seriesTypes";

import { ICallback } from "types/SagaInterface";
import { ISeriesDTO } from "types/SeriesInterface";

export const createSeries = (
    action_type: string,
    series_id: number,
    data: ISeriesDTO,
    callback: (res: ICallback) => void
) => ({
    type: CREATE_SERIES,
    payload: {
        action_type,
        series_id,
        data,
        callback,
    }
})

export const setSingleSeries = (data: ISeriesDTO) => ({
    type: SET_SINGLE_SERIES,
    payload: data
})

export const getSingleSeries = (id: number) => ({
    type: GET_SINGLE_SERIES,
    payload: id
})

export const setSeriesList = (list: ISeriesDTO[]) => ({
    type: SET_SERIES_LIST,
    payload: list,
});

export const getSeriesList = (callback: (res: ICallback) => void) => ({
    type: GET_SERIES_LIST,
    payload: {
        callback
    }
});

export const setAvailableSeries = (list: ISeriesDTO[]) => ({
    type: SET_AVAILABLE_SERIES,
    payload: list,
});

export const getAvailableSeries = (callback: (res: ICallback) => void) => ({
    type: GET_AVAILABLE_SERIES,
    payload: {
        callback
    }
});

export const deleteSeries = (id: number, callback: (res: ICallback) => void) => ({
    type: DELETE_SERIES,
    payload: {
        id,
        action_type: 'delete_series',
        callback
    }
})

export const deleteSerialNumber = (id: number, callback: (res: ICallback) => void) => ({
    type: DELETE_SERIAL_NUMBER,
    payload: {
        id,
        action_type: 'delete_serial_number',
        callback
    }
})