import {
    CREATE_ORDER,
    CREATE_ORDER_ITEM,
    SET_SINGLE_ORDER,
    // GET_SINGLE_ORDER,
    SET_SINGLE_ORDER_ITEM,
    GET_SINGLE_ORDER_ITEM,
    SET_ORDERS_LIST,
    GET_ORDERS_LIST,
    SET_AVAILABLE_ORDERS_SHIPMENT,
    GET_AVAILABLE_ORDERS_SHIPMENT,
    UPDATE_SINGLE_ORDER,
    UPDATE_ORDER_ITEM,
    UPDATE_ORDER_SHIPMENT,
    SET_MOVEMENT_STATUS,
    SET_ORDER_STATUS,
    SET_ORDER_STATUS_LIST,
    GET_ORDER_STATUS_LIST,
    SET_MOVEMENT_STATUS_LIST,
    GET_MOVEMENT_STATUS_LIST,
    SET_SHIPMENT_STATUS_LIST,
    GET_SHIPMENT_STATUS_LIST,
    DELETE_ORDER,
    DELETE_ORDER_ITEM,
    SET_ORDER_PRODUCTS,
    GET_ORDER_PRODUCTS,
    SPLIT_PRODUCT_ORDER_ITEM,
} from "store/types/orderTypes";

import {
    ICreateOrderItem,
    ISingleOrderItem,
    IUpdateOrderItem,
    IOrder,
    IOrderProducts,
    IOrderStatus,
    IMovementStatusDTO,
    IShipmentStatusDTO,
    ISplitOrderItemDTO,
    OrderNumberType,
    SingleOrderDataType,
    IUpdateOrderShipmentDTO,
} from "types/OrderInterface";

import { ICallback } from "types/SagaInterface";

export const createOrder = (data: IOrder, callback: (res: ICallback) => void) => ({
    type: CREATE_ORDER,
    payload: {
        data,
        callback,
    }
})

export const createOrderItem = (
    id: number,
    data: ICreateOrderItem,
    callback: (res: ICallback) => void
) => ({
    type: CREATE_ORDER_ITEM,
    payload: {
        id,
        data,
        callback,
    }
})

export const setSingleOrder = (data: IOrder) => ({
    type: SET_SINGLE_ORDER,
    payload: data
})

// export const getSingleOrder = (id: number, callback: (success: boolean) => void) => ({
//     type: GET_SINGLE_ORDER,
//     payload: {
//         id,
//         callback,
//     }
// })

export const setSingleOrderItem = (data: ISingleOrderItem) => ({
    type: SET_SINGLE_ORDER_ITEM,
    payload: data
})

export const getSingleOrderItem = (id: number, callback: (res: ICallback) => void) => ({
    type: GET_SINGLE_ORDER_ITEM,
    payload: {
        id,
        callback,
    }
})

export const setOrdersList = (orderList: IOrder[]) => ({
    type: SET_ORDERS_LIST,
    payload: orderList,
});

export const getOrdersList = (callback: (success: boolean) => void) => ({
    type: GET_ORDERS_LIST,
    payload: {
        callback,
    }
});

export const setAvailableOrdersShipment = (orderList: IOrder[]) => ({
    type: SET_AVAILABLE_ORDERS_SHIPMENT,
    payload: orderList,
});

export const getAvailableOrdersShipment = (callback: (res: ICallback) => void) => ({
    type: GET_AVAILABLE_ORDERS_SHIPMENT,
    payload: {
        callback,
    }
});

export const updateOrder = (
    id: number,
    person_id: number,
    data: SingleOrderDataType,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_SINGLE_ORDER,
    payload: {
        id,
        data: {...data, person_id},
        callback,
    }
})

export const updateOrderItem = (
    id: number,
    data: IUpdateOrderItem,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_ORDER_ITEM,
    payload: {
        id,
        data,
        callback,
    }
})

export const updateOrderShipment = (
    id: number,
    data: IUpdateOrderShipmentDTO,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_ORDER_SHIPMENT,
    payload: {
        id,
        data,
        callback,
    }
})

export const splitOrderItemByProduct = (data: ISplitOrderItemDTO, callback: (success: boolean) => void) => ({
    type: SPLIT_PRODUCT_ORDER_ITEM,
    payload: {
        data,
        callback,
    }
})

export const setMovementStatus = (
    movement_id: number,
    movement_status_id: OrderNumberType,
    callback: (success: boolean) => void
) => ({
    type: SET_MOVEMENT_STATUS,
    payload: {
        id: movement_id,
        status: movement_status_id,
        callback,
    },
});

export const setOrderStatus = (
    id: number,
    status: OrderNumberType,
    callback: (success: boolean) => void
) => ({
    type: SET_ORDER_STATUS,
    payload: {
        id,
        status,
        callback,
    },
});

export const setOrderStatusList = (list: IOrderStatus[]) => ({
    type: SET_ORDER_STATUS_LIST,
    payload: list,
});

export const getOrderStatusList = (callback: (success: boolean) => void) => ({
    type: GET_ORDER_STATUS_LIST,
    payload: {
        callback,
    },
});

export const setMovementStatusList = (list: IMovementStatusDTO[]) => ({
    type: SET_MOVEMENT_STATUS_LIST,
    payload: list,
});

export const getMovementStatusList = (callback: (success: boolean) => void) => ({
    type: GET_MOVEMENT_STATUS_LIST,
    payload: {
        callback,
    },
});

export const setShipmentStatusList = (list: IShipmentStatusDTO[]) => ({
    type: SET_SHIPMENT_STATUS_LIST,
    payload: list,
});

export const getShipmentStatusList = (callback: (res: ICallback) => void) => ({
    type: GET_SHIPMENT_STATUS_LIST,
    payload: {
        callback,
    },
});

export const deleteOrder = (id: number, callback: (success: boolean) => void) => ({
    type: DELETE_ORDER,
    payload: {
        id,
        callback
    }
})

export const deleteOrderItem = (id: number, callback: (res: ICallback) => void) => ({
    type: DELETE_ORDER_ITEM,
    payload: {
        id,
        callback
    }
})

export const setOrderProducts = (data: IOrderProducts) => ({
    type: SET_ORDER_PRODUCTS,
    payload: data,
});

export const getOrderProducts = (id: number, callback: (success: boolean) => void) => ({
    type: GET_ORDER_PRODUCTS,
    payload: {
        id,
        callback,
    }
});