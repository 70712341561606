import {
    SET_UNCONFIRMED_ORDERS_LIST,
    SET_UNCONFIRMED_ORDER_STATUS_LIST,
} from "store/types/unconfirmedOrderTypes";

export interface IUnconfirmedOrderStatus {
    unconfirmed_status_id: number;
    unconfirmed_status_code: string;
    unconfirmed_status_name: string;
}

export interface IUnconfirmedOrder {
    id: number;
    last_name: string;
    first_name: string;
    status: number;
    position: string;
    phone_number: string;
    unit_number: string;
    project_kind: number[];
}

export interface IUnconfirmedOrderState {
    unconfirmedOrdersList: IUnconfirmedOrder[]
    unconfirmedOrderStatusList: IUnconfirmedOrderStatus[]
}

interface ISetUnconfirmedOrderListAction {
    type: typeof SET_UNCONFIRMED_ORDERS_LIST
    payload: IUnconfirmedOrder[]
}

interface ISetUnconfirmedOrderStatusListAction {
    type: typeof SET_UNCONFIRMED_ORDER_STATUS_LIST
    payload: IUnconfirmedOrderStatus[]
}

export type UnconfirmedOrderAction = ISetUnconfirmedOrderListAction | ISetUnconfirmedOrderStatusListAction;

export enum EUnconfirmedOrderStatus {
    UNCONFIRMED = 1,
    CONFIRMED = 2,
    DECLINE = 3,
    DELETE = 4,
}