import {
    CREATE_COMPONENT_ORDER,
    // SET_COMPONENT_ORDER,
    // GET_COMPONENT_ORDER,
    SET_COMPONENTS_ORDERS_LIST,
    GET_COMPONENTS_ORDERS_LIST,
    UPDATE_COMPONENT_ORDER,
    DELETE_COMPONENT_ORDER,
    SET_COMPONENT_ORDER_MOVEMENT_STATUS,
} from "store/types/componentOrderTypes";

import {
    ICreateComponentOrder,
    IComponentOrder,
} from "types/ComponentOrderInterface";

import { OrderNumberType } from "types/OrderInterface";

import { ICallback } from "types/SagaInterface";

export const createComponentOrder = (
    data: ICreateComponentOrder,
    callback: (res: ICallback) => void
) => ({
    type: CREATE_COMPONENT_ORDER,
    payload: {
        data,
        callback,
    }
})

// export const setComponentOrder = (data: IComponentOrder) => ({
//     type: SET_COMPONENT_ORDER,
//     payload: data
// })

// export const getComponentOrder = (id: number, callback: (res: ICallback) => void) => ({
//     type: GET_COMPONENT_ORDER,
//     payload: {
//         id,
//         callback,
//     }
// })

export const setComponentsOrdersList = (list: IComponentOrder[]) => ({
    type: SET_COMPONENTS_ORDERS_LIST,
    payload: list,
});

export const getComponentsOrdersList = (callback: (res: ICallback) => void) => ({
    type: GET_COMPONENTS_ORDERS_LIST,
    payload: {
        callback,
    }
});

export const updateComponentOrder = (
    id: number,
    data: IComponentOrder,
    callback: (res: ICallback) => void
) => ({
    type: UPDATE_COMPONENT_ORDER,
    payload: {
        id,
        data,
        callback,
    }
})

export const deleteComponentOrder = (id: number, callback: (res: ICallback) => void) => ({
    type: DELETE_COMPONENT_ORDER,
    payload: {
        id,
        callback
    }
})

export const setComponentOrderMovementStatus = (
    movement_id: number,
    movement_status_id: OrderNumberType,
    callback: (success: boolean) => void
) => ({
    type: SET_COMPONENT_ORDER_MOVEMENT_STATUS,
    payload: {
        id: movement_id,
        status: movement_status_id,
        callback,
    },
});