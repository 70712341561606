import { ChangeEvent, FC, FocusEvent } from "react";

import { IInput } from "types/TableInterface";

import { TextField } from "@mui/material";

interface Props {
    data: IInput;
    index: number;
    onChangeInput?: (value: string | number, index: number) => void;
}

export const TableInputType: FC<Props> = ({ data, index, onChangeInput }) => {

    function handleChange(value: string | number) {
        if (onChangeInput) onChangeInput(value, index)
        else if (data?.onChange instanceof Function) data?.onChange(value, index)
    }

    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        let value: string | number = event?.target?.value || ''

        if (value !== '' && data?.min !== undefined && Number(value) < data?.min) {
            value = data.min
        }
        else if (data?.max !== undefined && Number(value) > data?.max) {
            value = data.max
        }

        handleChange(value)
    }

    function handleInputBlur(event: FocusEvent<HTMLInputElement>) {
        let value: string | number = event?.target?.value || ''

        if (data?.min !== undefined && Number(value) < data?.min) {
            value = data.min
        }
        else if (data?.max !== undefined && Number(value) > data?.max) {
            value = data.max
        }

        handleChange(value)
    }

    return (
        <div className="table-input-type">
            <TextField
                sx={{width: '100%'}}
                variant="standard"
                label={data?.label || ''}
                type={data?.type || 'text'}
                value={data?.value || ''}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
            />
        </div>
    )
}