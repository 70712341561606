import { FC, MutableRefObject, useCallback, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroll-component';

import { TableFilterLabels } from 'components/Table/TableFilterLabels';
import { TableHeadComponent } from './TableHead/TableHeadComponent';
import { TableBodyComponent } from './TableBody/TableBodyComponent';

import {
    IFilterData,
    ITableDataItem,
    ITableHeader,
    ITableItemStyles,
} from 'types/TableInterface';

import {
    styled,
    Table,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableRow,
    Paper,
    Button,
} from '@mui/material';

import {
    KeyboardDoubleArrowDown
} from '@mui/icons-material';

import './table.scss'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

interface Props<T> {
    tableData: ITableDataItem<T>[];
    headers: ITableHeader[];
    tableId: string;
    has_pagination?: boolean;
    amount_per_page?: number;
    searchEntityId?: number | null;
    onChangeRowInput?: (value: string | number, index: number) => void;
}

export const TableComponent: FC<Props<any>> = ({
    headers,
    tableData,
    tableId,
    has_pagination = true,
    amount_per_page = 20,
    searchEntityId,
    onChangeRowInput
}) => {
    const [ has_more, setHasMore ] = useState<boolean>(true);
    const [ per_page, setPerPage ] = useState<number>(amount_per_page);

    const tableComponentRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const tableBodyComponentRef = useRef<{ fetchData: () => void } | null>(null);

    const [ sortData, setSortData ] = useState<string[]>([])
    const [ filterData, setFilterData ] = useState<IFilterData>({})

    function setSortDataHandler(value: string[]) {
        setSortData(value)
    }
    function setFilterDataHandler(value: IFilterData) {
        setFilterData(value)
    }

    const [ searchParams, setSearchParams ] = useSearchParams()

    const getHeaderStyles = useCallback((styles: ITableItemStyles | undefined) => {
        return {
            ...styles,
            width: styles?.cellWidth ?? `${100/headers?.length}%`,
            minWidth: styles?.cellMinWidth ?? `initial`,
            maxWidth: styles?.cellMaxWidth ?? `initial`,
        }
    }, [headers])

    function checkDataDepth(list: ITableDataItem<any>[]) {
        let count = 1
        for (const idx in list) {
            if(list[idx]?.accordionData?.tableData?.length) {
                count = 1 + checkDataDepth(list[idx].accordionData.tableData)
            }
        }
        return count
    }

    const zIndex = useMemo(() => {
        return checkDataDepth(tableData)
    }, [tableData])

    const handleScroll = () => {
        const tc = tableComponentRef.current;
    
        if (tc && has_more) {
            const distanceFromBottom = tc.scrollHeight - (tc.scrollTop + tc.clientHeight);

            const threshold = 0;

            if (distanceFromBottom === threshold) {
                getFetchDataFunc();
            }
        }
    };

    function getFetchDataFunc() {
        if (tableBodyComponentRef?.current?.fetchData) {
            tableBodyComponentRef.current.fetchData();
        }
    }

    return (
        <>
            <TableFilterLabels
                sortData={sortData}
                filterData={filterData}
                headers={headers}
                per_page={per_page}
                setPerPage={setPerPage}
                has_pagination={has_pagination}
                updateSortData={(value: string[]) => setSortData(value)}
                updateFilterData={(value) => setFilterData(value)}
            />

            <Paper elevation={10}>

                <InfiniteScroll
                    dataLength={tableData.length}
                    next={getFetchDataFunc}
                    hasMore={has_more}
                    // loader={<h4>Loading...</h4>}
                    loader={null}
                >

                    <TableContainer
                        ref={tableComponentRef}
                        sx={{ maxHeight: 720 }}
                        className="table-component scroll-bar-primary-x-y"
                        component={Paper}
                        onScroll={handleScroll}
                    >
                        <Table
                            aria-label="customized table"
                            sx={{ minWidth: 700, borderCollapse: 'separate' }}
                        >

                            <TableHeadComponent
                                tableId={tableId}
                                zIndex={zIndex}
                                tableData={tableData}
                                headers={headers}
                                filterData={filterData}
                                sortData={sortData}
                                setSortDataHandler={setSortDataHandler}
                                setFilterDataHandler={setFilterDataHandler}
                                getHeaderStyles={getHeaderStyles}
                                StyledTableCell={StyledTableCell}
                            />

                            <TableBodyComponent
                                ref={tableBodyComponentRef}
                                zIndex={zIndex}
                                tableComponentRef={tableComponentRef}
                                tableData={tableData}
                                headers={headers}
                                filterData={filterData}
                                sortData={sortData}
                                has_pagination={has_pagination}
                                per_page={per_page}
                                has_more={has_more}
                                setHasMore={setHasMore}
                                StyledTableRow={StyledTableRow}
                                StyledTableCell={StyledTableCell}
                                getHeaderStyles={getHeaderStyles}
                                onChangeRowInput={onChangeRowInput}
                            />

                        </Table>
                    </TableContainer>

                </InfiniteScroll>

            </Paper>

            {searchEntityId &&
                <Paper elevation={10} sx={{width: 'fit-content', margin: '0 auto 25px'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}
                        onClick={() => setSearchParams('')}
                    >
                        <KeyboardDoubleArrowDown/>
                    </Button>
                </Paper>
            }
        </>
    );
}