import { all, fork } from "redux-saga/effects";
import { authWatcher } from "./authSaga";
import { orderWatcher } from "./orderSaga";
import { unconfirmedOrderWatcher } from "./unconfirmedOrderSaga";
import { componentOrderWatcher } from "./componentOrderSaga";
import { componentWatcher } from "./componentSaga";
import { productWatcher } from "./productSaga";
import { productTypeWatcher } from "./productTypeSaga";
import { recipeWatcher } from "./recipeSaga";
import { seriesWatcher } from "./seriesSaga";
import { operatorWatcher } from "./operatorSaga";
import { softwareWatcher } from "./softwareSaga";

export function* rootSaga() {
    yield all([
        fork(authWatcher),
        fork(orderWatcher),
        fork(unconfirmedOrderWatcher),
        fork(componentOrderWatcher),
        fork(componentWatcher),
        fork(productWatcher),
        fork(productTypeWatcher),
        fork(recipeWatcher),
        fork(seriesWatcher),
        fork(operatorWatcher),
        fork(softwareWatcher),
    ])
}